import { Button, Checkbox, Form, Input, Select, Tabs } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { useState } from "react";
import "./Settings.css";
import { editUser, getConfigGroup, getGroups, loadProducts, loadStores, putConfigGroup, searchUser } from "./useSettingsHook";
import TableCommission from "../TableCommission/TableCommission";
import TableProductCommission from "../TableProductsCommission/TableProductsCommission";

interface SettingsState {
  user?: any;
  configuration?: any;
  products?: any[];
  stores?: any[];
  groups?: any[];

}
const Settings = () => {

  const [settingsState, setSettingsState] = useState<SettingsState>({});

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [searchUserForm] = Form.useForm();
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [tabSelected, setTabSelected] = useState<string>("1");
  const [mentorSelected, setMentorSelected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSend, setLoadingSend] = useState<boolean>(false);


  function handleEditFormSubmit(): void {
    setLoadingSend(true);
    editForm.validateFields().then((values: any) => {
      let products = getProductsSubmit(values);
      const formData = {
        id: settingsState?.user.id,
        full_name: values.name,
        email: values.email,
        password: values.password,
        roles: values.roles,
        mentorStores: (settingsState.stores?.filter(x => values.stores?.includes(x.name)))?.map((x: { id: any; }) => {
          return x.id
        }),
        storeProducts: products,
        updated_at: new Date(),
        mentorGroup: Array.isArray(values.group) ? values.group[0] : values.group
      };
      editUser(formData).then(() => {
        setLoadingSend(false);
        editForm.resetFields();
        searchUserForm.resetFields();
        setSettingsState({})
      });


    });
  }

  function getProductsSubmit(values: any) {
    let products: any[] = [];
    values.stores?.forEach((element: string | number) => {
      let temProd = values[settingsState.stores?.find(x => x.name == element).id];
      if (temProd) {
        products = products.concat(temProd)
      }
    });
    return products;
  }
  function handleChange(activeKey: string): void {
    setTabSelected(activeKey);
  }

  function handleSearchUser(): void {
    setMentorSelected(false);
    editForm.resetFields();
    let tempSettigns: any = {};
    searchUserForm.validateFields().then((values: any) => {
      setLoading(true);
      searchUser(values?.email).then((user: any) => {
        if (user) {
          tempSettigns['user'] = user;
          getGroups().then((groups: any) => { tempSettigns['groups'] = groups })
          loadStores().then((stores: any) => {
            tempSettigns['stores'] = stores;
            loadProducts(user.mentorStores).then(async (products) => {
              var res = settingsState.stores?.filter(x => user?.mentorStores?.includes(x.id)).map((x: { name: any; }) => {
                return x.name
              })
              tempSettigns['products'] = products;
              let obj: any = {
                name: user?.full_name || '',
                email: user?.email || '',
                roles: user?.roles || [],
                stores: stores?.filter((x: { id: any; }) => user?.mentorStores?.includes(x.id)).map((x: { name: any; }) => {
                  return x.name
                }),

                products: settingsState.stores?.filter(x => user?.storeProducts?.includes(x.id)).map((x: { name: any; }) => {
                  return x.name
                }),
                group: user?.mentorGroup ?? undefined,
              }
              let prodFilter: any = products?.filter((x: { id: any; }) => user.storeProducts?.includes(x.id))
              let storeIds = prodFilter?.map((x: { storeId: number; }) => x.storeId);
              let result = storeIds.filter((item: any, index: any) => {
                return storeIds.indexOf(item) === index;
              })
              result.forEach((st: any) => {
                let provid = st
                obj[provid] = prodFilter.filter((x: { storeId: any; }) => x.storeId == st).map((x: { id: any; }) => x.id);
              })
              setSettingsState(tempSettigns);
              editForm.setFieldsValue(obj);

            })
          })
        }
        setLoading(false);

      })
    });
  }

  function handleConfigGroup(): void {
    editForm.validateFields().then((values: any) => {
      let products = getProductsSubmit(values);
      const config = {
        storeProducts: products,
        name: values.group
      };
      putConfigGroup(config)
    })
  }

  function handleAplyConfig() {
    getConfigGroup(settingsState.user.mentorGroup).then((config: any) => {
      setSettingsState({ ...settingsState, configuration: config })
      let tempSettigns = { ...settingsState };
      tempSettigns.user.mentorStores = config.mentorStores;
      tempSettigns.user.storeProducts = config.storeProducts;
      setSettingsState(tempSettigns);
      loadProducts(config.mentorStores).then((prods) => {
        setSettingsState({ ...settingsState, products: prods });
        let prodFilter: any = prods?.filter((x: { id: any; }) => settingsState.user.storeProducts.includes(x.id))
        let storeIds = prodFilter?.map((x: { storeId: number; }) => x.storeId);
        let result = storeIds.filter((item: any, index: any) => {
          return storeIds.indexOf(item) === index;
        })
        let obj: any = {};
        result.forEach((st: any) => {
          let provid = st
          obj[provid] = prodFilter.filter((x: { storeId: any; }) => x.storeId == st).map((x: { id: any; }) => x.id);
        })
        editForm.setFieldsValue(obj);

      })
    })

  }

  const roles = [
    { label: 'Admin', value: 2 },
    { label: 'Tienda', value: 3 },
    { label: 'Mensajero', value: 4 },
    { label: 'Productor', value: 5 },
    { label: 'Productor/Tienda', value: 6 },
    { label: 'UserFree', value: 7 },
    { label: 'Bodega', value: 8 },
    { label: 'Mentor', value: 9 },
  ];

  return (
    <div className="settings__container">
      <Tabs defaultActiveKey="1" type="card" onChange={handleChange}>
        <TabPane tab="Editar usuario" key="1"></TabPane>
        <TabPane tab="Ver comisiones" key="2"></TabPane>
        <TabPane tab="Productos" key="3"></TabPane>
      </Tabs>
      <div>
        {tabSelected === "1" && (
          <div>
            <Form form={searchUserForm}>
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingresa el correo electrónico",
                  },
                  {
                    type: "email",
                    message:
                      "Ingresa una dirección de correo electrónico válida",
                  },
                ]}
              >
                <Input></Input>
              </FormItem>

              <FormItem>
                <Button type="primary" onClick={handleSearchUser}
                  loading={loading}>
                  Buscar
                </Button>
              </FormItem>
            </Form>
            {settingsState?.user && settingsState.stores && (
              <Form
                form={editForm}
                layout="vertical"
              >
                <div className="datosTienda">
                  <div>
                    <strong>Nombre:</strong>
                    <h4>{settingsState?.user?.full_name}</h4>
                  </div>

                  <div>
                    <strong>Tienda:</strong>
                    <h4>{settingsState?.user?.storeName}</h4>
                  </div>


                </div>


                <FormItem label="Roles" name="roles">
                  <Checkbox.Group
                    options={roles}
                  />
                </FormItem>

                {settingsState?.user?.roles?.includes(9) && (
                  <FormItem
                    label="Selecionar grupo al mentor"
                    name="group"
                  >
                    <Select
                      mode="tags"
                      placeholder={"Selecciona Grupo"}
                      style={{ width: "100%" }}
                      disabled={!settingsState.user?.roles?.includes(9)}
                    >
                      {settingsState?.groups?.map((x: any) => (
                        <Option key={x.id} value={x.name}>
                          {x.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>

                )}

                {settingsState?.user?.roles?.includes(9) && settingsState.user?.mentorGroup && (<FormItem>
                  <Button onClick={handleAplyConfig}>
                    Aplicar configuración existente
                  </Button>
                </FormItem>)}

                {settingsState?.user?.roles?.includes(9) && (<FormItem
                  label="Selecionar tiendas asociadas al mentor"
                  name="stores"
                >
                  <Select
                    mode="multiple"
                    placeholder="Selecciona Tiendas"
                    style={{ width: "100%" }}
                    disabled={!settingsState?.user?.roles?.includes(9)}

                  >
                    {settingsState?.stores?.map((x: any) => (
                      <Option key={x.id} value={x.name}>
                        {x.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>)}

                {settingsState?.user?.mentorStores?.map((x: any) => (
                  <FormItem
                    key={x}
                    label={"Selecciona productos para la tienda " + settingsState?.stores?.find(store => store.id === x).name}
                    name={x.toString()}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Selecciona productos"
                      style={{ width: "100%" }}
                      disabled={!settingsState?.user?.roles?.includes(9)}

                    >
                      {settingsState?.products?.filter(prd => prd.storeId == x).map((x: any) => (
                        <Option key={x.id} value={x.id}>
                          {x.product.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>

                ))}

                <FormItem className="btn-group" >
                  <Button type="primary" onClick={handleEditFormSubmit}
                    loading={loadingSend}>
                    Enviar
                  </Button>

                  {settingsState?.user?.roles?.includes(9) && settingsState.user?.mentorGroup && (<Button type="default" onClick={() => handleConfigGroup()}>
                    Guardar configuración para el grupo
                  </Button>)}

                </FormItem>

              </Form>)}

          </div>
        )}
        {tabSelected === "2" && (
          <TableCommission></TableCommission>
        )}
        {tabSelected === "3" && (
          <TableProductCommission></TableProductCommission>
        )}
      </div>
    </div>
  );
};

export default Settings;
