import { PDFDocument } from 'pdf-lib'
import { useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';
import { message, notification } from 'antd';
import { API } from '../API/axios';

export const usePDFContract = () => {

    const [showBorder, setShowBorder] = useState<boolean>(true)
    const [sign, setSign] = useState<any>();
    const [urlFile, setUrlFile] = useState<any>();

    const Page1Ref = useRef(null);
    const Page2Ref = useRef(null);
    const Page3Ref = useRef(null);

    const validateInputs = () => {

        let inputs: any = document.getElementsByClassName('red-border');
        let flag = true;
        for (let i = 0, len = inputs.length; i < len; i++) {
            let input = inputs[i];
            if (!input.value) {
                flag = false;
                input.focus();
                notification.warning({ message: 'Todos los campos son obligatorios' })
                break;
            }
        }
        return (flag);
    }

    const downloadImage = async (name: string, idContractStore: number, numberPages: number) => {
        debugger
        let page1 = "";
        let page2 = "";
        let page3 = "";

        if (validateInputs()) {
            setShowBorder(false);
            if (Page1Ref.current != null) {
                page1 = await htmlToImage.toPng(Page1Ref.current);
            }
            if (Page2Ref.current != null) {
                page2 = await htmlToImage.toPng(Page2Ref.current);
            }
            if (numberPages == 3 && Page3Ref.current != null) {
                page3 = await htmlToImage.toPng(Page3Ref.current);
            }
            fillForm(page1, page2, page3, name, idContractStore);
        }
    }

    async function fillForm(page1: string, page2: string, page3: string, name: string, idContractStore: number) {

        const pdfDoc = await PDFDocument.create()

        const jpgImage = await pdfDoc.embedPng(page1)
        const pngImage = await pdfDoc.embedPng(page2)


        const jpgDims = jpgImage.scale(0.7)
        const pngDims = pngImage.scale(0.7)


        const _page1 = pdfDoc.addPage()

        _page1.drawImage(jpgImage, {
            x: _page1.getWidth() - jpgDims.width - 30,
            y: _page1.getHeight() - jpgDims.height - 20,
            width: jpgDims.width,
            height: jpgDims.height,
        })

        const _page2 = pdfDoc.addPage()
        _page2.drawImage(pngImage, {
            x: _page2.getWidth() - pngDims.width - 30,
            y: _page2.getHeight() - pngDims.height - 20,
            width: pngDims.width,
            height: pngDims.height,
        })

        if (page3 !== '') {
            const pngImage3 = await pdfDoc.embedPng(page3)
            const pngDims3 = pngImage3.scale(0.7)
            const _page3 = pdfDoc.addPage()
            _page3.drawImage(pngImage3, {
                x: _page3.getWidth() - pngDims3.width - 30,
                y: _page3.getHeight() - pngDims3.height - 20,
                width: pngDims3.width,
                height: pngDims3.height,
            })
        }

        const pdfBytes = await pdfDoc.save()

        // Create a new file
        // This uses File Saver to save the files, i used this to temp save whilst i moved around the text
        let file = new File(
            [pdfBytes],
            "test-Certificate.pdf",
            {
                type: "application/pdf;charset=utf-8",
            }
        );
        await saveDocument(file, name, idContractStore);
        const blobUrl = window.URL.createObjectURL(file);
        window.open(blobUrl);
        window.location.href = "/storeHome"
    }

    const handleFileRead = async (event: any) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setSign(base64);
    }

    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const saveDocument = async (file: File, name: string, idContractStore: number) => {

        let formData = new FormData();
        const myNewFile = new File([file], `${name}.${localStorage.getItem("userName")}`, {
            type: file.type,
        });
        formData.append("Attachments", myNewFile);
        formData.append("strContainerName", `contracts`);
        formData.append("folderName", `contracts`);
        await API.post(`/BlobStorage/UploadImageGallery`, formData).then(
            async (res: any) => {
                let contratDto = {
                    id: idContractStore,
                    urlDocument: res.data
                }
                await API.put(`/Contract/sign`, contratDto).then(
                    res => {
                        message.success(`${file.name} Se ha cargado el contrato correctamente`);
                    }
                )
            });
    }

    return {
        fillForm,
        Page1Ref,
        Page2Ref,
        Page3Ref,
        downloadImage,
        showBorder,
        handleFileRead,
        sign,
        urlFile,
    }
}