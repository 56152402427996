import { Button, Col, Input, Row } from "antd"
import { usePDFContract } from "../../Hooks/usePDFContract"
import './FillContract.css'
interface ISign {
    id: number;
}

const DropHackingContract = (props: ISign) => {

    const { showBorder, Page1Ref, Page2Ref, Page3Ref, downloadImage, handleFileRead, sign } = usePDFContract();
    return (
        <div>
            <div className="panel-container">
                <div className="page">
                    <div className="subpage" ref={Page1Ref}>
                        <h1 className="center"><u>CONTRATO DROPHACKINGMIND</u></h1>
                        Entre los suscritos a saber, CRECEL SAS sociedad constituida mediante las Leyes de la
                        República de Colombia, con domicilio principal en la <strong>Carrera 50 FF #10 sur 150</strong>,
                        con NIT. N. <strong>901.274.994-1</strong>, representada legalmente en este acto por Lina Patricia Calle S, mayor
                        de edad, identificado con cédula de ciudadanía <strong>43.597.363</strong> de la República de Colombia, en su
                        calidad de Representante Legal y quien para los efectos del presente se denominará<strong>EL (LA) FRANQUICIANTE</strong>; y, de otra parte,
                        <Input style={{ width: 250 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nombre' id='input1' />
                        mayor de edad, domiciliado en
                        <Input style={{ width: 190 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Ciudad' />, identificado(a) con documento de <strong>CC</strong>
                        Nº.<Input style={{ width: 100 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nº Documento' />
                        al que en adelante y para los efectos del presente
                        documento se denominará <strong>EL(LA) FRANQUICIADO(A)</strong>, los cuales, de común acuerdo y de forma voluntaria, con su firma, han suscrito el presente contrato, que se desarrollará
                        bajo las siguientes cláusulas y condiciones:
                        <br />
                        <br />
                        <strong>CLAÚSULA PRIMERA</strong>. <strong>Objeto del contrato DrophackingMind:</strong> El objeto del contrato, consiste en la prestación del servicio de mentorías llevada a cabo por parte de EL(LA) FRANQUICIANTE,
                        brindando consejos, capacitación a <strong>EL(LA) FRANQUICIADO(A)</strong>, con el objetivo de ayudarlo a desarrollar habilidades y conocimientos de <strong>marketing digital</strong> para vender
                        con éxito los productos de <strong>“CRECEL S.A.S.”</strong>. Igualmente, lograr el objetivo principal de ayudar a <strong>EL(LA) FRANQUICIADO(A)</strong>, a alcanzar una meta mínima de US <strong>2.000</strong> dólares
                        de utilidad en <strong>seis (06) meses</strong>, siguientes a la suscripción del mismo.<br /> <strong>CLÁUSULA SEGUNDA</strong>. <strong>Tiempo de duración:</strong> La prestación del servicio de mentorías 1 a 1 son durante tres
                        (03) meses. Las mentorías grupales, son durante <strong>doce (12) meses</strong>. Después de los <strong>tres (03) meses</strong> solo queda con las mentorías grupales, es decir, por <strong>nueve (09) meses</strong>.
                        <br />
                        <strong>CLÁUSULA TERCERA</strong>. <strong>Obligaciones del Franquiciado:</strong> (i) <strong>EL(LA) FRANQUICIADO(A)</strong>se compromete a asistir a todas las sesiones de mentoría programadas de forma virtual mediante ayuda
                        de plataformas, y en caso de no poder asistir, notificará al mentor con al menos de (24) horas de antelación, so pena de no hacer uso efectivo de la garantía. <strong>(ii)</strong>
                        <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a prepararse adecuadamente para cada sesión de mentoría y a llegar a las mismas con preguntas y temas específicos a discutir con el mentor.
                        (iii) <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a comunicar de manera oportuna cualquier problema o preocupación que pueda tener en relación con el proceso de mentoría.
                        <strong>(iv)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a poner en práctica los consejos y recomendaciones del mentor, y a proporcionar retroalimentación honesta y constructiva al mismo.
                        <strong>(v)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a estudiar todo el material del programa Drophacking Mind, como videos, PDF, workbook, o cualquier otro que surja del proceso de mentoría,
                        y que sea suministrado oportunamente por <strong>EL(LA) FRANQUICIANTE(A)</strong>. (vi) <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a cumplir con cualquier otra obligación
                        específica establecida en el presente contrato. <strong>(vii)</strong> El incumplimiento de cualquiera de las obligaciones establecidas en esta cláusula por parte de <strong>EL(LA) FRANQUICIADO(A)</strong>
                        podrá dar lugar al incumplimiento del contrato en su totalidad y a la terminación de las sesiones de mentoría, sin derecho a hacer efectivo la reclamación por
                        concepto de la garantía. <strong>(viii)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a guardar <strong>confidencialidad</strong> sobre la información compartida durante las sesiones de mentoría.
                        <strong>(ix)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> reconoce que el mentor no tiene responsabilidad alguna por cualquier acción o decisión tomada por EL(LA) FRANQUICIANTE en relación con
                        la mentoría otorgada. <strong>(x)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> estará de acuerdo con las condiciones establecidas en este contrato y se compromete a cumplirlas. <strong>(xi)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> se
                        compromete a tener buena conexión de internet al momento de las sesiones virtuales. <strong>(xii)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a respetar los horarios de soporte y mentorías
                        establecidos en común acuerdo al inicio de este contrato. <strong>(xiii)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a iniciar con una inversión mínima de US <strong>1000</strong> dólares para <strong>publicidad</strong> en
                        Facebook Ads y/o TikTok Ads, este capital sale de reinvertir las ganancias, para lo cual, deberá tener el soporte del mismo ante eventual requerimiento
                        del EL(LA) FRANQUICIANTE. <strong>(xiv)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a reinvertir el 100% de las ganancias en
                        más <strong>publicidad</strong> hasta alcanzar la meta de facturación acordada en este contrato.

                    </div>
                </div>
                <div className="page">
                    <div className="subpage" ref={Page2Ref}>
                        <p>
                            <strong>(xv)</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> se compromete a compartir sus resultados con la comunidad a través
                            de los canales virtuales para tal fín, como testimonio de que el programa cumple la promesa, una vez logrado la meta mínima de alcanzar los US
                            <strong>2.000</strong> dólares de utilidad en <strong>seis (06) meses</strong>, conforme a la Cláusula Primera de este Contrato.
                            <br />
                            <strong>CLÁUSULA CUARTA</strong>. <strong>Obligaciones del Franquiciante: (i) EL(LA) FRANQUICIANTE</strong> se
                            compromete a asistir a todas las sesiones de mentoría programadas, y en caso de no poder asistir, notificará a <strong>EL(LA) FRANQUICIADO(A)</strong> con al menos de 24 horas de antelación,
                            por el canal virtual establecido. <strong><strong>(ii)</strong></strong> EL(LA) FRANQUICIANTE se compromete a prepararse adecuadamente para cada sesión de mentoría, y a llegar a las mismas con recomendaciones y
                            consejos específicos para discutir con <strong>EL(LA) FRANQUICIADO(A)</strong>. <strong>(iii) EL(LA) FRANQUICIANTE</strong> se compromete a proporcionar todos los recursos educativos necesarios del Sistema
                            Drophacking, como videos, pdf, manuales, y los que puedan surgir con fines de este contrato. <strong>(iv)</strong> EL(LA) FRANQUICIANTE se compromete a proporcionar retroalimentación honesta y
                            constructiva a <strong>EL(LA) FRANQUICIADO(A)</strong>, y ayudarlo a alcanzar sus objetivos. <strong>(v)</strong> EL(LA) FRANQUICIANTE se compromete a cumplir con cualquier otra obligación específica establecida en
                            el presente contrato. <strong>(vi) EL(LA) FRANQUICIANTE</strong> se compromete a guardar <strong>confidencialidad</strong> sobre la información compartida durante las sesiones de mentoría y no utilizará esta información
                            para su beneficio personal o de terceros. <strong>(vii)</strong> EL(LA) FRANQUICIANTE se compromete a actuar con ética y profesionalismo durante todo el proceso y desarrollo de esta de mentoría.
                            <strong>(viii)</strong> EL(LA) FRANQUICIANTE se compromete a no aceptar beneficios económicos o cualquier otro tipo de beneficio por parte de <strong>EL(LA) FRANQUICIADO(A)</strong> o terceros relacionados
                            con la mentoría. <strong>(ix)</strong> EL(LA) FRANQUICIANTE se compromete a actuar conforme al ordenamiento jurídico y demás normas complementarias o reglamentarias colombianas. <strong>(x)</strong> EL(LA)
                            FRANQUICIANTE <strong>estará facultado para</strong> hacer uso de los resultados obtenidos por <strong>EL(LA) FRANQUICIADO(A)</strong>, con la finalidad de dar testimonio de que efectivamente se cumple la promesa
                            del programa de mentorías Drophacking Mind, <strong>previa</strong> autorización tácita y/o implícita a través de la firma ya sea digital o física por el <strong>EL(LA) FRANQUICIADO(A)</strong>.
                            <br />
                            CLAÚSULA QUINTA.
                            <strong>Derechos del Franquiciado:</strong> <strong>EL(LA) FRANQUICIADO(A)</strong> tendrá derecho a recibir orientación, consejos y formación de los mentores de Drophacking Mind con relación a construir un
                            negocio rentable de Dropshipping con Drophacking <strong>CRECEL S.A.S.</strong>, así como acceso a la experiencia y conocimientos de ÉL(LA) FRANQUICIANTE. <strong>EL(LA) FRANQUICIADO(A)</strong> también tendrá
                            derecho a la <strong>confidencialidad</strong> en relación a cualquier información compartida durante el proceso de mentoría. EL(LA) FRANQUICIANTE se compromete a respetar los objetivos y metas
                            de <strong>EL(LA) FRANQUICIADO(A)</strong> y a proporcionar un ambiente seguro y respetuoso para el desarrollo de la mentoría.
                            <br />
                            CLAÚSULA SEXTA. <strong>Contenido del programa de mentorías Drophacking Mind</strong>.
                            Se desarrollará bajo las siguientes condiciones: 3 Meses de Mentoría Semanal y Accountability. Sesión 1-a-1 de Onboarding con Ejecutivo de cuenta. Sesión 1-a-1 de Planificación
                            con Ejecutivo de cuenta. Sesión 1-a-1 de Procesos y estructura de equipos para Dropshipping. 4 Sesiones 1-a-1 de Técnicas de Copy Drophooks. Sesión 1-a-1 para Armar
                            Contingencias Black. 5 Sesiones 1-a-1 de Booster Tráfico pago. 2 Sesiones 1-a-1 de proceso de Configuración y optimización de Embudos Escalables. 5 Sesiones 1-a-1 de
                            Cierre de ventas con la metodología Max Closing. Sesión 1-a-1 de Calendario Semanal para vender con tráfico Orgánico para productos físicos. Sesión 1-a-1 de Optimización
                            y Análisis del Booster de Tráfico Pago. 2 Masterminds Presenciales 2023 + Acceso a las grabaciones (julio y diciembre). Soporte por Chat & (meet) con equipo Drophacking.
                            Llamada social semanal de networking. Networking y comunidad con Emprendedores que facturan 50k USD/ Año. Contenido Exclusivo para escalar a 2-5k USD/MES. Plantillas y Recursos
                            Copia y Pega. Soporte Meetings Grupales Semanal. Plan de cash rápido.
                            <br />
                            <strong> CLAÚSULA SÉPTIMA. Precio oficial de la mentoría:</strong> Establecido en <Input style={{ width: 80 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Valor' /> USD.
                            <br />
                            <strong>CLAÚSULA OCTAVA. Promesa</strong>: Escalar de US <strong>2.000</strong> dólares a US <strong>5.000</strong> dólares de utilidad del negocio digital de venta de productos físicos y reducir el tiempo de trabajo automatizando procesos en
                            tres (03) meses a favor de <strong>EL(LA) FRANQUICIADO(A)</strong>.
                            <br />
                            <strong>CLAÚSULA NOVENA.
                                Garantía</strong>: Si se comprueba y demuestra que <strong>EL(LA) FRANQUICIADO(A)</strong> hizo y llevó a cabo todos los procesos, asistió a todas las mentorías,
                            pone en práctica los consejos, tareas, actividades programadas por los mentores, aplica los cambios en las revisiones y, aun así, no logra la meta de mínimo
                            <strong>2.000 dólares</strong>de utilidad en <strong>seis (06) meses</strong>, EL(LA) FRANQUICIANTE, se compromete a hacer la devolución del doble de  lo que
                        </p>

                    </div>
                </div>

                <div className="page">
                    <div className="subpage" ref={Page3Ref}>
                        <p>
                            <strong>EL(LA) FRANQUICIADO(A)</strong>
                            pagó e invirtió en el programa Drophacking Mind conforme a la <strong>Cláusula Séptima</strong>, teniendo en cuenta para ello el <strong>PARÁGRAFO</strong> que se relaciona
                            a continuación.
                            Para hacer efectivo dicha devolución, la condición es haber asistido a todas las mentorías programadas en común acuerdo. Haber estudiado todos los materiales
                            educativos del programa Drophacking Mind. Haber asistido a todas las sesiones de contingencias.
                            Haber asistido a todas las sesiones de analítica y feedback y aplicar
                            los cambios necesarios. Comprobar que si invertiste como mínimo US 200 Dólares en <strong>publicidad</strong>. <strong>PARÁGRAFO</strong>. Si no logra el resultado en los primeros <strong>seis (06) meses</strong>,
                            EL(LA) FRANQUICIANTE se compromete a ayudar con otros <strong>seis (06) meses</strong> <strong>sin ningún costo adicional</strong> a <strong>EL(LA) FRANQUICIADO(A)</strong> para que éste alcance la meta inicial,
                            exceptuando el valor que, por concepto de <strong>publicidad</strong> - <strong>APPS</strong> se establece como mínimo conforme al numeral <strong>(xiii)</strong> de las obligaciones del <strong>EL(LA) FRANQUICIADO(A)</strong>
                            pactadas en la Cláusula tercera. Llegado el caso en que no se alcance las metas y/o resultados propuestos, se hará uso efectivo de la garantía establecida para
                            tal fin. CLAÚSULA DÉCIMA. DURACIÓN. El presente contrato tiene una duración de <strong>seis (06) meses</strong>, contados a partir de la suscripción del mismo, siempre y cuando,
                            no sé de aplicación al parágrafo de la Claúsula Novena, caso en el cual, la duración sería de <strong><strong>doce (12) meses</strong></strong>. <strong>CLAÚSULA DÉCIMA PRIMERA. Normatividad Aplicable</strong>.
                            Este contrato se regirá por lo establecido en el ordenamiento jurídico Colombiano, en especial, El Código de Comercio, Código Civil, Código General del Proceso
                            (Ley 1564 de 2012) y Ley 1480 del año 2011.
                            <br />



                            En señal de conformidad con lo acordado, EL(LA) FRANQUICIANTE y EL(LA)FRANQUICIADO(A) suscriben el presente acuerdo o contrato de forma voluntaria, en la ciudad de
                            Medellín - Antioquia y
                            <Input style={{ width: 190 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Ciudad' /> respectivamente, a los (<Input style={{ width: 40 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Día' />) días, del mes de
                            <Input style={{ width: 100 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Mes' />del año <Input style={{ width: 80 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Año' />
                            <br />
                            Ejemplares del mismo contenido y valor, con destino para cada una de las Partes, previo al entendimiento y aprobación entre ellas.
                            <Row>
                                <Col span={11}>
                                    <br />
                                    <img src='https://drophacking.blob.core.windows.net/documentos-internos/Firmas/firmaLinaP.png' />
                                    ____________________________________<br />
                                    <strong>LINA PATRICIA CALLE SALDARRIAGA</strong><br />
                                    REPRESENTANTE LEGAL CRECEL SAS<br />
                                    NIT: <strong>901.274.994-1</strong><br />
                                    <strong>LA FRANQUICIANTE</strong><br />
                                </Col>
                                <Col span={13} style={{ textAlign: 'left' }}>
                                    <br />
                                    {/*<SignatureCanvas penColor='black' canvasProps={{width: 300, height: 90, className: showBorder ? 'red-border' : 'no-border'}} />*/}
                                    <img src={sign} style={{ width: 300, height: 75 }} />
                                    <Input
                                        id="originalFileName"
                                        type="file"
                                        required
                                        name="originalFileName"
                                        onChange={e => handleFileRead(e)}
                                        size="small"
                                        className={showBorder ? 'show-Content' : 'hide-content'}
                                        accept='image/*'
                                    />                           ____________________________________<br />
                                    <Input style={{ width: 250 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nombre' /><br />
                                    CC. <Input style={{ width: 100 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nº' /> expedida en <Input style={{ width: 150 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Ciudad' /><br />
                                    <strong><strong>EL(LA) FRANQUICIADO(A)</strong></strong><br />
                                </Col>
                            </Row>
                        </p>

                    </div>
                </div>
                <Row>
                    <Col span={8}></Col>
                    <Col span={8}><Button className="generic-btn" onClick={() => downloadImage("Drophacking", props.id, 3)}>Generar y guardar documento</Button></Col>
                    <Col span={8}></Col>
                </Row>
                <br />
            </div>


        </div>
    )
}

export default DropHackingContract;