import { UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Upload, Row } from 'antd';
import { useModalUpdateStoreDocuments } from './hooks/useModalUpdateStoreDocuments';
import { IModalUpdateStoreDocumentsProps } from './IModalUpdateStoreDocumentsProps';
import './ModalUpdateStoreDocuments.css'
const ModalUpdateStoreDocuments = (props: IModalUpdateStoreDocumentsProps) => {

  const {
    closeModal,
    loading,
    setVisible,
    visible,
    uploadProductImageRut,
    uploadProductImageCedula1,
    uploadProductImageCedula2,
    storeDocuments,
    saveUploadDocument
  } = useModalUpdateStoreDocuments(props.storeId);

  const defaultImage = "https://drophacking.blob.core.windows.net/public-resources/Assets/Icons/sin%20imagen.jpg"

  return (
    <div className='modalUpdateStoreDocuments'>
      <Button onClick={() => setVisible(true)}>
        <UploadOutlined /> Cargar
      </Button>
      <Modal className='modalUpload update-modal'
        open={visible}
        width={800}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
      >
        <Row gutter={[16, 16]} style={{ marginTop: 20 }} className='update'>
          <div className='container-document'>
            <Upload {...uploadProductImageCedula1} accept='image/*'>
              {storeDocuments.cedula1 ? <img className='document-img' src={storeDocuments.cedula1} alt="" /> : <img className='document-img' src={defaultImage} alt="" />}
              <b>Cargar parte frontal de la cédula:</b>
              <Button icon={<UploadOutlined />}>Cargar</Button>
            </Upload>
          </div>

          <div className='container-document'>
            <Upload {...uploadProductImageCedula2} accept='image/*'>
              {storeDocuments.cedula1 ? <img className='document-img' src={storeDocuments.cedula2} alt="" /> : <img className='document-img' src={defaultImage} alt="" />}
              <b>Cargar parte posterior de la cédula:</b>
              <Button icon={<UploadOutlined />}>Cargar</Button>
            </Upload>
          </div>
          <div className='container-document'>
            {storeDocuments.rut ? <a href={storeDocuments.rut} target="_blank">Rut </a> : <p>Sin documento</p>}
            <Upload {...uploadProductImageRut} accept="application/pdf">
              <b>Cargar RUT:</b>
              <Button icon={<UploadOutlined />}>Cargar</Button>
            </Upload>
          </div>
        </Row>
        <div className='buttons'>
          <Button key="back" onClick={() => closeModal()}>
            Cancelar
          </Button>
          <Button key="submit" type="primary" loading={loading} onClick={() => saveUploadDocument()} >
            Guardar
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalUpdateStoreDocuments;
