import { Card, Col, Row, Select, Spin, Statistic, Switch } from "antd";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useStoreWalletManagement } from "../../Hooks/Admin/useStoreWalletManagement";

const { Option } = Select;

const StoreWalletManagement = () => {
  const {
    loading,
    historyMovementDetail,
    valueCustom,
    storeList,
    setSelectedStore,
    setCurrency,
    currency,
    totalWallet,
    Adelanto,
    cellRenderDeleteMovement,
  } = useStoreWalletManagement();

  return (
    <Row>
      <Col span={10}>
        <b>Tienda:</b>&nbsp;
        <Select
          placeholder="Tienda"
          style={{ width: 300 }}
          className="input"
          onChange={(store) => setSelectedStore(store)}
          showSearch
        >
          {storeList.map((x) => (
            <Option value={`${x.id}-${x.name}`} key={x.id}>
              {x.id}-{x.name}
            </Option>
          ))}
        </Select>
        &nbsp;&nbsp;&nbsp; Moneda: <b>{currency}</b> &nbsp;
        <Switch
          defaultChecked={currency == "COP"}
          onChange={(checked) => setCurrency(checked ? "COP" : "USD")}
        />
      </Col>
      <Col span={4}>
        <Spin tip="Cargando..." spinning={loading}>
          <Statistic
            title="Saldo total"
            value={totalWallet.toFixed(2)}
            prefix={"$"}
          />
        </Spin>
      </Col>
      <Col span={4}>
        <Spin tip="Cargando..." spinning={loading}>
          <Statistic
            title="Disponible para adelantar"
            value={Adelanto.toFixed(2)}
            valueStyle={{ color: "#3f8600" }}
            prefix={"$"}
          />
        </Spin>
      </Col>
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading}>
          <DataGrid
            dataSource={historyMovementDetail}
            allowColumnReordering={true}
            showBorders={true}
            rowAlternationEnabled={true}
          >
            <HeaderFilter visible={true} />
            <Export enabled={true} allowExportSelectedData={false} />
            <GroupPanel visible={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />
            <Column
              dataField="billingId"
              dataType="string"
              caption="# Factura"
            />
            <Column dataField="guia" caption="Guia" dataType="string" />
            <Column
              dataField="movement_type"
              dataType="string"
              caption="Tipo de movimiento"
            />
            <Column
              dataField="value"
              cellRender={valueCustom}
              caption="Valor"
            />
            {/* <Column dataField="shippingTotal" dataType="number" caption="Flete" /> */}
            <Column
              dataField="created_at"
              dataType="string"
              caption="Fecha"
              format="yyyy-MM-dd HH:mm"
            />
            <Column
              dataField="description"
              dataType="string"
              caption="Detalle"
              width={515}
            />
            <Column
              cellRender={cellRenderDeleteMovement}
              caption="Anular"
              width={100}
              alignment="center"
              allowEditing={false}
            />

            <Pager
              allowedPageSizes={[10, 25, 50, 100]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={20} />
          </DataGrid>
        </Spin>
      </Col>
    </Row>
  );
};

export default StoreWalletManagement;
