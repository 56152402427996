import { message } from "antd";
import { API } from "../../API/axios";
import { useState } from "react"
import { commissionResponse } from "./productCommision.interface";

export const useTableProductsCommission = () => {
    const [products, setProducts] = useState<commissionResponse[]>([]);

    function loadProducts() {
        API.get(`Products/commission/products`)
            .then((response) => {
                setProducts(response.data);
            })
            .catch((error) => message.error(error.message));
    }

    return {
        loadProducts,
        products,
    }

}

export function getComisionistas(storeProd: number) {
    return API.get(`Products/commissionagents/${storeProd}`)
        .then((response) => {
            return response.data
        });
}

export function updateCommission(mentoStoreProd: any) {
    API.put(`Products/commission`, mentoStoreProd)
        .then(() => message.success("Comisión actualizada"))
        .catch((error) =>
            message.error(`Error actualizando comisión. Error:${error.message}`))
}

