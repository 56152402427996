import { useContext } from "react";
import { PageContext } from "../../Hooks/ContextProvider";
import ApproveStore from "../ApproveStore/ApproveStore";
import CopysLibrary from "../CopysLibrary/CopysLibrary";
import Customers from "../Customers/Customers";
import DroppHooks from "../DroppHooks/DroppHooks";
import GenerateRegistertoken from "../GenerateRegistertoken/GenerateRegistertoken";
import Header from "../Header/Header";
import Panel from "../Panel/Panel";
import PendingRecharge from "../PendingRecharge/PendingRecharge";
import PowerSellers from "../PowerSellers/PowerSellers";
import Sales from "../Sales/Sales";
import SideBar from "../SideBar/SideBar";
import StoreWallet from "../StoreWallet/StoreWallet";
import StoresSalesList from "../StoresSalesList/StoresSalesList";
import Settlement from "../SuperAdmin/Settlement/Settlement";
import { DispatchList } from "../Warehouse/DispatchList/DispatchList";
import { Labels } from "../Warehouse/Labels/Labels";
import { PrintGuias } from "../Warehouse/PrintGuias/PrintGuias";
import WarehouseAdministration from "../WarehouseAdministration/WarehouseAdministration";
import WarehouseInventoryAdmin from "../WarehouseInventoryAdmin/WarehouseInventoryAdmin";
import { ILayoutProps } from "./ILayoutProps";
import "./Layout.css";
import Settings from "../Settings/Settings";
import GenerateBill from "../GenerateBill/GenerateBill";

const Layout = (props: ILayoutProps) => {
  const { selectedComponent } = useContext(PageContext);

  return (
    <div className="layout">
      <Header />
      <div className="container">
        <div className="sidenav">
          <SideBar />
        </div>
        <div className="content">
          {selectedComponent === "Panel" && <Panel />}
          {selectedComponent === "Billing" && <GenerateBill />}
          {selectedComponent === "DroppHooks" && <DroppHooks />}
          {selectedComponent === "PowerSellers" && <PowerSellers />}
          {selectedComponent === "Sales" && <Sales />}
          {selectedComponent === "Storetoken" && <ApproveStore />}
          {selectedComponent === "Settlement" && <Settlement />}
          {selectedComponent === "StoreWallet" && <StoreWallet />}
          {selectedComponent === "Transfers" && <WarehouseAdministration />}
          {selectedComponent === "WarehouseInventory" && <WarehouseInventoryAdmin />}
          {selectedComponent === "GenerateRegistertoken" && <GenerateRegistertoken />}
          {selectedComponent === "PendingRecharge" && <PendingRecharge />}
          {selectedComponent === "CopysLibrary" && <CopysLibrary />}
          {selectedComponent === "Labels" && <Labels />}
          {selectedComponent === "PrintGuias" && <PrintGuias />}
          {selectedComponent === "DispatchList" && <DispatchList />}
          {selectedComponent === "StoresSalesList" && <StoresSalesList />}
          {selectedComponent === "Customers" && <Customers />}
          {selectedComponent === "Settings" && <Settings />}
        </div>
      </div>
    </div>
  );
};

export default Layout;
