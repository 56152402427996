import { Button, Col, Row } from 'antd';
import './DropHacking.css';
import img from '../../Assets/img.jpg'
import img2 from '../../Assets/img2.jpg'


const DropHacking = () => {
    return (

        <div className='fondo1'>
            <Row >
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
                <Col xs={20} sm={20} md={5} lg={5} xl={5} style={{paddingTop: '180px'}} className='imgResponsive'>
                    <img src={img} style={{ width: '100%'}} className='img' />
                </Col>
                <Col xs={0} sm={0} md={6} lg={6} xl={6} style={{paddingTop: '140px'}}>
                    <img src={img2} style={{ width: '100%'}} className='img' />
                </Col>
                <Col xs={0} sm={0} md={5} lg={5} xl={5} style={{paddingTop: '180px'}}>
                    <img src={img} style={{ width: '100%'}} className='img' />
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
            </Row><br/>

            <Row >
                <Col xs={2} sm={2} md={1} lg={1} xl={1} ></Col>
                <Col xs={20} sm={20} md={22} lg={22} xl={22} className='textDropHacking'>
                    “Como el <b>sistema DropHacking</b> permite <br />
                    convertir personas comunes y corrientes en  <br />
                    <b>emprendedores</b> que facturan millones en el  <br />
                    mercado Colombiano”
                </Col>
                <Col xs={2} sm={2} md={1} lg={1} xl={1} ></Col>
            </Row><br/><br/>
        </div>
    )
}

export default DropHacking;

