import { Col, Row } from "antd";

import { DataGrid, Popup, ScrollView } from "devextreme-react";
import { Column, ColumnFixing, Editing, Export, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Sorting } from "devextreme-react/data-grid";
import { getComisionistas, updateCommission, useTableProductsCommission } from "./useTableProductsCommissionService";
import { useCallback, useEffect, useState } from "react";


interface Commision {
    commissionAgents?: CommissionAgent[];
    actualProduct?: any;
}

interface CommissionAgent {
    email?: string;
    name?: string;
    commissionValue?: number;
    mentorGroupName?: string;
    mentorStoresId?: number
}
const TableProductCommission = (props: any) => {
    const [commissionState, setCommission] = useState<Commision>({});
    const { loadProducts, products } = useTableProductsCommission();

    const [popupWithScrollViewVisible, setPopupWithScrollViewVisible] = useState(false);
    const showPopupWithScrollView = useCallback(() => {
        setPopupWithScrollViewVisible(true);
    }, [setPopupWithScrollViewVisible]);
    const hide = useCallback(() => {
        setPopupWithScrollViewVisible(false);
    }, [setPopupWithScrollViewVisible]);

    useEffect(() => {
        loadProducts();
    }, []);


    function handleGetCommissionAgent(StoreProduct: any) {
        getComisionistas(StoreProduct.store_Product.id).then((values: any) => {
            setCommission({ ...commissionState, actualProduct: StoreProduct, commissionAgents: values });
            showPopupWithScrollView();
        })
    }

    function handleUpdateComission(data: any) {
        let newCommission = {
            commissionValue: data.commissionValue,
            mentorStoresId: data.mentorStoreId,
            store_ProductId: commissionState.actualProduct.store_ProductId
        }
        updateCommission(newCommission);
    }

    return (
        <div>
            <Popup
                visible={popupWithScrollViewVisible}
                onHiding={hide}
                showCloseButton={true}
                title="Comisiones">
                <ScrollView width='auto' height='auto'>
                    <div className="caption"><strong>{commissionState?.actualProduct?.store_Product?.product.name}</strong> </div>
                    <br />
                    <div className="content">{commissionState?.actualProduct?.store_Product?.store.name}
                        <hr />
                        <div>
                            <DataGrid
                                id="products"
                                dataSource={commissionState.commissionAgents}
                                showBorders={true}
                                remoteOperations={true}
                                allowColumnResizing={true}
                                columnResizingMode={"widget"}
                                showRowLines={true}
                                onRowUpdated={(value) => handleUpdateComission(value.data)}
                            >
                                <HeaderFilter visible={true} />
                                <HeaderFilter visible={true} />
                                <Sorting mode="multiple" />
                                <GroupPanel visible={false} />
                                <ColumnFixing enabled={true} />
                                <FilterRow visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <Grouping autoExpandAll={false} />
                                <Pager
                                    showInfo={true}
                                    showNavigationButtons={true}
                                    showPageSizeSelector={true}
                                    visible={true}
                                />

                                <Editing
                                    mode="cell"
                                    allowUpdating={true}
                                />
                                <Column
                                    allowEditing={false}
                                    dataField="email"
                                    caption="Email"
                                    alignment="center"
                                />
                                <Column
                                    allowEditing={false}
                                    dataField="name"
                                    caption="Nombre"
                                    alignment="center" />

                                <Column
                                    dataField="commissionValue"
                                    caption="Valor comisión"
                                    alignment="center" />
                                <Column
                                    dataField="commissionValueUSD"
                                    caption="Valor comisión USD"
                                    alignment="center" />
                                <Column
                                    allowEditing={false}
                                    dataField="mentorGroupName"
                                    caption="Grupo"
                                    alignment="center" />
                            </DataGrid>
                        </div>
                    </div>
                </ScrollView>
            </Popup>

            {(<Row gutter={[16, 16]}>
                <Col span={24} style={{ padding: 10, backgroundColor: "white" }}>
                    <DataGrid
                        id="products"
                        dataSource={products}
                        showBorders={true}
                        remoteOperations={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        showRowLines={true}
                    >
                        <HeaderFilter visible={true} />
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <GroupPanel visible={false} />
                        <ColumnFixing enabled={true} />
                        <FilterRow visible={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />
                        <Pager
                            showInfo={true}
                            showNavigationButtons={true}
                            showPageSizeSelector={true}
                            visible={true}
                        />
                        <Editing

                            useIcons={true}
                        />
                        <Column
                            allowEditing={false}
                            dataField="store_Product.store.name"
                            caption="Tienda"
                            alignment="center"
                        />
                        <Column
                            allowEditing={false}
                            dataField="store_Product.product.name"
                            caption="Producto"
                            alignment="center" />
                        <Column type="buttons"
                            buttons={[
                                {
                                    text: "Ver usuarios",
                                    hint: "Ver usuarios",
                                    icon: "",
                                    onClick: (x: any) => {
                                        handleGetCommissionAgent(x.row.data);
                                    },
                                },
                            ]}>
                        </Column>
                    </DataGrid>
                </Col>
            </Row>)
            }
        </div>

    );
}

export default TableProductCommission;

