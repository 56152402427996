import { IStoresSalesListProps } from "./IStoresSalesListProps";
import { Alert, Button, Col, DatePicker, Row, Spin, Select } from "antd";
import { useStoresSalesList } from "./Hooks/useStoresSalesList";
import DataGrid, {
  Column,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";

import "./StoresSalesList.css";
import ModalDetalleGuia from "../ModalDetalleGuia/ModalDetalleGuia";
import { storeModel } from "../../Models/store";

const { RangePicker } = DatePicker;
const { Option } = Select;

const StoresSalesList = (props: IStoresSalesListProps) => {
  const {
    filterDates,
    setFilterDates,
    loading,
    findGuia,
    guia,
    modalSearchGuiaVisible,
    setModalSearchGuiaVisible,
    getBills,
    billList,
    cellRender,
    storesName,
    selectedStore,
    handleStores,
    onChangeDates,
    storeMovements,
    stores,
    loadingStore,
  } = useStoresSalesList();

  return (
    <div className="storesSalesList">
      <Row gutter={[16, 16]}>
        <Col span={2}></Col>
        <Col span={22}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Select
                style={{ width: 300, marginTop: 10 }}
                placeholder="Seleccionar tienda..."
                optionFilterProp="children"
                loading={loadingStore}
                onChange={handleStores}
              >
                {stores.map((x: storeModel) => (
                  <Option key={x.id} value={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>
            </Col>
            {selectedStore !== 0 ? (
              <Col span={6}>
                <RangePicker
                  style={{ width: "100%", marginTop: 10 }}
                  onChange={(e) => onChangeDates(e)}
                />
              </Col>
            ) : (
              <>
                <Col span={6}>
                  <RangePicker
                    style={{ width: "100%", marginTop: 10 }}
                    defaultValue={filterDates}
                    onChange={(e) => setFilterDates(e)}
                  />
                </Col>
                <Col span={2}>
                  <Button
                    type="primary"
                    style={{ marginTop: 10 }}
                    onClick={() => getBills(filterDates)}
                  >
                    Buscar
                  </Button>
                </Col>
              </>
            )}

            {selectedStore !== 0 ? (
              <Col span={14}>
                <Row style={{ padding: "10px" }}>
                  <Col span={7}>
                    <div className="contenedorTotales">
                      <div className="tituloTotales">Ventas:</div>
                      <div className="valorPositivo">
                        {`$ ${storeMovements
                          .map((x: any) => {
                            return parseFloat(
                              x.collect_value.replace(/\$\s?|(,*)/g, "")
                            );
                          })
                          .reduce(
                            (prev: number, curr: number) => prev + curr,
                            0
                          )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                    </div>
                  </Col>
                  <Col span={7} style={{ marginLeft: "10px" }}>
                    <div className="contenedorTotales">
                      <div className="tituloTotales">Fletes:</div>
                      <div className="valorNegativo">
                        {`$ -${storeMovements
                          .map((x: any) => {
                            return parseFloat(
                              x.delivery_value.replace(/\$\s?|(,*)/g, "")
                            );
                          })
                          .reduce(
                            (prev: number, curr: number) => prev + curr,
                            0
                          ).toFixed(1)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                    </div>
                  </Col>
                  <Col span={7} style={{ marginLeft: "10px" }}>
                    <div className="contenedorTotales">
                      <div className="tituloTotales">Costo:</div>
                      <div className="valorNegativo">
                        {`$ -${storeMovements
                          .map((x: any) => {
                            return parseFloat(
                              x.value.replace(/\$\s?|(,*)/g, "")
                            );
                          })
                          .reduce(
                            (prev: number, curr: number) => prev + curr,
                            0
                          )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            ) : (
              <>
                <Col span={14}>
                  <Row style={{ padding: "10px" }}>
                    <Col span={7}>
                      <div className="contenedorTotales">
                        <div className="tituloTotales">Ventas:</div>
                        <div className="valorPositivo">
                          {`$ ${billList
                            .map((x: any) => {
                              return parseFloat(
                                x.collect_value.replace(/\$\s?|(,*)/g, "")
                              );
                            })
                            .reduce(
                              (prev: number, curr: number) => prev + curr,
                              0
                            )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                      </div>
                    </Col>
                    <Col span={7} style={{ marginLeft: "10px" }}>
                      <div className="contenedorTotales">
                        <div className="tituloTotales">Fletes:</div>
                        <div className="valorNegativo">
                          {`$ -${billList
                            .map((x: any) => {
                              return parseFloat(
                                x.delivery_value.replace(/\$\s?|(,*)/g, "")
                              );
                            })
                            .reduce(
                              (prev: number, curr: number) => prev + curr,
                              0
                            ).toFixed(1)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                      </div>
                    </Col>
                    <Col span={7} style={{ marginLeft: "10px" }}>
                      <div className="contenedorTotales">
                        <div className="tituloTotales">Costo:</div>
                        <div className="valorNegativo">
                          {`$ -${billList
                            .map((x: any) => {
                              return parseFloat(
                                x.value.replace(/\$\s?|(,*)/g, "")

                              );
                            })
                            .reduce(
                              (prev: number, curr: number) => prev + curr,
                              0
                            )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
          <Spin tip="Cargando..." spinning={loading}>
            {billList.length > 0 && selectedStore === 0 ? (
              <Col style={{ marginTop: "10px" }}>
                <Spin tip="Cargando..." spinning={loading}>
                  <DataGrid style={{ marginTop: 10 }} dataSource={billList}>
                    <HeaderFilter visible={true} />
                    <Export enabled={true} allowExportSelectedData={false} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <FilterRow visible={true} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={true} />
                    <Column
                      dataField="guia"
                      alignment="center"
                      caption="Ver Guia"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Guia",
                          icon: "find",
                          // visible:  (row: any) => row.row.data.totalPendingBillNews > 0 ? true : false,
                          onClick: (row: any) => {
                            findGuia(row.row.data.guia);
                          },
                        },
                      ]}
                    />
                    <Column dataField="guia" caption="Guia" dataType="string" />
                    <Column
                      dataField="store"
                      caption="Tienda"
                      dataType="string"
                    />
                    <Column
                      dataField="productos"
                      dataType="string"
                      caption="Productos"
                      width={400}
                    />
                    <Column
                      dataField="created_at"
                      dataType="string"
                      caption="Fecha"
                    />
                    <Column
                      dataField="collect_value"
                      dataType="string"
                      caption="Recaudo"
                    />
                    <Column
                      dataField="delivery_value"
                      dataType="string"
                      caption="Flete"
                    />
                    <Column
                      dataField="value"
                      dataType="string"
                      caption="Costo"
                    />
                    <Column
                      dataField="status"
                      dataType="string"
                      caption="Estado"
                    />
                    <Column
                      dataField="company_Transport"
                      caption="Transportadora"
                      dataType="string"
                    />
                    <Column
                      dataField="payment_home"
                      dataType="string"
                      caption="Forma de pago"
                    />
                    <Column
                      dataField="city"
                      dataType="string"
                      caption="Ciudad"
                    />
                    <Column
                      dataField="province"
                      dataType="string"
                      caption="Departamento"
                    />
                  </DataGrid>
                </Spin>
              </Col>
            ) : storeMovements.length > 0 && selectedStore !== 0 ? (
              <Col style={{ marginTop: "10px" }}>
                <Spin tip="Cargando..." spinning={loading}>
                  <DataGrid
                    style={{ marginTop: 10 }}
                    dataSource={storeMovements}
                  >
                    <HeaderFilter visible={true} />
                    <Export enabled={true} allowExportSelectedData={false} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <FilterRow visible={true} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={true} />
                    <Column
                      dataField="guia"
                      alignment="center"
                      caption="Ver Guia"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Guia",
                          icon: "find",
                          // visible:  (row: any) => row.row.data.totalPendingBillNews > 0 ? true : false,
                          onClick: (row: any) => {
                            findGuia(row.row.data.guia);
                          },
                        },
                      ]}
                    />
                    <Column dataField="guia" caption="Guia" dataType="string" />
                    <Column
                      dataField="storeId"
                      caption="Tienda"
                      dataType="string"
                    >
                      <Lookup
                        dataSource={storesName}
                        valueExpr="id"
                        displayExpr="name"
                      ></Lookup>
                    </Column>
                    <Column
                      dataField="productos"
                      dataType="string"
                      caption="Productos"
                      width={400}
                    />
                    <Column
                      dataField="created_at"
                      dataType="string"
                      caption="Fecha"
                    />
                    <Column
                      dataField="collect_value"
                      dataType="string"
                      caption="Recaudo"
                    />
                    <Column
                      dataField="delivery_value"
                      dataType="string"
                      caption="Flete"
                    />
                    <Column
                      dataField="value"
                      dataType="string"
                      caption="Costo"
                    />
                    <Column
                      dataField="status"
                      dataType="string"
                      caption="Estado"
                    />
                    <Column
                      dataField="company_TransportId"
                      allowSorting={false}
                      cellRender={cellRender}
                      caption="Transportadora"
                    />
                    <Column
                      dataField="payment_home"
                      dataType="string"
                      caption="Forma de pago"
                    />
                  </DataGrid>
                </Spin>
              </Col>
            ) : (
              <Col style={{ marginTop: "10px" }}>
                <Alert
                  message="No hay datos en este rango de fechas"
                  description="Seleccione un rango fechas"
                  type="warning"
                  showIcon
                />
              </Col>
            )}
          </Spin>
        </Col>
      </Row>

      {modalSearchGuiaVisible && (
        <ModalDetalleGuia
          guia={guia}
          visible={modalSearchGuiaVisible}
          setVisible={setModalSearchGuiaVisible}
        />
      )}
    </div>
  );
};

export default StoresSalesList;
