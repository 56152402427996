import React from 'react';
import { IModalWarehouseDispatchReportProps } from './IModalWarehouseDispatchReportProps';

// Ant
import { Button, Col, Divider, Modal, Row } from 'antd';

// DevExtreme
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, Grouping } from 'devextreme-react/data-grid';


const ModalWarehouseDispatchReport = (props:IModalWarehouseDispatchReportProps) => {
  return (
    <div className='modalWarehouseDispatchReport'>
      <Modal
        width={1000}
        open={props.ModalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => {
          props.setModalVisible(false);
        }}
        footer=
        {[
          <Button key="submit" type="primary" onClick={() => props.setModalVisible(false)}> Cerrar </Button >
        ]}
      >
        <Button onClick={props.imprimirRuta}>Imprimir</Button>
        <div id='divToPrint' style={{ textAlign: 'center' }}>
          <Row style={{ marginTop: 30 }}>
            <Col span={24}>
              <h1>REPORTE RELACIÓN DE DESPACHOS</h1>
            </Col>
          </Row>
          <Divider />
          <DataGrid
            dataSource={props.dispatchReport}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
          >
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />

            <Column dataField="guia" caption="Guia" dataType="string" />
            <Column dataField="billDate" caption="Fecha facturación" dataType="string" />
            <Column dataField="store" caption="Tienda" dataType="string" />
            <Column dataField="messenger" caption="Mensajero" dataType="string" />
          </DataGrid>
        </div>
      </Modal>
    </div>
  );
};

export default ModalWarehouseDispatchReport;
