import { PageHeader, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useCompanyTransportWarehouse } from "../CompanyTransportWarehouse/useCompanyTransportWarehouseHook";
import WarehouseInventory from "../WarehouseInventory/WarehouseInventory";
import WarehouseInventoryDetail from "../WarehouseInventoryDetail/WarehouseInventoryDetail";
import "./WarehouseInventoryAdmin.css";
import { useWareHouseInventoryAdmin } from "./useWarehouseInventoryAdminHook";

const WarehouseInventoryAdmin = () => {
  const { TabPane } = Tabs;
  const [stockSelected, setStockSelected] = useState<number>(1);

  const {
    dispatchWarehouseSelected,
    dispatchInventoryList,
    onChangeDispatchWarehouse,
    devolutionWarehouseSelected,
    devolutionInventoryList,
    onChangeDevolutionWarehouse,
    loading,
    getProducts,
    productList,
    insertInventoryMovement,
    insertTransferMovement,
    onChangeWarehouse,
    selectedWarehouse,
    inventoryList
  } = useWareHouseInventoryAdmin();

  const {
    getWarehouseList,
    getCompanyTransportList,
    warehouse
  } = useCompanyTransportWarehouse();

  useEffect(() => {
    getWarehouseList();
    getCompanyTransportList();
    getProducts();
  }, []);

  const handleChange = (activeKey: string) => {
    setStockSelected(parseInt(activeKey, 10))
  };

  return (
    <div className="warehouse-inventory-admin__container">
      <PageHeader
        className="site-page-header"
        onBack={() => (window.location.href = "/HomeStore")}
        title={`Inventario`}
        subTitle=""
      />
      <Tabs defaultActiveKey="1" type="card" onChange={handleChange}>
        <TabPane tab="Stock General" key="1"></TabPane>
        <TabPane tab="Stock Salidas" key="2"></TabPane>
        <TabPane tab="Stock Devoluciones" key="3"></TabPane>
      </Tabs>
      <div>
        {stockSelected === 1 &&
          <WarehouseInventory
            warehouseList={warehouse}
            productList={productList}
            insertInventoryMovement={insertInventoryMovement}
            insertTransferMovement={insertTransferMovement}
            loading={loading}
            onChangeWarehouse={onChangeWarehouse}
            selectedWarehouse={selectedWarehouse}
            inventoryList={inventoryList}
          />
        }
        {stockSelected === 2 && (
          <WarehouseInventoryDetail
            selectedWarehouse={dispatchWarehouseSelected}
            onChangeFunction={onChangeDispatchWarehouse}
            inventoryList={dispatchInventoryList}
            warehouseList={warehouse}
            stockName='Stock Salidas'
            loading={loading}
          />
        )}
        {stockSelected === 3 && (
          <WarehouseInventoryDetail
            selectedWarehouse={devolutionWarehouseSelected}
            onChangeFunction={onChangeDevolutionWarehouse}
            inventoryList={devolutionInventoryList}
            warehouseList={warehouse}
            stockName='Stock Devoluciones'
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default WarehouseInventoryAdmin;
