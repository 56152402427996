import {
  Col,
  Input,
  Row,
  Switch,
  InputNumber,
  Tabs,
  Spin,
  Badge,
  Button,
  Popconfirm,
} from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./Category.css";
import DataGrid, {
  Column,
  FilterRow,
  Selection,
  Export,
  HeaderFilter,
  Popup,
} from "devextreme-react/data-grid";
import { useCategories } from "../../Hooks/useCategories";
import { useCallback, useState } from "react";
import TableContractsCategory from "../TableContractsCategory/TableContractsCategory";

const { TabPane } = Tabs;

const Category = () => {
  const {
    loading,
    selectedProductKeys,
    selectedStoreRows,
    selectedProductRows,
    storeList,
    selectedStoreKeys,
    productList,
    categories,
    selectCategory,
    selectedCategory,
    postCategory,
    setSelectedCategory,
    deleteCategory,
    newCategory
  } = useCategories();

  const [viewPopupCategories, setViewPopupCategories] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>("");

  const hide = useCallback(() => {
    setViewPopupCategories(false);
  }, [setViewPopupCategories]);


  return (
    <div>

      {selectedCategory.id != 0 && (
        <Popup
          visible={viewPopupCategories}
          onHiding={hide}
          showCloseButton={true}
          title="Comisiones">

        </Popup>
      )}
      <Row>
        <Col span={6} className="leftPane">
          <div className="leftPaneTitle">
            Categorías{" "}
            <PlusCircleOutlined className="editIcon" title="Agregar categoría" onClick={newCategory} />
            <Input onChange={(e) => setFilterText(e.target.value.toLocaleLowerCase())} ></Input>
          </div>
          <div className="leftPaneCategoryList">
            {categories.map((x) => x.name.toLocaleLowerCase().includes(filterText) && (
              <div
                className={`leftPaneCategoryItem ${x.id == selectedCategory?.id ? "selectedCategory" : ""}`}
                onClick={(_) => selectCategory(x.id)}
              >
                {x.name} <EditOutlined className="editIcon" title="Editar" />
              </div>
            ))}
          </div>
        </Col>
        <Col span={18} className="rightPane">
          <div className="editCategoryTitle">
            Nombre:
            <Input
              type="text"
              value={selectedCategory?.name}
              className="editCategoryInput"
              onChange={(e) =>
                setSelectedCategory({ ...selectedCategory, name: e.target.value })
              }
            />
            <Button
              className="CategoryButton generic-btn"
              loading={loading}
              onClick={(_) => postCategory(selectedCategory)}
            >
              Guardar Cambios
            </Button>
            {selectedCategory?.id != 0 && (
              <Popconfirm
                className="CategoryButton"
                title="Eliminar esta categoría ?"
                onConfirm={(_) => deleteCategory(selectedCategory.id)}
              >
                <Button loading={loading}>Eliminar Categoría</Button>
              </Popconfirm>
            )}
          </div>
          <div className="rightPaneTitle">Picking y Packing</div>
          <div className="CardPriceContainer">
            <Switch
              className="rightPaneSwitch"
              checkedChildren="Si"
              unCheckedChildren="No"
              checked={selectedCategory?.picking_Packing_COP > 0}
            />{" "}
            Colombia (COP):
            <InputNumber
              className="priceInput"
              min={0}
              value={selectedCategory?.picking_Packing_COP}
              onChange={(e) =>
                setSelectedCategory({
                  ...selectedCategory,
                  picking_Packing_COP: e,
                })
              }
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </div>
          <div className="CardPriceContainer">
            <Switch
              className="rightPaneSwitch"
              checkedChildren="Si"
              unCheckedChildren="No"
              checked={selectedCategory?.picking_Packing_USD > 0}
            />{" "}
            Ecuador (USD):
            <InputNumber
              className="priceInput"
              min={0}
              value={selectedCategory?.picking_Packing_USD}
              onChange={(e) =>
                setSelectedCategory({
                  ...selectedCategory,
                  picking_Packing_USD: e,
                })
              }
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </div>

          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <Badge count={selectedStoreKeys?.length}>
                  Tiendas&nbsp;&nbsp;
                </Badge>
              }
              key="1"
            >
              <Spin spinning={loading}>
                <DataGrid
                  dataSource={storeList}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  onSelectedRowKeysChange={selectedStoreRows}
                  selectedRowKeys={selectedStoreKeys}
                >
                  <Selection mode="multiple" selectAllMode={"allPages"} />
                  <FilterRow visible={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Column
                    dataField="id"
                    dataType="number"
                    caption="Id"
                    width={100}
                  />
                  <Column dataField="name" dataType="string" caption="Tienda" />
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane
              tab={
                <Badge count={selectedProductKeys?.length}>
                  Productos&nbsp;&nbsp;
                </Badge>
              }
              key="2"
            >
              <Spin spinning={loading}>
                <DataGrid
                  dataSource={productList}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  onSelectedRowKeysChange={selectedProductRows}
                  selectedRowKeys={selectedProductKeys}
                >
                  <Selection mode="multiple" selectAllMode={"allPages"} />
                  <FilterRow visible={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Column
                    dataField="id"
                    dataType="number"
                    caption="Id"
                    width={100}
                  />
                  <Column dataField="name" dataType="string" caption="Producto" />
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane tab={
              <Badge>
                Contratos
              </Badge>
            }
              key="3">
              <Spin spinning={loading}></Spin>
              <TableContractsCategory id={selectedCategory?.id}></TableContractsCategory>

            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default Category;
