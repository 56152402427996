import { Button, Col, message, Modal, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { IModalBtnDevolverProps } from './IModalBtnDevolverProps';
import './ModalBtnDevolver.css';
import { Input } from 'antd';
import { BillData } from '../../Models/Bill';
import { API } from '../../API/axios';
import { updateBill } from '../../Hooks/SuperAdmin/useEditBill';

const { TextArea } = Input;

const ModalBtnDevolver = (props:IModalBtnDevolverProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [billData, setbillData] = useState<BillData>();

  const closeModal = () => {
    setVisible(false);
  }

  useEffect(() => {
    GetDeliveryData(props.guia);
  }, [props.guia]);

  const GetDeliveryData = (guia: string) => {
    API.get(`/Bills/GetBillByGuia/${guia}`).then(res => {
      if (res.data !== "") {
        setbillData(res.data);
      }
    })
  }

  const ReturnBill = (changeFreight: boolean) => {
    let el = {
      billId: billData?.id,
      chargeFreight: changeFreight,
      observaciones_Cancelacion: billData?.observaciones_Cancelacion
    }
    API.put(`/Bills/ReturnBill`, el).then(res=>{
      let array: updateBill[] = [];
      let el: any = billData;
      array.push(el);
      el.Status = 'Devuelto';
      API.put(`/Bills`, array).then(res => {
        message.success('La Guia fue devuelta');
        setVisible(false);
      })
    })
    .catch(err => {
      message.error('Se presentó un error intentelo nuevamente');
      console.error(err);
    })
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setbillData((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };



  return (
    <div className={`modalBtnDevolver`}>
      <Row>
        <Col span={24}>
          <Button type='primary' onClick={() => setVisible(true)}>Devolver</Button>
        </Col>
      </Row>
      <Modal
        open={visible}
        title={`Devolver Guia ${props.guia}`}
        width={1000}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={[
          <Button key="back" onClick={() => closeModal()}>
            Cerrar
          </Button>
        ]}
      >
        <div style={{padding:20}}> 
         Comentario:
        <TextArea 
          rows={4} 
          name="observaciones_Cancelacion"
          onChange={(e)=>handleChange(e)}
          value={billData?.observaciones_Cancelacion}
          style={{marginBottom:15}}
          />
        
        {billData?.company_TransportId !== 1 ?
        <Row>
          <Col span={12}>
            <Button onClick={()=>ReturnBill(true)}>aplicar el cobro del domicilio por valor de {billData?.delivery_value}</Button>
          </Col>
          <Col span={12}>
            <Button type='primary' onClick={()=>ReturnBill(false)}>Realizar devolución sin cobro de flete a la tienda</Button>
          </Col>
        </Row>
        :
        <Col span={12}>
            <Button type='primary' onClick={()=>ReturnBill(true)}>Aplicar devolución de envía</Button>
          </Col>
        }
        </div>
      </Modal>
    </div>
  );
};

export default ModalBtnDevolver;
