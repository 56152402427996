import React, { useState, useEffect } from "react";
import { IStoreRegistrationProps } from "./IStoreRegistrationProps";
import "./StoreRegistration.css";
import { Row, Col, Input, Select, Button, message, Upload } from "antd";
import utils from "../../../utils";
import { UploadOutlined } from "@ant-design/icons";
import { city } from "../../../Models/city.model";
import { storeModel } from "../../../Models/store";
import { API } from "../../../API/axios";
const { Option } = Select;

const StoreRegistration = (props: IStoreRegistrationProps) => {
  const [listCities, setListCities]: any = useState<Array<city>>([]);
  const [listCitiesFiltered, setlistCitiesFiltered]: any = useState<Array<city>>([]);

  useEffect(() => {
    getCities();
  }, []);

  const handleSearchCiudad = (value: string) => {
    if (value) {
      setlistCitiesFiltered(
        listCities.filter((x: city) => x.name.toLowerCase().includes(value))
      );
    } else {
      setlistCitiesFiltered([]);
    }
  };

  const getCities = async () => {
    await API.get<city[]>(`/Cities`).then((res) => {
      res.data.map((x) => {
        return (x.name = `${x.name} (${x.provinceName})`);
      });
      setListCities(utils.sortArray(res.data, "name"));
      setlistCitiesFiltered(utils.sortArray(res.data, "name"));
    });
  };

  async function onchangeUploadImage(event: any, documentType: string) {
    const file = event.target.files[0];
    if (documentType === "RUT") {
      props.setRut(file);
    } else if (documentType === "Cedula1") {
      props.setCedula1(file);
    } else if (documentType === "Contrato") {
      props.setContrato(file);
    } else {
      props.setCedula2(file);
    }
  }

  const updateState = (value: any, prop: string) => {
    let arrayAux = { ...(props.store as any) };
    arrayAux[prop] = value;
    props.setStore(arrayAux);
  };

  const goToRegisterUser = () => {
    let storeTemp: storeModel = props.store;
    if (storeTemp !== undefined) {
      debugger
      if (
        storeTemp.name !== "" &&
        storeTemp.adress !== "" &&
        storeTemp.city !== "" &&
        storeTemp.phone !== "" &&
        storeTemp.cel !== "" &&
        storeTemp.identification_type !== undefined &&
        (storeTemp.identification !== "" && storeTemp.identification !== undefined)
      ) {
        if (storeTemp.identification_type == "Cédula" || storeTemp.identification_type == "Nit") {
          if (storeTemp.rut === undefined || props.Rut == undefined) {
            storeTemp.rut === undefined ? message.warn("Debe llenar el campo RUT") : message.warn("Debe adjuntar el RUT");
          } else {
            props.setCurrentStep(1);
          }
        } else {
          props.setCurrentStep(1);
        }

      } else {
        message.warn("Debe llenar todos los datos de la tienda");
      }
    } else {
      message.warn("Debe llenar todos los datos de la tienda");
    }
  };

  const propsUploadProductImageRut: any = {

    transformFile(file: any) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        uploadDocument("RUT", file);
        reader.readAsDataURL(file);
        message.success(`${file.name} se ha cargado correctamente`);
      });
    },
  };

  const propsUploadProductImageCedula1: any = {
    transformFile(file: any) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        // props.setCedula1(file)
        uploadDocument("CEDULA1", file);
        reader.readAsDataURL(file);
        message.success(`${file.name} se ha cargado correctamente`);
      });
    },
  };

  const propsUploadProductImageCedula2: any = {
    transformFile(file: any) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        uploadDocument("CEDULA2", file);
        // props.setCedula2(file)
        reader.readAsDataURL(file);
        message.success(`${file.name} se ha cargado correctamente`);
      });
    },
  };

  const uploadDocument = async (document: string, image: any) => {
    try {
      if (image.size > 1000000) {
        message.warning("El archivo no puede superar 1MB o 1.000 KB");
      } else {
        let formData = new FormData();
        const myNewFile = new File([image], `${document}.${image.type}`, {
          type: image.type,
        });
        formData.append("Attachments", myNewFile);
        formData.append("strContainerName", `store-documents`);
        formData.append("folderName", `store-documents`);
        await API.post(`/BlobStorage/UploadImageGallery`, formData).then(
          (res: any) => {
            if (document === "CONTRATO") {
              props.setContrato(res.data);
            }
            if (document === "RUT") {
              props.setRut(res.data);

            }
            if (document === "CEDULA1") {
              props.setCedula1(res.data);
            }
            if (document === "CEDULA2") {
              props.setCedula2(res.data);
            }
          }
        );
      }
    } catch (error) {
      message.error(`Error al cargar la imagen. ${error}`);
    }
  };

  return (
    <div className={`storeRegistration`}>
      <Row gutter={[16, 16]}>
        {/*  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button
            className={'btnRegistrarTienda'}
            loading={loadingDownload}
            type="primary" onClick={() => downloadContract()}
          >Descargar Contrato</Button>
        </Col> */}
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            required={true}
            placeholder="Nombre completo de la tienda"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-usuario.png"
                className="form-icon"
                alt="icono-de-tienda"
              />
            }
            onChange={(e) => updateState(e.target.value, "name")}
            className="input"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Input
            placeholder="Dirección"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-dirección.png"
                className="form-icon"
                alt="icono-de-direccion"
              />
            }
            onChange={(e) => updateState(e.target.value, "adress")}
            className="input"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Ciudad"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchCiudad}
            onChange={(e) => updateState(e, "city")}
            notFoundContent={null}

          >
            {listCitiesFiltered.map((x: city) => (
              <Option key={x.id} value={x.name}>
                {x.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Input
            placeholder="Teléfono"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-telefono.png"
                className="form-icon"
                alt="icono-de-telefono"
              />
            }
            onChange={(e) => updateState(e.target.value, "phone")}
            className="input"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Input
            placeholder="Teléfono"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-celular.png"
                className="form-icon"
              />
            }
            onChange={(e) => updateState(e.target.value, "cel")}
            className="input"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            placeholder="Número del RUT"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-del-rut.png"
                className="form-icon"
              />
            }
            onChange={(e) => updateState(e.target.value, "rut")}
            className="input"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Select
            style={{ width: "100%" }}
            onChange={(e) => updateState(e, "identification_type")}
            placeholder="Tipo de documento"
          >
            <Option value="Cédula">Cédula</Option>
            <Option value="Nit">Nit</Option>
            <Option value="Pasaporte">Pasaporte</Option>
            <Option value="PEP">PEP</Option>
            <Option value="PPT">PPT</Option>
            <Option value="Otros - Documento Extranjero">
              Otros - Documento Extranjero
            </Option>
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Input
            placeholder="No. de Identificación"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-identificación.png"
                className="form-icon"
              />
            }
            onChange={(e) => updateState(e.target.value, "identification")}
            className="input"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Lugar de Expedición"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchCiudad}
            onChange={(e) => updateState(e, "identification_expedition")}
            notFoundContent={null}
          >
            {listCitiesFiltered.map((x: city) => (
              <Option key={x.id} value={x.name}>
                {x.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <b>Cargar RUT:</b>
          <span
            style={{
              fontSize: 12,
              marginLeft: 10,
              textDecoration: "underline",
            }}
          >
            En formato .pdf
          </span>
          {/*               <input type="file" id="rut" name="rut" accept="application/pdf" style={{ width: '100%' }} onChange={(e) => onchangeUploadImage(e, 'RUT')} />
           */}{" "}
          <br />
          <Upload {...propsUploadProductImageRut} accept="application/pdf">
            <Button icon={<UploadOutlined />}>Cargar</Button>
          </Upload>
        </Col>
        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <b>Cargar Contrato:</b>
          <span
            style={{
              fontSize: 12,
              marginLeft: 10,
              textDecoration: "underline",
            }}
          >
            En formato .pdf
          </span>
          <br />
          <Upload {...propsUploadProductImageContrato}>
            <Button icon={<UploadOutlined />}>Cargar</Button>
          </Upload>
        </Col> */}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <b>Cargar parte frontal de la cédula:</b>
          <span
            style={{
              fontSize: 12,
              marginLeft: 10,
              textDecoration: "underline",
            }}
          >
            En formato .png
          </span>
          {/*               <input type="file" id="cedula1" accept="image/x-png" name="cedula1" style={{ width: '100%' }} onChange={(e) => onchangeUploadImage(e, 'Cedula1')} />
           */}{" "}
          <br />
          <Upload {...propsUploadProductImageCedula1} accept='image/*'>
            <Button icon={<UploadOutlined />}>Cargar</Button>
          </Upload>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <b>Cargar parte posterior de la cédula:</b>
          <span
            style={{
              fontSize: 12,
              marginLeft: 10,
              textDecoration: "underline",
            }}
          >
            En formato .png
          </span>
          {/*               <input type="file" id="cedula2" name="cedula2" accept="image/x-png" style={{ width: '100%' }} onChange={(e) => onchangeUploadImage(e, 'Cedula2')} />
           */}
          <br />{" "}
          <Upload {...propsUploadProductImageCedula2} accept='image/*'>
            <Button icon={<UploadOutlined />}>Cargar</Button>
          </Upload>
        </Col>
      </Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Button
          className={"generic-btn"}
          type="primary"
          onClick={() => goToRegisterUser()}
        >
          Registrar Tienda
        </Button>
      </Col>
    </div>
  );
};

export default StoreRegistration;
