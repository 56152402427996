import moment from 'moment';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { BillProducts, product } from '../../../Models/Products';
import { storeModel } from '../../../Models/store';
import { Bill, vw_Bills } from '../../../Models/Bill';
import { API } from '../../../API/axios';

export const useStoresSalesList = () => {
  const dateFormat = 'YYYY/MM/DD';
  const [filterDates, setFilterDates] = useState<any>([moment(new Date(), dateFormat), moment(new Date(), dateFormat)]);
  const [billList, setBillList] = useState<any>([])
  const [productsList, setProductsList] = useState<product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [guia, setGuia] = useState<string>("");
  const [storesName, setStoresName] = useState<any[]>([]);
  const [selectedStore, setSelectedStore] = useState<number>(0);
  const [stores, setStores] = useState<storeModel[]>([]);
  const [modalSearchGuiaVisible, setModalSearchGuiaVisible] = useState<boolean>(false);
  const [storeMovements, setStoreMovements] = useState<any>([]);

  useEffect(() => {
    if (localStorage.storeID !== "") {
      getProducts();
      getStoreData();
      getStores();
    }
  }, [])

  const getBills = (fecha: any) => {
    setLoading(true);
    let from = moment(fecha[0]).utcOffset('GMT-05').format(dateFormat);
    let to = moment(fecha[1]).utcOffset('GMT-05').format(dateFormat);
    API.get(`/Bills/GetBillsByDates?startDate=${from}&endDate=${to}`).then((res: any) => {
      res.data=res.data.filter((x: any) => x.countryId == parseInt(localStorage.country))
      let arrayBills = res.data.filter((x: vw_Bills) => moment(x.created_at).utcOffset('GMT-05').format(dateFormat) >= from &&
        moment(x.created_at).utcOffset('GMT-05').format(dateFormat) <= to).map((x: any, i: number) => {
          let el: any = {}
          el.storeId = x.storeId
          el.store = x.store
          el.guia = x.guia;
          el.created_at = moment(x.created_at).utcOffset('GMT-05').format('YYYY-MM-DD HH:mm');
          el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          el.status = x.status === 'Impreso-G' || x.status === 'Impreso-R' ? 'Despachado' : x.status;
          el.payment_home = x.payment_home ? 'Contraentrega' : 'Sin recaudo';
          el.delivery_value = `$ ${x.delivery_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          el.company_Transport = x.company_Transport
          el.city = x.city
          el.province = x.province

          if (x.status !== 'Anulado' && x.status !== 'Devuelto') {
            el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else {
            el.collect_value = `$ ${0}`;
          }

          /*let arrayProducts: string[] = x.bill_Products.map((prod: BillProducts) => {
            return ` ${prod.quantity} x ${productsList.filter((p: product) => p.id === prod.productId)[0].name}`;
          });*/
          el.productos = x.products;

          /*let valueProducts: number[] = x.bill_Products.map((prod: BillProducts) => {
            return (prod.sellingPrice ? prod.sellingPrice : 0) * prod.quantity;
          })*/
          el.value = `$ ${x.cost}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');//`$ ${valueProducts.map((item: number) => item).reduce((prev: number, curr: number) => prev + curr, 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

          return el;
        })
      setBillList(arrayBills);
      setLoading(false);
    })
  }

  const getStoreMovements = (storeId: number, date?: any) => {
    if (localStorage.storeID !== "") {
      setLoading(true);
      if ( storeId !== 0 ){
        API.get(`/Bills/${storeId}`).then(res => {
          let array: Bill[] = [];
          if (date) {
          res.data.map((x:any, i:any) => {
              if (moment(x.created_at) >= moment(date[0]) && moment(x.created_at) <= moment(date[1])) {
                let el: any = {}
                el.storeId = x.storeId
                el.guia = x.guia;
                el.created_at = moment(x.created_at).utcOffset('GMT-05').format('YYYY-MM-DD HH:mm');
                el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                el.status = x.status === 'Impreso-G' || x.status === 'Impreso-R' ? 'Despachado' : x.status;
                el.payment_home = x.payment_home ? 'Contraentrega' : 'Sin recaudo';
                el.delivery_value = `$ ${x.delivery_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                el.company_TransportId = x.company_TransportId
  
                if (x.status !== 'Anulado' && x.status !== 'Devuelto') {
                  el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else {
                  el.collect_value = `$ ${0}`;
                }
  
                let arrayProducts: string[] = x.bill_Products.map((prod: BillProducts) => {
                  return ` ${prod.quantity} x ${productsList.filter((p: product) => p.id === prod.productId)[0].name}`;
                });
                el.productos = arrayProducts.join();
  
                let valueProducts: number[] = x.bill_Products.map((prod: BillProducts) => {
                  return (prod.sellingPrice ? prod.sellingPrice : 0) * prod.quantity;
                })
                el.value = `$ ${valueProducts.map((item: number) => item).reduce((prev: number, curr: number) => prev + curr, 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
                array.push(el);
              }
              if (res.data.length === i + 1) {
                setStoreMovements(array);
                setLoading(false);
              }
            })
          } else {
            let array = res.data.map((x:any) => {
              let el: any = {}
              el.storeId = x.storeId
              el.guia = x.guia;
              el.created_at = moment(x.created_at).utcOffset('GMT-05').format('YYYY-MM-DD HH:mm');
              el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              el.status = x.status === 'Impreso-G' || x.status === 'Impreso-R' ? 'Despachado' : x.status;
              el.payment_home = x.payment_home ? 'Contraentrega' : 'Sin recaudo';
              el.delivery_value = `$ ${x.delivery_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              el.company_TransportId = x.company_TransportId
  
              if (x.status !== 'Anulado' && x.status !== 'Devuelto') {
                el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              } else {
                el.collect_value = `$ ${0}`;
              }
  
              let arrayProducts: string[] = x.bill_Products.map((prod: BillProducts) => {
                return ` ${prod.quantity} x ${productsList.filter((p: product) => p.id === prod.productId)[0].name}`;
              });
              el.productos = arrayProducts.join();
  
              let valueProducts: number[] = x.bill_Products.map((prod: BillProducts) => {
                return (prod.sellingPrice ? prod.sellingPrice : 0) * prod.quantity;
              })
              el.value = `$ ${valueProducts.map((item: number) => item).reduce((prev: number, curr: number) => prev + curr, 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
              return(el);
              
            })
            setStoreMovements(array);
            setLoading(false);
          }
        })
      }
    } else {
      return;
    }
  }

  const onChangeDates = (date: any) => {
    getStoreMovements(selectedStore, date);
  }

  const handleStores = (value: number) => {
    setSelectedStore(value);
    getStoreMovements(value);
  }

  const getProducts = () => {
    API.get(`/Products`).then(res => {
      setProductsList(res.data);
    })
  }

  const findGuia = (guia: string) => {
    setGuia(guia)
    setModalSearchGuiaVisible(true);
  }

  const getStoreData = () => {
    if (localStorage.storeID !== "") {
      API.get(`/Stores/GetStoreList`).then(res => {
        let arrayStoresName = res.data.map((x: storeModel) => {
          return {
            id: x.id,
            name: x.name
          }
        })
        setStoresName(arrayStoresName);
      })
    }
  }

  const getStores = async () => {
    if (localStorage.storeID !== "") {
      setLoadingStore(true);
      try {
        const res = await API.get(`/Stores/GetStoreList`);
        setStores(res.data);
      } catch (error) {
        message.error(`No se pudo cargar las tiendas. ${error}`);
      }
      setLoadingStore(false);
    }
  }

  function cellRender(data: any) {
    if (data.value === 1) {
      return 'Envia';
    }
    if (data.value === 2) {
      return 'Paloma';
    }
    if (data.value === 3) {
      return 'Interapidisimo';
    }
    if (data.value === 4) {
      return 'Crecel';
    }
  }

  return {
    filterDates,
    setFilterDates,
    loading,
    findGuia,
    guia,
    modalSearchGuiaVisible,
    setModalSearchGuiaVisible,
    getBills,
    billList,
    cellRender,
    storesName,
    selectedStore,
    handleStores,
    onChangeDates,
    storeMovements,
    stores,
    loadingStore,
  }
}
