import { Button, Col, Row } from 'antd';
import './Page.css';
import logo from '../../Assets/logo.png';
import tituloCrecel from '../../Assets/tituloCrecel.png';
import enColombia from '../../Assets/enColombia.png'
import botonInicioSesion from '../../Assets/botonInicioSeccion.png'
import botonRegistrarse from '../../Assets/botonRegistrarse.png'
import inicio from '../../Assets/inicio.png'
import productos from '../../Assets/productos.png'
import sobreNosotros from '../../Assets/sobreNosotros.png'
import entrenamiento from '../../Assets/entrenamiento.png'
import inicioSesion from '../../Assets/inicioSesion.png'
import inicioRegistrarse from '../../Assets/inicioRegistrarse.png'

const Page = () => {

    const gotoUrl = (url: string) => {
        window.location.href= url;
    }
    return (
        <div className="fondo">
            <Row className='header'>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                </Col>
                <Col xs={22} sm={22} md={2} lg={2} xl={2}>
                    <img src={logo} style={{ width: '100%', paddingTop: '20px' }} />
                </Col>
                <Col xs={1} sm={1} md={4} lg={4} xl={4}>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} xl={2} className='textHeader'>
                    <div>
                        <img src={inicio} style={{ width: '15px', marginBottom: '3px' }} /> INICIO
                    </div>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} xl={2} className='textHeader'>
                    <div>
                        <img src={productos} style={{ width: '10px', marginBottom: '3px' }} /> PRODUCTOS
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className='textHeader'>
                    <div>
                        <img src={sobreNosotros} style={{ width: '10px', marginBottom: '3px' }} /> SOBRE NOSOTROS
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className='textHeader'>
                    <div>
                        <img src={entrenamiento} style={{ width: '20px', marginBottom: '3px' }} />ENTRENAMIENTO
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className='textHeader'>
                    <div onClick={()=>gotoUrl('/login')}>
                        <img src={inicioSesion} style={{ width: '15px', marginBottom: '3px' }}/> INICIAR SESIÓN
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className='textHeader'>
                    <div onClick={() => gotoUrl('/createRegistration')}>
                        <img src={inicioRegistrarse} style={{ width: '15px', marginBottom: '3px' }} /> REGISTRARSE
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                </Col>
            </Row>
            <Row style={{ paddingTop: '100px' }}>
                <Col xs={2} sm={2} md={6} lg={6} xl={6}>
                </Col>
                <Col xs={20} sm={20} md={15} lg={15} xl={15} >
                    <img className='imgCrecel' src={tituloCrecel}></img>
                </Col>
                <Col xs={2} sm={2} md={3} lg={3} xl={3}>
                </Col>
            </Row><br />x
            <Row gutter={[16, 16]}>
                <Col xs={2} sm={2} md={9} lg={9} xl={9}>
                </Col>
                <Col xs={10} sm={10} md={3} lg={3} xl={3} >
                    <img src={botonInicioSesion} style={{ width: '100%' }} className='botonInicio' onClick={()=>gotoUrl('/login')}/>
                </Col>
                <Col xs={10} sm={10} md={3} lg={3} xl={3}>
                    <img src={botonRegistrarse} style={{ width: '92%' }} className='botonInicio' onClick={()=>gotoUrl('/createRegistration')}/>
                </Col>
                <Col xs={2} sm={2} md={9} lg={9} xl={9}>
                </Col>
            </Row>
            <Row style={{ paddingTop: '50px' }}>
            </Row>
            <Row className='textWhite'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                    <img className='textColombia' src={'https://drophacking.blob.core.windows.net/images/FRANQUICIA-GANADORA.png'} />
                </Col>
            </Row>
        </div>
    )
}

export default Page;

