import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, message, Row, Col } from 'antd';
import './ModalForgotPassword.css';
import { UserOutlined } from '@ant-design/icons';
import { IModalForgotPasswordProps } from './IModalForgotPasswordProps';
import { Auth } from '../../Models/User.Model';
import { API } from '../../API/axios';



const ModalForgotPassword = (props:IModalForgotPasswordProps) => {

    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [userData, setUserData]:any = useState<Auth>({User:"", Password:""})
    
    const askToken = () => {
        setLoading(true);
        let el:any = { 
            email: email
        }
        API.post(`/ForgotPassTempTokens`,el).then(res => {
        if (email == el.email){
            message.success('Le hemos enviado un link al correo');
            closeModal();
            setLoading(false);
            }   
        })
        .catch(err =>{
            message.error("Usuario invalido");
            setLoading(false);
            console.log(err);
        })
    }

    const closeModal = () => {
        setEmail("");
        setVisible(false);
    }

    return (
        <div>
            <span className={ 'linkPassword' } onClick={ () => setVisible(true) } >¿Has olvidado la contraseña?</span>
            
            <Modal
                open={visible}
                title={`¿Olvidaste La contraseña?`}
                onOk={()=>askToken()}
                onCancel={()=>closeModal()}
                footer={[
                    <Button key="back" onClick={()=>closeModal()}>
                    Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={ askToken }>
                    Enviar
                    </Button>,      
                ]}
                >
                    <div style={{padding:10}}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Input 
                            placeholder="Email"
                            prefix={<UserOutlined />}
                            onChange={ (e) => setEmail(e.target.value) }
                        />   
                    </Col>
                </Row>
          <Row>
            <Col span={16}></Col>
            <Col span={2}>
              <Button key="back" onClick={()=>closeModal()}>
                Cerrar
              </Button>
            </Col>
            <Col span={2}></Col>
            <Col span={2}>
            <Button key="submit" type="primary" loading={loading} onClick={ askToken }>
                    Enviar
                    </Button>
            </Col>
          </Row>
          </div>
            </Modal>    
        </div>
    )
}

export default ModalForgotPassword

