import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, message, Row, Select, Spin, Table } from 'antd';
import moment from 'moment';
import React, {useEffect,useState} from 'react';
import { API } from '../../../../API/axios';
import { Bill } from '../../../../Models/Bill';
import { company_transport } from '../../../../Models/company_Transport';
import { BillProducts, product } from '../../../../Models/Products';
import { user } from '../../../../Models/User.Model';
import { dispatch } from '../DispatchRelationship/EditDispatchRelationship';
import { IPrintDispatchRelationshipProps } from './IPrintDispatchRelationshipProps';
import PrintDispatchRelationShip from './ModalPrintDispatchRelationship';
const { Option } = Select;

export interface arrayRelationShips {
  id:number,
  date:string,
  company_transport:any
}

const PrintDispatchRelationship = (props:IPrintDispatchRelationshipProps) => {

  const [dispatchId, setDispatchId] = useState<number>(0);
  const [dispatchData, setDispatchData] = useState<dispatch>();
  const [dispatchBills, setdispatchBills] = useState<Bill[]>([]);
  const [productList, setProductList] = useState<Array<product>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [arrayDispatchesRelationShip1, setArrayDispatchesRelationShip1] = useState<arrayRelationShips[]>([]);
  const [arrayDispatchesRelationShip2, setArrayDispatchesRelationShip2] = useState<arrayRelationShips[]>([]);
  const [companyTransportList, setCompanyTransportList] = useState<company_transport[]>([]);
  const [couriersList, setCouriersList] = useState<user[]>([]);

  useEffect(() => {
    getCrecelCouriers();
    getProductList();
    GetCompanyTransport();
  }, []);

  const getCrecelCouriers = () => {
    API.get<user[]>(`/Users/GetCrecelCouriers`).then(res=>{
      setCouriersList(res.data);
      getDispatchHistory(res.data);
    })
  }

  const GetCompanyTransport = () => {
    API.get<company_transport[]>(`/CompanyTransports`).then(res => {
      setCompanyTransportList(res.data);
    });
  }

  const getProductList = () => {
    API.get<product[]>(`/Products`).then(res => {
      setProductList(res.data);
    })
  }

  const getDispatchHistory = (courrierList:user[]) => {
    let endDate = moment(new Date()).format('YYYY/MM/DD');
    let startDate = moment(new Date()).day(-15).format('YYYY/MM/DD');
    API.get(`Dispatchs/GetDispatchesByDates?startDate=${startDate}&endDate=${endDate}`).then(res=>{
      let array1 = res.data.map((x:any)=>{
        let el:arrayRelationShips = {
          id:x.id,
          date: moment(x.created_at).utcOffset('GMT-05').format('YYYY-MM-DD'),
          company_transport : x.bills.length > 0 ? x.bills[0].company_TransportId : 0
        }
        return el;
      });
      setArrayDispatchesRelationShip1(array1);
    });
    API.get(`CrecelDeliveries/GetDeliveriesByDates?startDate=${startDate}&endDate=${endDate}`).then(res=>{
      let array2 = res.data.map((x:any)=>{
        let mensajeros = courrierList.filter(u=>u.id === x.courierId);
        let mensajero = "";
        if(mensajeros.length > 0)
        {
          mensajero = mensajeros[0].full_name;
        }
        let el:arrayRelationShips = {
          id:x.id,
          date: moment(x.created_at).utcOffset('GMT-05').format('YYYY-MM-DD'),
          company_transport : mensajero
        }
        return el;
      });
      setArrayDispatchesRelationShip2(array2);
    });
  }


  const getDispatchByById = (type:string,dispatchId:number) => {
    if(dispatchId)
    {
      API.get(`/${type}/${dispatchId}`).then(res=>{
        let response = res.data.bills.map((x: any,i:any) => {
          x.key = x.id;
          x.numero = i+1;
          return x
        })
        setDispatchData(res.data);
        setdispatchBills(response); 
      })
      .catch(err=>{
        console.log(err);
        message.error('Se presentó un error, intentelo nuevamente');
      })
    }
    else{
      setdispatchBills([]); 
    }
  }

  const columns = [
    {
      title: 'Guia',
      dataIndex: 'guia',
      key: 'guia'
    },
    {
      title: 'Teléfono',
      dataIndex: 'guia',
      key: 'guia',
      render: (f: any, x: Bill) => {
        return `${x.storeClient?.cellphone} / ${x.storeClient?.phone}`
      }
    },
    {
      title: 'Contenido',
      dataIndex: 'bill_Products',
      key: "bill_Products",
      render: (f: any, x: Bill) => {
        let arrayProds = x.bill_Products.map((prod: BillProducts) => {
          let productFiltered = productList.filter((p: product) => prod.productId === p.id);
          return productFiltered.length > 0 ? `${prod.quantity} ${productFiltered[0].name}` : ""
        });
        return arrayProds.join();
      }
    },
    {
      title: 'Recaudo',
      dataIndex: 'bill_Products',
      key: "bill_Products",
      render: (f: any, x: Bill) => {
        let arrayProds = x.bill_Products.map((prod: BillProducts) => {
          let productFiltered = productList.filter((p: product) => prod.productId === p.id);
          return productFiltered.length > 0 ? productFiltered[0].selling_price : ""
        });
        let total = arrayProds.length > 0 ? arrayProds.reduce((a: any, b: any) => { return a + b }) : 0;
        return `$ ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  ];

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (e: any) => onSelectChange(e),
  };
  
  return (
    <div>
      <Row gutter={[16,16]}>
        <Col span={6}>
          Nº Relación de despachos:<br />
          <Select
            allowClear
            style={{ width: '100%',marginBottom:20 }}
            placeholder={"Mensajero"}
            onChange={(e:any)=>getDispatchByById('Dispatchs',e)}
          >
            {arrayDispatchesRelationShip1.map(x=>
                <Option key={x.id} value={x.id}>
                  {`${companyTransportList.filter(com=>com.id ===x.company_transport).length > 0 ? 
                  companyTransportList.filter(com=>com.id ===x.company_transport)[0].name: ""} 
                  - ${x.date}`}
                </Option>
            )}
          </Select>

          Relacion de despachos Crecel:
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder={"Mensajero"}
            onChange={(e:any)=>getDispatchByById('CrecelDeliveries',e)}
          >
            {arrayDispatchesRelationShip2.map(x=>
                <Option key={x.id} value={x.id}>
                  {x.company_transport} - {x.date}
                </Option>
            )}
          </Select>
        </Col>
        <Col span={2}>
          <br/> 
{/*           <Button onClick={getDispatchByById} type='primary' icon={<SearchOutlined/>}>Buscar</Button>
 */}        </Col>
        <Col span={12}></Col>
        <Col span={2}>
          <Button onClick={()=>setModalVisible(true)}>Imprimir relación</Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Spin tip="Cargando datos..." spinning={loading}>
            <Table columns={columns} dataSource={dispatchBills} />
          </Spin>
        </Col>
      </Row>
      {dispatchBills.length > 0 &&
      <PrintDispatchRelationShip 
        ModalVisible={modalVisible} 
        setModalVisible={setModalVisible}
        dispatchBills={dispatchBills}
        dispatchData={dispatchData}
        productList={productList}
        />
      }
    </div>
  );
};

export default PrintDispatchRelationship;
