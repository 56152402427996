import { Button, notification } from "antd";
import { useState } from "react";
import { API } from "../../API/axios";

export interface IUseRegistration {
  name: string;
  email: string;
}

export const useRegistration = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userRegistrationData, setUserRegistrationData] =
    useState<IUseRegistration>({
      name: "",
      email: "",
    });

  const handleChangeUserRegistration = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setUserRegistrationData({ ...userRegistrationData, [name]: value });
  };

  const key = `open${Date.now()}`;
  const btn = (
    <Button
      className="generic-btn"
      onClick={() => (window.location.href = "/")}
    >
      Confirm
    </Button>
  );

  const registerUser = async () => {
    setLoading(true);
    var resp: any = await API.post(`/TempTokens`,userRegistrationData).catch((err) => {
      setLoading(false);
    });
    if (resp.status === 200) {
      notification.success({
        message: "Datos guardados",
        description:
          "Los datos fueron registrados correctamente. Una vez que sean confirmados, se enviará un correo electrónico.",
        btn,
        key,
        duration: 10,
      });
    }
    setLoading(false);
  };

  return {
    userRegistrationData,
    handleChangeUserRegistration,
    loading,
    registerUser,
  };
};
