import { Col, Input, InputNumber, Modal, Row } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { IProductDetailProps } from "./IProductDetailProps";
import { HeartFilled } from "@ant-design/icons";
import "./ProductDetail.css";
import { usePowerSellers } from "../../../Hooks/Store/PowerSellers";

const ProductDetail = (props: IProductDetailProps) => {
  const { getProductById, productDetail,addProductToStore,storeSellingPrice, setStoreSellingPrice } = usePowerSellers();

  useEffect(() => {
    getProductById(props.productId);
  }, [props.productId]);

  return (
    <Modal
      open={props.isModalVisible}
      onCancel={() => {
        props.setIsModalVisible(false);
        setStoreSellingPrice(0);
      }}
      width={"60%"}
      footer={[]}
    >
      {productDetail && (
        <Row>
          <Col span={12} className="product-image-container">
            <img src={productDetail?.image} alt="" />
          </Col>
          <Col span={12}>
            <div className="product-description-container">
              <div className="product-detail-name color-texto-gris">
                {productDetail?.name}
              </div>
              <div className="orange-line"></div>
              {/* <span className="likes">
                <HeartFilled /> {productDetail?.total_Likes}
              </span>{" "} */}
              <div className="product-detail-description color-texto-gris">
                {productDetail?.description}
              </div>
              <span className="product-detail-min-price color-texto-gris">
                Precio mínimo de venta
              </span>
              <div className="product-detail-price">
                {`$ ${localStorage.currency == 'COP' ? productDetail?.suggested_selling_price : productDetail.usD_Suggested_selling_price}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}
              </div>
              <span className="product-detail-description color-texto-gris">
                Obten una ganancia por venta de hasta
              </span>
              <span className="product-detail-orange-price">
                {localStorage.currency == 'COP' ? 
                <>
                {`$ ${
                  (productDetail.suggested_selling_price - productDetail.selling_price)
                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </>
                :
                <>
                {`$ ${
                  productDetail.usD_Suggested_selling_price - productDetail.usD_Selling_price
                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </>
                }
              </span>
            </div>

            <div className="add-product-container">
              <div className="color-texto-gris">
                ¿Deseas añadir este producto?
              </div>
              <Row>
                <Col span={1}></Col>
                <Col span={11}>
                <InputNumber
                  value={storeSellingPrice}
                  min={localStorage.currency == 'COP' ? productDetail.suggested_selling_price : productDetail.usD_Suggested_selling_price}
                  style={{ width: '90%' }}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(e:any) => setStoreSellingPrice(e)}
                />
                </Col>
                <Col span={1}></Col>
                <Col span={10} onClick={()=>addProductToStore(props.productId)}>
                  <img
                    src="https://drophacking.blob.core.windows.net/images/PowerSellers/BOTON-DE-AÑADIR-A-BODEGA.png"
                    alt="añadir a bodega"
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default ProductDetail;
