import React, { useContext, useEffect } from "react";

// CSS
import "./CopysLibrary.css";

// Assets

// ant
import { Col, Row, Button, Spin, Input } from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";

// Custom Hooks
import useCopysLibrary from "./Hooks/useCopysLibrary";
import ProductModalLibrary from "./ProductModalLibrary";
import useProductModalLibrary from "./Hooks/useProductModalLibrary";
import { PageContext } from "../../Hooks/ContextProvider";

const { Search } = Input;

const CopysLibrary = () => {
  const { userData } = useContext(PageContext);

  const { imagesCopy, loading, getCopyProducts, onSearchFilteredProduct } =
    useCopysLibrary();

  const { type, productId, modalVisible, setModalVisible, openModal } =
    useProductModalLibrary();

  const defaultImage =
    "https://drophacking.blob.core.windows.net/public-resources/Assets/Icons/sin%20imagen.jpg";

  return (
    <div className="contenedor">
      <Row>
        <Col span={2}></Col>
        <Col span={20}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="copy-title">DROP HOOKS</div>
            </Col>
            {(userData?.roles.includes("Admin") ||
              userData?.roles.includes("SuperAdmin")) && (
                <Col span={4}>
                  <div className="btn-creating">
                    <Button type="primary" onClick={() => openModal("Creating")}>
                      <PlusCircleOutlined /> Agregar Producto
                    </Button>
                  </div>
                </Col>
              )}
            {modalVisible && (
              <ProductModalLibrary
                type={type}
                productId={productId}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                getCopyProducts={getCopyProducts}
              />
            )}

            <Row>
              <Col span={24}>
                <div className="search">
                  <Search
                    placeholder="Buscar por nombre de producto"
                    allowClear
                    onSearch={onSearchFilteredProduct}
                    onChange={(e) => onSearchFilteredProduct(e.target.value)}
                    style={{ width: 500, margin: "0 10px" }}
                  />
                </div>
              </Col>
            </Row>

            <Col span={24}>
              <Spin spinning={loading}>
                <div className="card-container">
                  {imagesCopy.map((image: any) => (
                    <div key={image.id} className="card-folder">
                      <a href={image.folderInformation} target="_blank">
                        {image.testimonials !== "" ? (
                          <img src={image.testimonials} alt={image.name} />
                        ) : (
                          <div>
                            <div className="card-image-null">
                              <img src={defaultImage} alt={image.name} />
                            </div>
                            <div className="product-name">{image.name}</div>
                          </div>
                        )}
                      </a>
                      {(userData?.roles.includes("Admin") ||
                        userData?.roles.includes("SuperAdmin")) && (
                          <div className="contein-btn">
                            <div className="btn-editing">
                              <Button
                                type="primary"
                                onClick={() => openModal("Editing", image.id)}
                              >
                                {" "}
                                <EditOutlined /> Editar{" "}
                              </Button>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              </Spin>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CopysLibrary;
