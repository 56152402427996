import { Button, Col, Row, Select, Spin, Table } from "antd";
import { useEffect } from "react";
import useWarehouse from "../../../Hooks/useWarehouse";
import { PrinterOutlined } from '@ant-design/icons';
import ModalDetalleGuia from "../../ModalDetalleGuia/ModalDetalleGuia";
import PrintLabels from "../Labels/PrintLabels/PrintLabels";
const { Option } = Select;

export const PrintGuias = () => {
  const {
    getCompanyTransports,
    getProductList,
    getCities,
    selectedCompanyTransport,
    setSelectedCompanyTransport,
    selectedRowKeys,
    loading,
    rowSelection,
    columnsLabels,
    dataLabels,
    companyTransportList,
    labelsList,
    ModalVisible,
    setModalVisible,
    billsArrayList,
    printMode,
    getPendingBillsByStatus,
    printLabels,
    ModalDetailBillVisible,
    setModalDetailBillVisible,
    selectedGuia,
    getPendingBillsGuias
  } = useWarehouse();

  useEffect(() => {
    getCities();
    getProductList();
    getPendingBillsGuias();
    getCompanyTransports();
    getPendingBillsByStatus('Impreso-R');
  }, []);

  return (
    <div>
      <h1 style={{ marginTop: "30px", textAlign: "center" }}>Imprimir guias</h1>
      <Row>
        <Col span={2}></Col>
        <Col span={20}>
          Seleccione una transportadora: <Select value={selectedCompanyTransport} style={{ width: 300, marginBottom: 10 }} onChange={(e) => setSelectedCompanyTransport(e)}>
            <Option value={-1}>Todos</Option>
            {companyTransportList.map(x => <Option value={x.id}>{x.name}</Option>)}
          </Select>
        </Col>
        <Col span={2}>
          {selectedRowKeys.length > 0 &&
            <Button
              style={{ marginRight: 10 }}
              icon={<PrinterOutlined />}
              onClick={() => printLabels('Guias')}
            >Imprimir Guias</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col span={22}>
          <Spin spinning={loading} tip="Cargando...">
            <Table
              rowSelection={rowSelection}
              columns={columnsLabels}
              dataSource={selectedCompanyTransport === -1 ? dataLabels : dataLabels.filter(x => x.company_TransportId === selectedCompanyTransport)}
            />
          </Spin>
        </Col>
      </Row>
      {labelsList.length > 0 &&
        <PrintLabels
          ModalVisible={ModalVisible}
          setModalVisible={setModalVisible}
          labelsData={labelsList}
          billsArrayList={billsArrayList}
          getPendingBillsByStatus={getPendingBillsByStatus}
          printMode={printMode}
          selctedCompanyStransport={companyTransportList.filter(x => x.id === selectedCompanyTransport)[0].name}
        />
      }
      {ModalDetailBillVisible &&
        <ModalDetalleGuia visible={ModalDetailBillVisible} setVisible={setModalDetailBillVisible} guia={selectedGuia} />
      }
    </div>
  );
}
