import React, { useContext, useEffect } from "react";
import { ISideBarProps } from "./ISideBarProps";
import "./SideBar.css";
import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useState } from "react";
import { PageContext } from "../../Hooks/ContextProvider";

export type MenuOptions = {
  store: boolean;
  admin: boolean;
  warehouse: boolean;
  superAdmin: boolean;
  Productor: boolean;
};

const SideBar = (props: ISideBarProps) => {
  const [colapse, setColapse] = useState(true);
  const [MenuOptions, setMenuOptions] = useState({
    store: false,
    admin: false,
    warehouse: false,
    superAdmin: false,
    Productor: false,
  });
  const { setSelectedComponent, userData } = useContext(PageContext);

  useEffect(() => {
    validateMenu();
  }, [userData]);

  const validateMenu = () => {
    let menuOptions: MenuOptions = {
      store: false,
      admin: false,
      warehouse: false,
      superAdmin: false,
      Productor: false,
    };
    if (userData?.roles.includes("Tienda")) {
      menuOptions.store = true;
    }
    if (userData?.roles.includes("Admin")) {
      menuOptions.admin = true;
    }
    if (userData?.roles.includes("Bodega")) {
      menuOptions.warehouse = true;
    }
    if (userData?.roles.includes("SuperAdmin")) {
      menuOptions.superAdmin = true;
    }
    if (userData?.roles.includes("Productor") || userData?.roles.includes("Productor/Tienda")) {
      menuOptions.Productor = true;
    }
    setMenuOptions(menuOptions);
  };

  return (
    <div>
      <ProSidebar
        onMouseEnter={() => setColapse(false)}
        onMouseLeave={() => setColapse(true)}
        collapsed={colapse}
      >
        <Menu iconShape="square" className="sideBar">
          {MenuOptions.Productor && (
            // Opciones de menu para Productor
            <MenuItem
              onClick={() => setSelectedComponent("WarehouseInventory")}
              icon={
                <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
              }
            >
              Inventario
            </MenuItem>
          )}
          {(MenuOptions.store || MenuOptions.Productor) && (
            // Opciones de menu para tienda
            <>
              <MenuItem
                onClick={() => setSelectedComponent("Panel")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-PANEL.png" alt="icono menú" />
                }
              >
                Panel
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("PowerSellers")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-MASTER-TOOLS.png" alt="icono menú" />
                }
              >
                Power Sellers
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("DroppHooks")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DROP-HOOKS.png" alt="icono menú" />
                }
              >
                Drop Hooks
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("StoreWallet")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-MI-MONEDERO.png" alt="icono menú" />
                }
              >
                Monedero
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("Billing")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-FACTURAR.png" alt="icono menú" />
                }
              >
                Facturar
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("Sales")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/MIS-VENTAS.png" alt="icono menú" />
                }
              >
                Mis Ventas
              </MenuItem>
            </>
          )}

          {MenuOptions.admin && (
            // Opciones de menu para administrador
            <>
              <MenuItem
                onClick={() => setSelectedComponent("Storetoken")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Tiendas
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("GenerateRegistertoken")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Generar token
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("Transfers")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Traslados
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("WarehouseInventory")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Inventario
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("CopysLibrary")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Drop Hooks
              </MenuItem>

              <MenuItem
                onClick={() => setSelectedComponent("StoresSalesList")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Ventas Tiendas
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("Customers")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Clientes no Gratos
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("Settings")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Configuración
              </MenuItem>
            </>
          )}

          {MenuOptions.warehouse && (
            // Opciones de menu para Superadministrador
            <>
              <MenuItem
                onClick={() => setSelectedComponent("Labels")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Imprimir rotulos
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("PrintGuias")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Imprimir Guias
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("DispatchList")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Relación de despachos
              </MenuItem>
            </>
          )}

          {MenuOptions.superAdmin && (
            // Opciones de menu para Superadministrador
            <>
              <MenuItem
                onClick={() => setSelectedComponent("Settlement")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Liquidar Guias
              </MenuItem>
              <MenuItem
                onClick={() => setSelectedComponent("PendingRecharge")}
                icon={
                  <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-SOPORTE-911.png" alt="icono menú" />
                }
              >
                Recargas
              </MenuItem>
            </>
          )}
        </Menu>
      </ProSidebar>
    </div>
  );
};

export default SideBar;
