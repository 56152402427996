import { Button, Col, Row } from 'antd';
import './FormContact.css';
import nombre from '../../Assets/nombre.png'
import correo from '../../Assets/correo.png'
import celular from '../../Assets/celular.png'
import mensaje from '../../Assets/mensaje.png'



const FormContact = () => {
    return (

        <div className='fondoContact'>

            <Row  >
                <Col xs={1} sm={1} md={8} lg={8} xl={8} ></Col>
                <Col xs={22} sm={22} md={8} lg={8} xl={8} className='titleForm' >
                    <div>
                        COMUNICATE <br />
                        <b>CON NOSOTROS</b>
                    </div><br />
                    <div className='textForm' >
                        <img src={nombre} style={{ width: '25px' }} />
                        Nombre</div>
                    <div className='textForm' >
                        <img src={correo} style={{ width: '25px' }} /> Correo electónico</div>
                    <div className='textForm'>
                        <img src={celular} style={{ width: '25px' }} /> Celular</div>
                    <div className='textForm' style={{ height: '120px' }}>
                        <img src={mensaje} style={{ width: '25px' }} /> Mensaje</div>
                </Col>
                <Col xs={1} sm={1} md={8} lg={8} xl={8} ></Col>
            </Row><br />
            <Row>
            <Col xs={1} sm={8} md={8} lg={8} xl={8} ></Col>
            <Col xs={1} sm={8} md={8} lg={8} xl={8} style={{textAlign:'center'}} ><Button className='buttonSend'>Enviar</Button></Col>
            <Col xs={1} sm={8} md={8} lg={8} xl={8} ></Col>
            </Row>
        </div>
    )
}

export default FormContact;

