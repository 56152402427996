import { IWarehouseAdministrationProps } from './IWarehouseAdministrationProps';

// Ant
import { Button, Col, Form, Input, InputNumber, Row, Select, Spin } from 'antd';

// DevExtreme
import { DataGrid } from 'devextreme-react';
import { Column, Editing, Export, FilterRow, Grouping, GroupPanel, Lookup, Pager, Paging, SearchPanel } from 'devextreme-react/data-grid';

// Hooks
import { product } from '../../Models/Products';
import { WarehouserData } from '../../Models/warehouse.model';
import { useWarehouseAdministration } from './Hooks/useWarehouseAdministration';

// Models




const WarehouseAdministration = (props:IWarehouseAdministrationProps) => {

  const { Option } = Select;
  const pageSizes = [10, 25, 50, 100];

  const {
    loading,
    formRef,
    productsFiltered,
    onSearchFilteredProduct,
    handleSelectWarehouse,
    onSearchFilteredWarehouse,
    onFinish,
    inventoryMovements,
    products,
    warehouseByUserId
  } = useWarehouseAdministration();

  return (
    <Row gutter={[16, 16]}>
      <Col span={2}></Col>
      <Col span={22} className="cardContainer" style={{ padding: 50 }}>
        <div className="titulo">Traslados</div>

        <Form
          name="basic"
          layout="vertical"
          ref={formRef}
          initialValues={{}}
          onFinish={onFinish}
        >
          <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Bodega"
                  name="warehouseId"
                  rules={[{ required: true, message: 'Seleccione un rol.' }]}
                >
                  <Select
                    placeholder="Seleccionar..."
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={onSearchFilteredWarehouse}
                    notFoundContent={null}
                    onChange={handleSelectWarehouse}
                  >
                    {warehouseByUserId.map((x: WarehouserData) => <Option key={x.id} value={x.id}> {x.warehouseName} </Option>)}
                  </Select>
                </Form.Item>
              </Col>
            {/* } */}
            <Col span={12}>
              <Form.Item
                label="Tipo movimiento"
                name="movement_Type"
              >
                <Select
                  defaultValue="Entrada"
                  disabled
                >
                  <Option key="Entrada" value="Entrada">Entrada</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Producto"
                name="productId"
                rules={[{ required: true, message: 'Este campo es requerido.' }]}
              >
                <Select
                  placeholder="Seleccionar..."
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={onSearchFilteredProduct}
                  notFoundContent={null}
                >
                  {productsFiltered.map((x: product) => <Option key={x.id} value={x.id}> {x.name} </Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Observaciones"
                name="observations"
              >
                <Input.TextArea style={{ maxHeight: 200 }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Cantidad"
                name="quantity"
                rules={[{ required: true, message: 'Este campo es requerido.' }]}
              >
                <InputNumber
                  defaultValue={0}
                  min={1}
                />
              </Form.Item>
            </Col> 
            <Col span={8}>
              <Form.Item >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: '30px', width: "200px" }}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {
          inventoryMovements.length !== 0 &&
          
          <Spin tip="Cargando..." spinning={loading}>
            <div className="titulo">Inventario de movimiento</div>
            <DataGrid
              dataSource={ inventoryMovements }
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              showBorders={true}
            >
              <Export enabled={true} allowExportSelectedData={false} />
              <GroupPanel visible={true} />
              <SearchPanel visible={true} highlightCaseSensitive={true} />
              <Grouping autoExpandAll={true} />
              <FilterRow visible={true} />

              <Column dataField="productId" caption="Producto" dataType="number" >
                <Lookup dataSource={ products } valueExpr="id" displayExpr="name" />
              </Column>
              <Column dataField="quantity" caption="Cantidad" dataType="string" />
              <Column dataField="created_at" caption="Fecha" dataType="string" />
              <Column dataField="observations" caption="Observaciones" dataType="string" />

              <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
              <Paging defaultPageSize={20} />
              <Editing
                mode="form"
                allowUpdating={true}
                allowAdding={true}
                useIcons={true}
              />
            </DataGrid>
          </Spin>
        }

      </Col>
    </Row>
  );
};

export default WarehouseAdministration;
