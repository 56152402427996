import { Button, Card, Col, Row, Statistic } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useModalStoreBank } from './Hooks/useModalStoreBank';
import { IModalStoresBankProps } from './IModalStoresBankProps';
import './ModalStoresBank.css';


const ModalStoresBank = (props:IModalStoresBankProps) => {
  const { bankData, storeBank } = useModalStoreBank(props.storeId);
  const closeModal = () => {
    props.setVisible(false);
  }

  return (
    <div className={'modalStoresBank'}>
      <Modal
        open={props.visible}
        title={`Datos Bancarios`}
        width={500}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={[
          <Button key="back" onClick={() => closeModal()}>
            Cerrar
          </Button>
        ]}
      >
        <div style={{padding:10}}>
        <Row>
          <Col span={12}>
            <b>Titular de la cuenta</b><br />
            {bankData?.owner_account}
          </Col>
          <Col span={12}>
            <b>Banco</b><br />
            {storeBank}
          </Col>
        </Row>
        <Row>
          <Col span={12}> 
            <b>Tipo de cuenta</b><br />
            {bankData?.account_type}
          </Col>
          <Col span={12}>
            <b>Numero de cuenta</b><br />
            {bankData?.bank_account}
          </Col>
        </Row> 
        <Row>
          <Col span={12}>
            <b>Documento identidad titular</b><br />
            {bankData?.owner_identification}
          </Col>
        </Row>
        <Row>
          <Col span={12}> 
            <b>Cuenta Global 66</b><br />
            {bankData?.global66}
          </Col>
          <Col span={12}>
            <b>Cuenta PayPal</b><br />
            {bankData?.payPal}
          </Col>
        </Row> 
        </div>
      </Modal>
    </div>
  );
};

export default ModalStoresBank;
