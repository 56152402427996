import * as React from 'react';
import { ICitiesProps } from './ICitiesProps';
import './Cities.css';
import { Button, Checkbox, Col, Input, Row, Select, Spin } from 'antd';
import DataGrid,
{
  Column,
  Export,
  Grouping,
  GroupPanel,
  Pager,
  Sorting,
  HeaderFilter,
  ColumnFixing,
  FilterRow,
  Editing,
}
  from 'devextreme-react/data-grid';
import { useCities } from '../../Hooks/useCities';

const { Option } = Select;
const Cities = (props:ICitiesProps) => {

  const {
    cities,
    city,
    provinces,
    loading,
    cellRender,
    getCities,
    Savecity,
    updateCity
  } = useCities();

  return (
    <div>
      <h3>Gestión de Ciudades:</h3>
      <Select
        showSearch
        placeholder="Seleccione un departamento" 
        optionFilterProp="children"
        style={{ width: 400 }}
        onChange={getCities}
        filterOption={(input, option) =>
          (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      >
        {provinces.map((x) =>  <Option value={x.code}>{x.name}</Option>)}
      </Select>
      <br />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Spin tip="Cargando..." spinning={loading}>
            <DataGrid
              id="payment_methods"
              keyExpr={'id'}
              dataSource={cities}
              showBorders={true}
              remoteOperations={true}
              allowColumnResizing={true}
              columnResizingMode={"widget"}
              rowAlternationEnabled={true}
              showRowLines={true}
              focusedRowEnabled={true}
              onRowUpdated={(row)=>Savecity(row.data)}   
              onRowInserted={(row)=>Savecity(row.data)}
            >
              <Export enabled={true} />
              <HeaderFilter visible={true} />
              <Sorting mode="multiple" />
              <GroupPanel visible={true} />
              <ColumnFixing enabled={true} />
              <FilterRow visible={true} />
              <Grouping contextMenuEnabled={true} expandMode="rowClick" />
              <Grouping autoExpandAll={false} />
              <Editing
                mode="bash"
                allowUpdating={true}
                allowAdding={true}
                allowDeleting={false}
              />
              <Pager
                showInfo={true}
                showNavigationButtons={true}
                showPageSizeSelector={true}
                visible={true}
              />
              <Column dataField="id" caption="Id" width={50} allowEditing={false}></Column>
              <Column dataField="name" caption="Nombre" width={300} ></Column>
              <Column dataField="code" caption="Código" ></Column>
              <Column dataField="isActived" caption="Activo" ></Column>
{/*               <Column cellRender={cellRender} caption="Deshabilitar" alignment='center' allowEditing={false} /> 
 */}            </DataGrid>
          </Spin>
        </Col>
        {/* <Col span={12}>
        <Col span={8}>
          <h3>{city.name}</h3>
        </Col>
        <Col span={4}>
            <br />
            Activo:&nbsp;
            <Checkbox name='isActived' checked={city.isActived} onChange={updateCity} />
          </Col>
         <Row gutter={[16, 16]}>
          <Col span={18}></Col>
          <Col span={6}>
            <Button onClick={()=>Savecity(city)} loading={loading} type='primary' icon={<SaveOutlined/>}>Guardar</Button>
          </Col>
        </Row>
           <Row gutter={[16, 16]}>
            <Col span={16}></Col>
            <Col span={8}>
              <Button onClick={cleanModel} loading={loading} type='primary' icon={<PlusCircleOutlined />}>Crear bodega</Button>
            </Col>
          </Row>
          <br />
           <Row gutter={[16, 16]}>
          <Col span={12}>
            Nombre:
            <Input name='name' value={Warehouse.name} onChange={updateWarehouse} placeholder="Nombre" />
          </Col>
          <Col span={8}>
            Código:
            <Input name='name' value={Warehouse.code} onChange={updateWarehouse} placeholder="Nombre" />
          </Col>
          <Col span={4}>
            <br />
            Activo:&nbsp;
            <Checkbox name='active' checked={Warehouse.active} onChange={updateWarehouse} />
          </Col>
        </Row>
        <br />
        <Row gutter={[16, 16]}>
          <Col span={18}></Col>
          <Col span={6}>
            <Button onClick={SaveWarehouse} loading={loading} type='primary' icon={<SaveOutlined/>}>Guardar</Button>
          </Col>
        </Row>
        </Col> */}
      </Row>
    </div>
  );
};

export default Cities;
