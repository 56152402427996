import { ICountrySelectionProps } from './ICountrySelectionProps';
import './CountrySelection.css';
import Header from '../Header/Header';
import { Col, Row } from 'antd';
import { useContext } from 'react';
import { PageContext } from '../../Hooks/ContextProvider';

const CountrySelection = (props: ICountrySelectionProps) => {

  const { userData } = useContext(PageContext);
  console.log('userData');
  console.log(userData);


  const goToUrl = (country: string) => {
    if (country == '2') {
      window.location.href = 'https://app.drophackingcrecel.com/auth/login'
    }
    else {
      localStorage.setItem('country', country);
      localStorage.setItem('currency', country == '1' ? 'COP' : 'USD');
      window.location.href = '/storeHome';
    }
  }

  return (
    <div className='country-name'>
      <Header />
      <div className='text-center mtop-100'>
        <div className='sub-title-white weight-500'>Bienvenido</div>
        <div className='bold-title-white'>
          {userData?.userName}
        </div>
        <div className='sub-title-white'>¿A que tienda deseas ingresar?</div>
      </div>
      <Row>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
        <Col xs={22} sm={22} md={22} lg={6} xl={6}>
          <img src="https://drophacking.blob.core.windows.net/images/CortinaTiendas/TIENDA-COLOMBIA.png" className='flag-container' onClick={() => goToUrl('1')} />
          <div className='dropshiping-text text-center'>DROPSHIPPING</div>
          <div className='country-text text-center'>COLOMBIA</div>
        </Col>
        <Col xs={2} sm={2} md={2} lg={1} xl={1}></Col>
        <Col xs={22} sm={22} md={22} lg={6} xl={6}>
          <img src="https://drophacking.blob.core.windows.net/images/CortinaTiendas/TIENDA-MEXICO.png" className='flag-container' onClick={() => goToUrl('2')} />
          <div className='dropshiping-text text-center'>DROPSHIPPING</div>
          <div className='country-text text-center'>MEXICO</div>
        </Col>
        <Col xs={2} sm={2} md={2} lg={1} xl={1}></Col>
        <Col xs={22} sm={22} md={22} lg={6} xl={6}>
          <img src="https://drophacking.blob.core.windows.net/images/CortinaTiendas/ECUADOR.png" className='flag-container' onClick={() => goToUrl('3')} />
          <div className='dropshiping-text text-center'>DROPSHIPPING</div>
          <div className='country-text text-center'>ECUADOR</div>
        </Col>
      </Row>
    </div>
  );
};

export default CountrySelection;
