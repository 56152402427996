import { Button, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import FormItem from "antd/lib/form/FormItem";
import DataGrid, {
  Column,
  ColumnFixing,
  Export,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Sorting,
} from "devextreme-react/data-grid";
import { useState } from "react";
import { WarehouserData } from "../../Models/warehouse.model";
import "./WarehouseInventory.css";
const { Option } = Select;

const WarehouseInventory = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [transferVisible, setTransferVisible] = useState(false);
  const [productSelected, setProductSelected] = useState<any>(null);
  const [productWarehouseSelected, setProductWarehouseSelected] = useState<any>(null);
  const [productTransferSelected, setTransferProductSelected] = useState<any>(null);
  const [movementSelected, setMovementSelected] = useState<string>('');
  const [form] = Form.useForm();
  const [formTransfer] = Form.useForm();


  const showModal = () => {
    setVisible(true)
  }

  const showTransferModal = () => {
    setTransferVisible(true)
  }

  const handleCancel = () => {
    setVisible(false);
  };

  const handleTransferCancel = () => {
    setTransferVisible(false);
    formTransfer.resetFields();
  };

  const handleFormSubmit = () => {
    form.validateFields().then((values) => {

      const formData = {
        productId: productSelected,
        warehouseId: productWarehouseSelected,
        quantity: values.total,
        movement_Type: values.movement,
        created_at: new Date(),
      };

      props.insertInventoryMovement(formData);

      setVisible(false)
      setProductSelected(null)
      setMovementSelected('');

      form.resetFields();
    });
  };

  const handleProductChange = (value: any) => {
    setProductSelected(value);
  };

  function handleProductWarehouse(value: any): void {
    setProductWarehouseSelected(value)
  }

  function handleTransferProduct(value: any): void {
    setTransferProductSelected(value)
  }

  function handleMovement(value: string): void {
    setMovementSelected(value)
  }

  function handleTransferFormSubmit(): void {
    formTransfer.validateFields().then((values) => {

      const formData = {
        productId: productTransferSelected,
        initialWarehouseId: values.warehouse,
        finalWarehouseId: values.finalWarehouse,
        quantity: values.total
      };

      props.insertTransferMovement(formData);
      setTransferVisible(false)
      setTransferProductSelected(null)
      formTransfer.resetFields();
    });
  }

  return (
    <div className={`warehouseInventory`}>
      <Row>
        <Col>
          <Spin tip="Cargando datos..." spinning={props.loading}>
            <Row>
              <div className="warehouse-inventory__header">
                <Col span={5}>
                  <div className="warehouse-inventory__select">
                    <span>Bodega:</span>
                    <Select
                      style={{ width: "100%" }}
                      placeholder={"Selecciona una bodega"}
                      onChange={props.onChangeWarehouse}
                      value={props.selectedWarehouse}
                    >
                      <Option value={0}>Todas las bodegas</Option>
                      {props.warehouseList.map((x: WarehouserData) => (
                        <Option key={x.id} value={x.id}>
                          {x.warehouseName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <div className="warehouse-inventory__actions">
                  <Button onClick={showTransferModal}>Traslados entre bodegas</Button>
                  <Button onClick={showModal}>Traslados</Button>
                </div>
              </div>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ padding: 10, backgroundColor: "white" }}>
                <DataGrid
                  id="Inventory"
                  dataSource={props.inventoryList}
                  showBorders={true}
                  remoteOperations={true}
                  allowColumnResizing={true}
                  columnResizingMode={"widget"}
                  showRowLines={true}
                >
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <GroupPanel visible={false} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <Pager
                    showInfo={true}
                    showNavigationButtons={true}
                    showPageSizeSelector={true}
                    visible={true}
                  />
                  <Column dataField="productName" caption="Productos" />
                  <Column
                    dataField="totalEntries"
                    caption="Total entradas"
                    alignment="center"
                  />
                  <Column
                    dataField="totalOuts"
                    caption="Total salidas"
                    alignment="center"
                  />
                  <Column
                    dataField="devolutions"
                    caption="Devoluciones"
                    alignment="center"
                  />
                  <Column
                    dataField="balance"
                    caption="Saldo"
                    alignment="center"
                  />
                </DataGrid>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>

      <div className="warehouse-inventory__modal" >
        <Modal title="Traslados" open={visible} onCancel={handleCancel}>
          <Form form={form} style={{ padding: 20 }} layout="vertical">
            <FormItem label="Producto" rules={[{ required: true }]}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Selecciona un producto"}
                value={productSelected}
                onChange={handleProductChange}
              >
                {props.productList.map((x: any) => (
                  <Option key={x.id} value={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <Form.Item
              label="Bodega"
              name="warehouse"
              rules={[{ required: true, message: 'Por favor ingresa la bodega' }]}
            >
              <Select
                placeholder={"Selecciona una bodega"}
                onChange={handleProductWarehouse}
                value={productWarehouseSelected}
              >
                <Option value={0}>Todas las bodegas</Option>
                {props.warehouseList.map((x: WarehouserData) => (
                  <Option key={x.id} value={x.id}>
                    {x.warehouseName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Tipo de movimiento"
              name="movement"
              rules={[{ required: true, message: 'Por favor ingresa el tipo de movimiento' }]}
            >
              <Select
                placeholder={"Selecciona el tipo de movimiento"}
                value={movementSelected}
                onChange={handleMovement}
              >
                <Option value={'Entrada'}>Entrada</Option>
                <Option value={'Salida Despachada'}>Salida despachada</Option>
                <Option value={'Devolución'}>Devolución</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Total"
              name="total"
              rules={[
                { required: true, message: 'Por favor ingresa el total' },
                { pattern: /^[0-9]+$/, message: 'Por favor ingresa solo números' }
              ]}
            >
              <Input />
            </Form.Item>

            <FormItem>
              <Button type="primary" onClick={handleFormSubmit}>
                Enviar
              </Button>
            </FormItem>
          </Form>
        </Modal>
      </div>

      <div className="warehouse-inventory__modal" >
        <Modal title="Traslados" open={transferVisible} onCancel={handleTransferCancel}>
          <Form form={formTransfer} style={{ padding: 20 }} layout="vertical">
            <FormItem label="Producto" rules={[{ required: true }]}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Selecciona un producto"}
                value={productTransferSelected}
                onChange={handleTransferProduct}
              >
                {props.productList.map((x: any) => (
                  <Option key={x.id} value={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <Form.Item
              label="Bodega inicial"
              name="warehouse"
              rules={[{ required: true, message: 'Por favor ingresa la bodega' }]}
            >
              <Select placeholder={"Selecciona una bodega"}>
                {props.warehouseList.map((x: WarehouserData) => (
                  <Option key={x.id} value={x.id}>
                    {x.warehouseName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Bodega final"
              name="finalWarehouse"
              rules={[{ required: true, message: 'Por favor ingresa la bodega' }]}
            >
              <Select placeholder={"Selecciona una bodega"}>
                {props.warehouseList.map((x: WarehouserData) => (
                  <Option key={x.id} value={x.id}>
                    {x.warehouseName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Total"
              name="total"
              rules={[
                { required: true, message: 'Por favor ingresa el total' },
                { pattern: /^[0-9]+$/, message: 'Por favor ingresa solo números' }
              ]}
            >
              <Input />
            </Form.Item>

            <FormItem>
              <Button type="primary" onClick={handleTransferFormSubmit}>
                Trasladar
              </Button>
            </FormItem>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default WarehouseInventory;
