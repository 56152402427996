import { Result } from "antd";
import Header from "../Header/Header";

interface IApprove {
  titulo: string;
  subtitulo: string;
}
const PendingApprove = (props: IApprove) => {
  return (
    <div>
      <Header />
      <Result
        status="info"
        title={props.titulo}
        subTitle={props.subtitulo}
      />
    </div>

  );
};

export default PendingApprove;