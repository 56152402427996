import { notification } from "antd";
import axios from "axios";
const URL = process.env.REACT_APP_serviceUrl;

const API = axios.create({
  baseURL: URL,
});

API.interceptors.request.use(function (config: any) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

API.interceptors.response.use(undefined, function (error) {
  error.handleGlobally = errorComposer(error);
  return Promise.reject(error);
})

const errorComposer = (error: any) => {
  const statusCode = error.response ? error.response.status : null;
  if (statusCode === 404) {
    console.error('The requested resource does not exist or has been deleted')
  }

  if (statusCode === 401) {
    notification.error({ message: 'Usuario no autorizado' })
    console.error('Please login to access this resource')
  }
}


export {
  API
};