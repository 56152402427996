import { Col, Row, Tabs } from "antd";
import DispatchRelationship from "./DispatchRelationship/DispatchRelationship";
import EditDispatchRelationship from "./DispatchRelationship/EditDispatchRelationship";
import PrintDispatchRelationship from "./PrintDispatchRelationship/PrintDispatchRelationship";
import WarehouseDispatchReport from "./WarehouseDispatchReport/WarehouseDispatchReport";

const { TabPane } = Tabs;

export const DispatchList = () => {
  return (
    <div>
        <h1 style={{ marginTop: "30px", textAlign: "center" }}>Relación de Despachos</h1>
       <Row>
        <Col span={2}></Col>
        <Col span={20}>
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Generar relación de despachos" key="1">
          <DispatchRelationship />
        </TabPane>
        <TabPane tab="Editar relación de despachos" key="2">
          <EditDispatchRelationship />
        </TabPane>
        <TabPane tab="Imprimir relación de despachos" key="3">
          <PrintDispatchRelationship />
        </TabPane>
        <TabPane tab="Reporte relación de despachos" key="4">
          <WarehouseDispatchReport />
        </TabPane>
      </Tabs>
      </Col>
      </Row>
    </div>
  )
}
