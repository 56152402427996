import { notification } from "antd";
import { useEffect, useState } from "react";
import { API } from "../API/axios";
import { storeName, product, productName } from "../Models/Products";
import { Category } from "../Models/Category.model";

export const useCategories = () => {

  const initialCategoryData: Category = {
    id: 0,
    name: "",
    picking_Packing_USD: 0,
    picking_Packing_COP: 0,
    storeIds: [],
    productIds: [],
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStoreKeys, setselectedStoreKeys] = useState<storeName[]>([]);
  const [selectedProductKeys, setSelectedProductKeys] = useState<productName[]>([]);
  const [storeList, setStoreList] = useState<storeName[]>([]);
  const [productList, setProductList] = useState<productName[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category>(initialCategoryData);

  useEffect(() => {
    getStoreList();
    getProductList();
    getCategories();
  }, []);

  const selectedStoreRows = (data: any) => {
    setselectedStoreKeys(data);
  };

  const selectedProductRows = (data: any) => {
    setSelectedProductKeys(data);
  };

  const newCategory = () => {
    setSelectedCategory(initialCategoryData);
    setSelectedProductKeys([]);
    setselectedStoreKeys([]);
  };

  const getStoreList = async () => {
    setLoading(true);
    let res = await API.get<storeName[]>(`/Stores/GetStoreList`);
    if (res.status == 200) {
      var Stores: storeName[] = [];
      res.data.map((x) => {
        Stores.push({ id: x.id, name: x.name, key: "id" });
      });
      setStoreList(Stores);
      setLoading(false);
    }
  };

  const getProductList = async () => {
    setLoading(true);
    let res = await API.get<productName[]>(`/Products`);
    if (res.status == 200) {
      var Products: productName[] = [];
      res.data.map((x) => {
        Products.push({ id: x.id, name: x.name, key: "id" });
      });
      setProductList(Products);
      setLoading(false);
    }
  };

  const deleteCategory = async (id: number) => {
    setLoading(true);
    let res = await API.delete<boolean>(`/Category/${id}`);
    if (res.status == 200) {
      notification.success({
        message: "Categoría eliminada",
      });
      setLoading(false);
      getCategories();
    }
  };

  const postCategory = async (data: Category) => {
    setLoading(true);
    if (!validateCategory()) {
      setLoading(false);
      return;
    }
    data.productIds = selectedProductKeys.map((x) => x.id);
    data.storeIds = selectedStoreKeys.map((x) => x.id);
    let res = await API.post<boolean>(`/Category`, data).catch((err) => { console.log(err); setLoading(false) });
    setLoading(false);
    notification.success({
      message: "Categoría actualizada",
      description: "El cambio pordría tardar unos minutos en verser reflejado",
    });
    getCategories();
  };

  // validatate if category has products or stores
  const validateCategory = () => {
    if (selectedProductKeys.length == 0) {
      notification.error({
        message: "Error",
        description: "Debe seleccionar al menos un producto",
      });
      return false;
    }
    if (selectedStoreKeys.length == 0) {
      notification.error({
        message: "Error",
        description: "Debe seleccionar al menos una tienda",
      });
      return false;
    }
    if (selectedCategory.name == "") {
      notification.error({
        message: "Error",
        description: "Debe ingresar un nombre",
      });
      return false;
    }
    if (selectedCategory.picking_Packing_COP == null || selectedCategory.picking_Packing_USD == null) {
      notification.error({
        message: "Error",
        description: "Debe ingresar un valor de picking y packing",
      });
      return false;
    }
    return true;
  };

  const getCategories = async () => {
    setLoading(true);
    let res = await API.get(`/Category`);
    if (res.status == 200) {
      setCategories(res.data.result);
      setLoading(false);
      return res.data;
    }
  };

  const getCategoryById = async (id: number) => {
    setLoading(true);
    let res = await API.get<Category>(`/Category/${id}`);
    if (res.status == 200) {
      setSelectedCategory(res.data);
      setSelectedKeys(res.data);
      setLoading(false);
      return res.data;
    }
  };

  const setSelectedKeys = async (category: Category) => {
    var Products: productName[] = [];
    category.productIds.map((x) => {
      var product: productName = productList.filter((pro) => pro.id == x)[0];
      Products.push({ id: product.id, name: product.name, key: "id" });
    });
    var prodListFilterBySelect = productList.filter((x) => !Products.includes(x))
    var newProducts = Products.concat(prodListFilterBySelect);
    var finalList = removeDuplicates(newProducts, "name");
    setProductList(finalList);

    var Stores: storeName[] = [];
    category.storeIds.map((x) => {
      var store: storeName = storeList.filter((pro) => pro.id == x)[0];
      Stores.push({ id: store?.id, name: store?.name, key: "id" });
    });

    var storeListFilterBySelect = storeList.filter((x) => !Products.includes(x))
    var newProducts = Stores.concat(storeListFilterBySelect);
    var finalListStores = removeDuplicates(newProducts, "name");
    setStoreList(finalListStores);
    setselectedStoreKeys(Stores);
    setSelectedProductKeys(Products);
  };

  const selectCategory = async (id: number) => {
    let res: any = await getCategoryById(id);
    setSelectedCategory(res);
  };

  const removeDuplicates = (originalArray: any[], prop: string) => {
    var newArray = [];
    var lookupObject: any = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  return {
    loading,
    selectedStoreRows,
    selectedProductRows,
    selectedStoreKeys,
    selectedProductKeys,
    storeList,
    productList,
    categories,
    deleteCategory,
    selectedCategory,
    selectCategory,
    postCategory,
    setSelectedCategory,
    newCategory
  };
};
