import { Button, Input, Modal, Spin } from 'antd';
import { useSearchByPhone } from './Hooks/useSearchByPhone';
import { ISearchByPhoneProps } from './ISearchByPhoneProps';
import './SearchByPhone.css';
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, Grouping, GroupPanel, SearchPanel } from 'devextreme-react/data-grid';
import ModalDetalleGuia from '../ModalDetalleGuia/ModalDetalleGuia';
import { MobileOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { PageContext } from '../../Hooks/ContextProvider';


const SearchByPhone = (props:ISearchByPhoneProps) => {

  const { userData } = useContext(PageContext);

  const {
    loading,
    SearchBill,
    billList,
    findGuia,
    guia,
    modalSearchGuiaVisible,
    setModalSearchGuiaVisible,
    cellRender,
    showModal,
    modalVisible, 
    setModalVisible
  } = useSearchByPhone();


  return (
    <div className='searchByPhone'>
      <Button title='Buscar guia por celular' style={{marginTop:30,borderRadius:'0px 10px 10px 0px'}} onClick={showModal}><MobileOutlined /></Button>
      <Modal
        open={modalVisible}
        title={`Buscar guia por celular`}
        width={1000}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={[]}
      >
      <Input.Search
        style={{ width: '25%', marginTop: 10 }}
        placeholder="Buscar guias por celular..."
        onSearch={SearchBill}
      />
      <Spin tip='Cargando...' spinning={loading}>
        <DataGrid
          style={{ marginTop: 10 }}
          dataSource={billList}
        >
          <GroupPanel visible={true} />
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <Grouping autoExpandAll={true} />
          <FilterRow visible={true} />
          <Column dataField="guia" alignment="center" caption="Ver Guia"
            type="buttons"
            buttons={[{
              text: "ver",
              hint: 'Ver Guia',
              icon: 'find',
              // visible:  (row: any) => row.row.data.totalPendingBillNews > 0 ? true : false,  
              onClick: (row: any) => { findGuia(row.row.data.guia) }
            }]}
          /> 
          <Column dataField="guia" caption="Guia" dataType="string" />
          <Column dataField= "productos" dataType="string" caption="Detalle" />
            {(userData?.roles.includes("Admin")  || userData?.roles.includes("SuperAdmin")) && <Column dataField="collect_value" dataType="number" caption="Recaudo" /> }
            {(userData?.roles.includes("Admin")  || userData?.roles.includes("SuperAdmin")) && <Column dataField="delivery_value" dataType="number" caption="Flete" /> }
            {(userData?.roles.includes("Admin")  || userData?.roles.includes("SuperAdmin")) && <Column dataField="value" dataType="number" caption="Cobro" /> }
          <Column dataField="created_at" dataType="string" caption="Fecha" />
          <Column
            dataField="company_TransportId"
            allowSorting={false}
            cellRender={cellRender}
            caption="Transportadora"
          />
          <Column dataField="status" dataType="string" caption="Estado" />
        </DataGrid>
      </Spin>
      {(modalSearchGuiaVisible && guia != "") &&
        <ModalDetalleGuia visible={modalSearchGuiaVisible} setVisible={setModalSearchGuiaVisible} guia={guia}/>
      }
      </Modal>
    </div>
  );
};

export default SearchByPhone;
