import { Button, Col, DatePicker, PageHeader, Row, Select, Spin } from "antd";
import { useSettlement } from "../../../Hooks/SuperAdmin/useSettlement";
import { user } from "../../../Models/SuperAdmin/users.model";
import { ISettlementProps } from "./ISettlementProps";
import "./Settlement.css";
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  Selection,
  GroupPanel,
  SearchPanel,
  Export,
  HeaderFilter,
  Sorting,
  ColumnFixing,
} from "devextreme-react/data-grid";
import { Tabs } from "antd";
import EditBill from "../EditBill/EditBill";

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;


const Settlement = (props: ISettlementProps) => {
  const {
    loadign,
    handleChangeCourrier,
    courriers,
    listbills,
    selectedRows,
    selectedBills,
    SaveData,
    dispatchetBills,
    showModalCellRender,
    showModal,
    setShowModal,
    selectedBill,
    PredevolutionRender,
    GetPendingBillsByDate,
    settledInvoices
  } = useSettlement();
  const AmountFormat = {
    style: "currency",
    currency: "USD",
    useGrouping: true,
    minimumSignificantDigits: 3,
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={2}></Col>
        <Col span={20}>
          <PageHeader
            className="site-page-header"
            title="Liquidación de Guias"
            subTitle=""
          />

          <Tabs defaultActiveKey="1">
            <TabPane tab="Liquidar Mensajero" key="1">
              <Row gutter={[16, 16]}>
                <Col span={20}></Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    disabled={selectedBills.length == 0}
                    onClick={SaveData}
                  >
                    Liquidar Guias
                  </Button>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={4}>
                  Seleccione mensajero:

                  <Select
                    style={{ width: "100%" }}
                    placeholder="Seleccionar mensajero..."
                    showSearch
                    optionFilterProp="children"
                    loading={loadign}
                    onChange={handleChangeCourrier}
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {courriers.map((x: user) => (
                      <Option key={x.id} value={x.id}>
                        {x.full_name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <br />
              <Row gutter={[8, 8]}>
                <Col span={3}>
                  <div className="total-container">
                    Total Entregas:{" "}
                    <span className="total-value">{selectedBills.length}</span>
                  </div>
                </Col>

                <Col span={4}>
                  <div className="total-container">
                    Recaudo Total:{" "}
                    <span className="total-value">
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.collect_value,
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={3}>

                  <div className="total-container">
                    Flete Total:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.delivery_value,
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={3}>

                  <div className="total-container">
                    Utilidad Crecel:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.crecelUtility,
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={4}>

                  <div className="total-container">
                    Uso Plataforma:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.platformUse,
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={4}>

                  <div className="total-container">
                    Comisión Recaudo{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.collectionCommission,
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={4}>

                  <div className="total-container">
                    Flete Consolidador:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + (a.delivery_value - a.platformUse - a.collectionCommission - a.crecelUtility),
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>


                <Col span={4}>

                  <div className="total-container">
                    Total a Liquidar:{" "}
                    <span className="total-value" style={{ color: "green" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.collect_value,
                        0
                      ) -
                        selectedBills.reduce(
                          (partialSum, a) => partialSum + (a.delivery_value - a.platformUse - a.collectionCommission - a.crecelUtility),
                          0
                        )
                        }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>
              </Row>
              <br />

              <Row gutter={[16, 16]}>
                <Col span={22}>
                  <Spin spinning={loadign}>
                    <DataGrid
                      dataSource={listbills}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      showBorders={true}
                      rowAlternationEnabled={true}
                      onSelectionChanged={selectedRows}
                    >
                      <Selection
                        mode="multiple"
                        selectAllMode={"allPages"}
                        showCheckBoxesMode={"onClick"}

                      />
                      <GroupPanel visible={true} />
                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                      />
                      <Grouping autoExpandAll={true} />
                      <FilterRow visible={true} />
                      <GroupPanel visible={true} />
                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                      />
                      <Grouping autoExpandAll={true} />
                      <Export enabled={true} allowExportSelectedData={false} />
                      <HeaderFilter visible={true} />
                      <Sorting mode="multiple" />
                      <GroupPanel visible={true} />
                      <ColumnFixing enabled={true} />
                      <FilterRow visible={true} />
                      <Grouping
                        contextMenuEnabled={true}
                        expandMode="rowClick"
                      />
                      <Grouping autoExpandAll={false} />
                      <FilterRow visible={true} />
                      <Column
                        dataField="created_at"
                        dataType="string"
                        caption="Fecha Guia"
                        format={Date}
                        width={100}
                      />
                      <Column
                        dataField="status"
                        dataType="string"
                        caption="Estado"
                        width={110}
                      />
                      <Column
                        dataField="guia"
                        dataType="string"
                        caption="Guia"
                        width={120}
                      />
                      <Column
                        dataField="products"
                        caption="Venta"
                        width={150}
                      />
                      <Column
                        dataField="city"
                        caption="Ciudad"
                        width={100}
                      />
                      <Column
                        dataField="delivery_News"
                        caption="Novedades"
                        width={330}
                      />
                      <Column
                        dataField="collect_value"
                        caption="Recaudo"
                        type={"number"}
                        format={AmountFormat}
                      />
                      <Column
                        dataField="delivery_value"
                        caption="Flete"
                        type={"number"}
                        format={AmountFormat}
                      />
                      <Column
                        dataField="crecelUtility"
                        caption="Utilidad crecel"
                        type={"number"}
                        format={AmountFormat}
                      />
                      <Column
                        dataField="platformUse"
                        caption="Uso Plataforma"
                        type={"number"}
                        format={AmountFormat}
                        width={120}
                      />

                      <Column
                        dataField="collectionCommission"
                        caption="Comision Recaudo"
                        type={"number"}
                        format={AmountFormat}
                        width={120}
                      />
                      <Column
                        dataField="delivery_value_Consolidator"
                        caption="Flete Consolidador"
                        type={"number"}
                        format={AmountFormat}
                        width={130}
                      />


                      <Column cellRender={showModalCellRender} width={50} />
                    </DataGrid>
                  </Spin>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Guias Despachadas" key="2">
              <Spin spinning={loadign}>
                <DataGrid
                  dataSource={dispatchetBills}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                >
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <FilterRow visible={true} />
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  <Grouping autoExpandAll={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <GroupPanel visible={true} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <FilterRow visible={true} />
                  <Column dataField="guia" dataType="string" caption="Guia" />
                  <Column dataField="status" caption="Estado" />
                  <Column dataField="city" caption="Ciudad" />
                  <Column
                    dataField="created_at"
                    caption="Creado"
                    type={"date"}
                    format={"dd-MM-yyyy"}
                  />
                  <Column dataField="store" caption="Tienda" />
                  <Column dataField="products" caption="Venta" width={200} />
                  <Column
                    dataField="dispatched"
                    caption="Despachado"
                    type={"date"}
                    format={"dd-MM-yyyy"}
                  />
                  <Column dataField="mensajero" caption="Mensajero" />
                </DataGrid>
              </Spin>
            </TabPane>
            <TabPane tab="Guias Liquidadas" key="3">
              <Row gutter={[16, 16]}>
                <Col span={4}>
                  <RangePicker onChange={GetPendingBillsByDate} />
                </Col>
              </Row>

              <br />
              <Row gutter={[8, 8]}>
                <Col span={3}>
                  <div className="total-container">
                    Total Entregas:{" "}
                    <span className="total-value">{selectedBills.length}</span>
                  </div>
                </Col>

                <Col span={4}>

                  <div className="total-container">
                    Recaudo Total:{" "}
                    <span className="total-value">
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.collect_value,
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={3}>

                  <div className="total-container">
                    Flete Total:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.delivery_value,
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>
                <Col span={3}>

                  <div className="total-container">
                    Utilidad Crecel:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.crecelUtility,
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={4}>

                  <div className="total-container">
                    Uso Plataforma:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.length * 2000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={4}>

                  <div className="total-container">
                    Comisión Recaudo:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.length * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={4}>

                  <div className="total-container">
                    Flete Consolidador:{" "}
                    <span className="total-value" style={{ color: "red" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + (a.delivery_value - 2000),
                        0
                      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>

                <Col span={4}>
                  <div className="total-container">
                    Total a Liquidar:{" "}
                    <span className="total-value" style={{ color: "green" }}>
                      {`$ ${selectedBills.reduce(
                        (partialSum, a) => partialSum + a.collect_value,
                        0
                      ) -
                        selectedBills.reduce(
                          (partialSum, a) => partialSum + (a.delivery_value - 2000),
                          0
                        )
                        }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>
              </Row>

              <br />
              <Row gutter={[16, 16]}>
                <Col span={22}>
                  <Spin spinning={loadign}>
                    <DataGrid
                      dataSource={settledInvoices}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      showBorders={true}
                      rowAlternationEnabled={true}
                      onSelectionChanged={selectedRows}
                    >
                      <Selection
                        mode="multiple"
                        selectAllMode={"allPages"}
                        showCheckBoxesMode={"onClick"}
                      />
                      <GroupPanel visible={true} />
                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                      />
                      <Grouping autoExpandAll={true} />
                      <FilterRow visible={true} />
                      <GroupPanel visible={true} />
                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                      />
                      <Grouping autoExpandAll={true} />
                      <Export enabled={true} allowExportSelectedData={false} />
                      <HeaderFilter visible={true} />
                      <Sorting mode="multiple" />
                      <GroupPanel visible={true} />
                      <ColumnFixing enabled={true} />
                      <FilterRow visible={true} />
                      <Grouping
                        contextMenuEnabled={true}
                        expandMode="rowClick"
                      />
                      <Grouping autoExpandAll={false} />
                      <FilterRow visible={true} />
                      <Column
                        dataField="courierName"
                        dataType="string"
                        caption="Mensajero"
                        width={150}
                      />
                      <Column
                        dataField="status"
                        dataType="string"
                        caption="Estado"
                        width={150}
                      />
                      <Column
                        dataField="guia"
                        dataType="string"
                        caption="Guia"
                        width={120}

                      />
                      <Column dataField="products" caption="Venta" width={150} />
                      <Column dataField="city" caption="Ciudad" width={100} />
                      <Column
                        dataField="collect_value"
                        caption="Recaudo"
                        type={"number"}
                        format={AmountFormat}
                      />
                      <Column
                        dataField="delivery_value"
                        caption="Flete"
                        type={"number"}
                        format={AmountFormat}
                      />
                      <Column
                        dataField="crecelUtility"
                        caption="Utilidad crecel"
                        type={"number"}
                        format={AmountFormat}
                      />
                      <Column
                        dataField="platformUse"
                        caption="Uso Plataforma"
                        type={"number"}
                        format={AmountFormat}
                        width={130}
                      />

                      <Column
                        dataField="collectionCommission"
                        caption="Comisión Recaudo"
                        type={"number"}
                        format={AmountFormat}
                        width={130}
                      />
                      <Column
                        dataField="delivery_value_Consolidator"
                        caption="Flete Consolidador"
                        type={"number"}
                        format={AmountFormat}
                      />


                    </DataGrid>
                  </Spin>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
          {selectedBill && (
            <EditBill
              showModal={showModal}
              setShowModal={setShowModal}
              billId={selectedBill}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Settlement;
