import * as React from 'react';

// Custom Hooks
import { useCustomers } from './Hooks/useCustomers';

// Styles
import './Customers.css';
import { Button, Col, Form, Input, Row } from 'antd';


const Customers = () => {

  const { findClient, storeClient, handleChange, handleUpdate, isComment, loading } = useCustomers();

  return (
    <Row gutter={[16, 16]}>
      <Col span={2}></Col>
      <Col span={20} className="cardContainner" style={{ padding: 50 }}>
        <div className="Titulo">Clientes</div>

        <Col span={12}>
          <Input.Group compact>
            <Input.Search
              style={{ width: '100%' }}
              placeholder="Buscar teléfono..."
              onSearch={findClient}
            />
          </Input.Group>
        </Col>

        <Row gutter={24}>
          <Col span={12}>
            Nombre:
            <Input
              onChange={(e) => handleChange(e.target.value, "name")}
              value={storeClient.name}
              // readOnly={isComment}
            />
          </Col>
          <Col span={12}>
            Teléfono:
            <Input
              onChange={(e) => handleChange(e.target.value, "phone")}
              value={storeClient.phone}
              // readOnly={isComment}
            />
          </Col>
          <Col span={12}>
            Comentarios:
            <Input
              onChange={(e) => handleChange(e.target.value, "warningComments")}
              value={storeClient.warningComments}
              // readOnly={isComment}
            />
            {storeClient.warningComments === '' &&
              <small style={{color: "red"}}>Este cliente no tiene comentarios</small>
            }
          </Col>
          <Col span={12}>
           <Button
            type="primary"
            style={{marginTop: '20px'}}
            onClick={handleUpdate}
            loading={false}
            // disabled={isComment}
           >
             Actualizar
           </Button>
          </Col>
        </Row>


      </Col>
    </Row>
  );
};

export default Customers;
