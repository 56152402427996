import * as React from "react";
import { IDroppHooksProps } from "./IDroppHooksProps";
import "./DroppHooks.css";
import { Col, Input, Row, Spin } from "antd";
import { usePowerSellers } from "../../Hooks/Store/PowerSellers";
const { Search } = Input;

const DroppHooks = (props: IDroppHooksProps) => {
  const { loading, productsListFiltered, onSearchFilteredProduct } =
    usePowerSellers();

  return (
    <div className="droppHooks-container">
      <span className="sub-title-white">DROP</span>&nbsp;&nbsp;
      <span className="bold-title-white">HOOKS</span>
      <div className="droppHooks-description">
        Descubre el contenido que hemos creado para ti, vende tus productos con
        contenido de valor.
      </div>
      <div className="droppHooks-folder-container">
        <Row className="droppHooks-folder-title">
          <Col span={20}>
            <span className="color-texto-gris">Todos los</span>&nbsp;&nbsp;
            <span className="color-texto-gris">
              <b>productos</b>
            </span>
            <div className="gray-line"></div>
          </Col>
          <Col span={4}>
            <Search
              placeholder="Buscar producto"
              onChange={(e) => onSearchFilteredProduct(e.target.value)}
              onSearch={onSearchFilteredProduct}
            />
          </Col>
        </Row>
        <Spin spinning={loading} tip="Cargando">
          <Row gutter={[16, 16]} className="item-container">
            {productsListFiltered.map((item) => (
              <>
                {item.testimonials !== "" && (
                  <Col span={3}>
                    <a href={localStorage.currency == 'COP' ? item.promotional_Images : item.youtube} target="_blank">
                      <img src={item.testimonials} alt={item.name} />
                    </a>
                  </Col>
                )}
              </>
            ))}
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default DroppHooks;
