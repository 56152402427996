import { Col, Row, Spin } from "antd";
import { useContext } from "react";
import { PageContext } from "../../Hooks/ContextProvider";
import { usePanel } from "../../Hooks/Store/usePanel";
import { StoreResume } from "../../Models/storeResume";
import Chart from "../Chart/Chart";
import { IPanelProps } from "./IPanelProps";
import "./Panel.css";
import Placas from "./Placas/Placas";

const Panel = (props: IPanelProps) => {
  const { storeResume, loading, totalWallet, store, ChartDataSource,setDayQuantity,dayQuantity,storePanelResume } =
    usePanel();

  const { userData } = useContext(PageContext);

  return (
    <div className="panel-container">
      <Row>
        <Col xs={1} sm={1} md={1} lg={2} xl={2}></Col>
        <Col xs={22} sm={22} md={22} lg={20} xl={20}>
          <Chart ChartDataSource={ChartDataSource} setDayQuantity={setDayQuantity} dayQuantity={dayQuantity}/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col xs={1} sm={1} md={1} lg={2} xl={2}></Col>
        <Col xs={22} sm={22} md={22} lg={6} xl={6} className="left-panel">
          <div className="logo-container mt-50">
            <img src={store?.logo} className="logo" />
          </div>
          <div className="line-h-20">
            <span className="hola-text">¡Hola</span>&nbsp;
            <span className="user-name">{userData?.userName}!</span>
          </div>
          <div className="line-h-20">
            <span className="normal-text">SALDO</span>&nbsp;
            <span className="bold-text">ACTUAL</span>
          </div>
          <div className="line-h-40">
            <span className="amount-value">
              {`$ ${totalWallet}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          </div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={0} xl={0}></Col>

        <Col xs={22} sm={22} md={22} lg={8} xl={8}>
          {storeResume.length > 0 && (
            <>
              <Row>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className="bg-2 panel-metric"
                >
                  <Spin spinning={loading}>
                    <img src="https://drophacking.blob.core.windows.net/images/Panel/INCONO-DE-INGRESOS.png" />
                    <div className="line-h-20">
                      <span className="panel-title">INGRESOS</span>
                      <br />
                      <span className="panel-value">
                        {" "}
                        {`$ ${storeResume
                          .map((x: StoreResume) => {
                            return x.ingresos;
                          })
                          .reduce(function (a: number, b: number) {
                            return a + b;
                          }).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </div>
                  </Spin>
                </Col>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className="bg-3 panel-metric"
                >
                  <img src="https://drophacking.blob.core.windows.net/images/Panel/INCONO-DE-INGRESOS.png" />
                  <div className="line-h-20">
                    <span className="panel-title">GANANCIA</span>
                    <br />
                    <span className="panel-value">
                      {`$ ${storePanelResume?.ganancias.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className="bg-3 panel-metric"
                >
                  <img src="https://drophacking.blob.core.windows.net/images/Panel/ICONO-DE-FLETES.png" />
                  <div className="line-h-20">
                    <span className="panel-title">FlETES</span>
                    <br />
                    <span className="panel-value">
                    {`$ ${storePanelResume?.fletes.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className="bg-2 panel-metric"
                >
                  <img src="https://drophacking.blob.core.windows.net/images/Panel/ICONO-DE-DEVOLUCIONES.png" />
                  <div className="line-h-20">
                    <span className="panel-title">DEVOLUCIONES</span>
                    <br />
                    <span className="panel-value">
                    {`$ ${storePanelResume?.devoluciones.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className="bg-2 panel-metric"
                >
                  <img src="https://drophacking.blob.core.windows.net/images/Panel/INCONO-DE-PRODUCTOS-.png" />
                  <div className="line-h-20">
                    <span className="panel-title">PRODUCTOS</span>
                    <br />
                    <span className="panel-value">
                    {`$ ${storePanelResume?.productos.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  className="bg-3 panel-metric"
                >
                  <img src="https://drophacking.blob.core.windows.net/images/Panel/DESEMPEÑO.png" />
                  <div className="line-h-20">
                    <span className="panel-title">DESEMPEÑO</span>
                    <br />
                    <span className="panel-value">
                    {`% ${storePanelResume?.desempenio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>
            <Col xs={22} sm={22} md={22} lg={6} xl={6} className="left-panel">
              <Placas />
            </Col>
        <Col xs={1} sm={1} md={1} lg={4} xl={4}></Col>
      </Row>
    </div>
  );
};

export default Panel;
