import { Button, Col, Row } from 'antd';
import './Reality.css';
import realidad from '../../Assets/realidad.png';
import eliminarRiesgo from '../../Assets/eliminarRiesgo.png';



const Reality = () => {
    return (

        <div className='fondoReality'>

            <Row  >
                <Col xs={1} sm={1} md={3} lg={3} xl={3} ></Col>
                <Col xs={22} sm={22} md={7} lg={7} xl={7} >
                    <img src={realidad} className='imgRealidad' />
                </Col>

                <Col xs={1} sm={1} md={3} lg={3} xl={3} ></Col>
                <Col xs={1} sm={1} md={0} lg={0} xl={0} ></Col>

                <Col xs={22} sm={22} md={8} lg={8} xl={8} >
                <img src={eliminarRiesgo} className='imgEliminar'/>
                </Col>

                <Col xs={1} sm={1} md={3} lg={3} xl={3} ></Col>


            </Row>
        </div>
    )
}

export default Reality;

