import { useEffect, useState, useRef } from 'react';
import { IDispatchRelationshipProps } from './IDispatchRelationshipProps';
import './DispatchRelationship.css';
import { Button, Col, Input, message, Popconfirm, Row, Select, Spin, Table } from 'antd';
import moment from 'moment';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { company_transport } from '../../../../Models/company_Transport';
import { Bill } from '../../../../Models/Bill';
import { BillProducts, product } from '../../../../Models/Products';
import SelectCrecelCourier from '../../../UtilityComponents/SelectCrecelCourier/SelectCrecelCourier';
import { API } from '../../../../API/axios';

const { Option } = Select;

const DispatchRelationship = (props: IDispatchRelationshipProps) => {

  const [selectedCompanyTransport, setSelectedCompanyTransport] = useState<number>();
  const [guia, setGuia] = useState<string>("");
  const [companyTransportList, setCompanyTransportList] = useState<company_transport[]>([]);
  const inputRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [listDataPendingDispatch, setListDataPendingDispatch] = useState<Array<Bill>>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [dataForDispatch, setDataForDispatch] = useState<Array<Bill>>([]);
  const [productList, setProductList] = useState<Array<product>>([]);
  const [selectedCrecelCourier, setSelectedCrecelCourier] = useState<number>(0);

  useEffect(() => {
    GetCompanyTransport();
    getPendingBillsDispatch();
    getProductList();
  }, []);

  const GetCompanyTransport = () => {
    API.get<company_transport[]>(`/CompanyTransports`).then(res => {
      setCompanyTransportList(res.data);
    });
  }

  const getProductList = () => {
    API.get<product[]>(`/Products`).then(res => {
      setProductList(res.data);
    })
  }

  const addItemToArray = (e: any) => {
    if (e.key === 'Enter') {
      let guia = e.target.value;
      let bill = listDataPendingDispatch.find((x: Bill) => x.guia === guia);
      let billInDispatch = dataForDispatch.map((x: Bill) => x.guia).indexOf(guia);
      if (billInDispatch === -1) {
        if (bill !== undefined) {
          let object: Bill = bill;
          setDataForDispatch(dataForDispatch => [...dataForDispatch, object])
        }
        else {
          message.error('Esta guia no esta disponible para ser despachada', 4)
        }
      }
      else {
        message.warning(`La guia ${guia}, ya está incluída en esta relación de despacho`, 5)
      }
      inputRef.current.focus();
      setGuia("");
    }
  }

  const getPendingBillsDispatch = () => {
    let array: any = [];
    setLoading(true);
    API.get<Bill[]>(`/Bills/GetBillsByStatus?status=Impreso-G`).then(res => {
      res.data.map((x: Bill) => {
        x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
        x.key = x.id;
        array.push(x);
      })
    });
    //Despachado
    API.get<Bill[]>(`/Bills/GetBillsByStatus?status=Despachado`).then(res => {
      res.data.map((x: Bill) => {
        x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
        x.key = x.id;
        array.push(x);
      })
      setLoading(false);
      setListDataPendingDispatch(array);
    });
  }

  const removeItemsFromDispatch = () => {
    let arrayTemp = [...dataForDispatch as any];
    selectedRowKeys.map((x: number) => {
      arrayTemp = arrayTemp.filter((r: Bill) => r.id !== x);
    });
    setDataForDispatch(arrayTemp);
    setSelectedRowKeys([])
  }

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (e: any) => onSelectChange(e),
  };

  const columns = [
    {
      title: 'Guia',
      dataIndex: 'guia',
      key: 'guia'
    },
    {
      title: 'Teléfono',
      dataIndex: 'guia',
      key: 'guia',
      render: (f: any, x: Bill) => {
        return `${x.storeClient?.cellphone} / ${x.storeClient?.phone}`
      }
    },
    {
      title: 'Contenido',
      dataIndex: 'bill_Products',
      key: "bill_Products",
      render: (f: any, x: Bill) => {
        let arrayProds = x.bill_Products.map((prod: BillProducts) => {
          let productFiltered = productList.filter((p: product) => prod.productId === p.id);
          return productFiltered.length > 0 ? `${prod.quantity} ${productFiltered[0].name}` : ""
        });
        return arrayProds.join();
      }
    },
    {
      title: 'Recaudo',
      dataIndex: 'bill_Products',
      key: "bill_Products",
      render: (f: any, x: Bill) => {
        let total = x.collect_value;
        return `$ ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  ];

  const saveData = () => {
    if (companyTransportList.filter(x => x.name === 'Crecel')[0].id === selectedCompanyTransport ||
      companyTransportList.filter(x => x.name === 'Crecel Ecuador')[0].id === selectedCompanyTransport) {
      let el = {
        courierId: selectedCrecelCourier,
        billingsId: dataForDispatch.map((x: Bill) => { return x.id !== undefined ? x.id : 0 })
      }
      API.post(`/CrecelDeliveries`, el).then((res: any) => {
        message.success(`Se ha generado la relación de despacho Nº ${res.data.id}`, 5);
        setCompanyTransportList([]);
        setSelectedRowKeys([]);
      })
    }
    else {
      let el = {
        billingsId: dataForDispatch.map((x: Bill) => { return x.id !== undefined ? x.id : 0 })
      }
      API.post(`/Dispatchs`, el).then((res: any) => {
        message.success(`Se ha generado la relación de despacho Nº ${res.id}`, 5)
        setCompanyTransportList([]);
        setSelectedRowKeys([]);
      })
    }
  }

  const onChangeCrecelCourier = (e: number) => {
    setSelectedCrecelCourier(e)
  }

  return (
    <div className={`dispatchRelationship`}>
      <Row>
        <Col span={4}>
          Transportadora:<br />
          <Select
            className={'input'}
            style={{ width: '100%' }}
            placeholder={"Selecciona una transportadora"}
            onChange={(e: number) => {
              setSelectedCompanyTransport(e);
            }}
            value={selectedCompanyTransport}
          >
            {companyTransportList.map((x: company_transport) =>
              <Option
                key={x.id}
                value={x.id}
              >
                <img src={x.iconPath} style={{ width: 40, marginRight: 20 }} alt="icon" />{x.name}
              </Option>)}
          </Select>
        </Col>
        <Col span={1}></Col>
        <Col span={4}>
          Guia:<br />
          <Input
            className={'input'}
            style={{ width: '100%' }}
            ref={inputRef}
            value={guia}
            onChange={(e) => setGuia(e.target.value)}
            onKeyDown={addItemToArray}
            disabled={loading || selectedCompanyTransport === undefined}
          />
        </Col>
        <Col span={1}></Col>
        {companyTransportList.length > 0 &&
          companyTransportList.filter(x => x.name === 'Crecel')[0].id === selectedCompanyTransport &&
          <Col span={4}>
            Mensajero:
            <SelectCrecelCourier
              onChangeCrecelCourier={onChangeCrecelCourier}
              value={selectedCrecelCourier} />
          </Col>
        }
        {companyTransportList.length > 0 &&
          companyTransportList.filter(x => x.name === 'Crecel Ecuador')[0].id === selectedCompanyTransport &&
          <Col span={4}>
            Mensajero:
            <SelectCrecelCourier
              onChangeCrecelCourier={onChangeCrecelCourier}
              value={selectedCrecelCourier} />
          </Col>
        }
        <Col span={1}></Col>
        <Col span={4}>
          {selectedRowKeys.length > 0 &&
            <Popconfirm
              placement="top"
              title="¿Eliminar las guias seleccionadas de la relación ?"
              onConfirm={removeItemsFromDispatch}
              okText="Sí" cancelText="No">
              <br />
              <Button icon={<DeleteOutlined />} type="primary">{`Elimininar (${selectedRowKeys.length}) de la relación`}</Button>
            </Popconfirm>
          }
        </Col>
        <Col span={2}></Col>
        <Col span={2}>
          <br />
          {dataForDispatch.length > 0 &&
            <Button icon={<SaveOutlined />} type="primary" onClick={saveData}>Guardar</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Spin tip="Cargando datos..." spinning={loading}>
            <Table rowSelection={rowSelection} columns={columns} dataSource={dataForDispatch} />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default DispatchRelationship;
