import { Col, Row, Select, Spin } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, ColumnFixing, Export, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Sorting } from "devextreme-react/data-grid";
import { WarehouserData } from "../../Models/warehouse.model";
import "./WarehouseInventoryDetail.css"

const WarehouseInventoryDetail = (props: any) => {
  const { Option } = Select;

  return(
    <div className={`warehouseInventory`}>
      <Row>
        <Col>
          <Spin tip="Cargando datos..." spinning={props.loading}>
            <Row>
              <Col span={5}>
                <div className="warehouse-inventory-detail__select">
                  <span>Bodega:</span> 
                  <Select
                    style={{ width: "100%" }}
                    placeholder={"Selecciona una bodega"}
                    onChange={props.onChangeFunction}
                    value={props.selectedWarehouse}
                  >
                    <Option value={0}>Todas las bodegas</Option>
                    {props.warehouseList.map((x: WarehouserData) => (
                      <Option key={x.id} value={x.id}>
                        {x.warehouseName}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ padding: 10, backgroundColor: "white" }}>
                <DataGrid
                  id="Inventory"
                  dataSource={props.inventoryList}
                  showBorders={true}
                  remoteOperations={true}
                  allowColumnResizing={true}
                  columnResizingMode={"widget"}
                  showRowLines={true}
                >
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <GroupPanel visible={false} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                  <Grouping autoExpandAll={false} />
                  <Pager
                    showInfo={true}
                    showNavigationButtons={true}
                    showPageSizeSelector={true}
                    visible={true}
                  />
                  <Column dataField="date" caption="Fecha" />
                  <Column
                    dataField="guia"
                    caption="Guia"
                    alignment="center"
                  />
                  <Column
                    dataField="productName"
                    caption="Producto"
                    alignment="center"
                  />
                  <Column
                    dataField="quantity"
                    caption="Cantidad"
                    alignment="center"
                  />
                </DataGrid>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

export default WarehouseInventoryDetail