import React, { useEffect, useState, createRef } from 'react'
import moment from 'moment';

import { message } from 'antd';
import { product } from '../../../Models/Products';
import { company_transport } from '../../../Models/company_Transport';
import { inventoryMovement, Warehouse, WarehouserData } from '../../../Models/warehouse.model';
import { API } from '../../../API/axios';


export const useWarehouseAdministration = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<product[]>([]);
    const [productsFiltered, setProductsFiltered] = useState<product[]>([]);
    const [companyTransport, setCompanyTransport] = useState<company_transport[]>([]);
    const [companyTransportId, setCompanyTransportId] = useState<number>(0);
    const [companyTransportSelected, setCompanyTransportSelected] = useState<number>(0);
    const [companyTransportFiltered, setCompanyTransportFiltered] = useState<company_transport[]>([]);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [warehouseFiltered, setWarehouseFiltered] = useState<Warehouse[]>([]);
    const [inventoryMovements, setInventoryMovements] = useState<inventoryMovement[]>([]);
    const [warehouseByUserId, setWarehouseByUserId] = useState<WarehouserData[]>([]);
    

    const formRef = createRef<any>();

    const onFinish = ( value: inventoryMovement ) => {
        formRef.current.resetFields();

        value.movement_Type = 'Entrada';
        value.created_at = moment().format('YYYY-MM-DD');
        value.updated_at = moment().format('YYYY-MM-DD');

        setLoading(true);
        API.post(`/Inventory_Movement`,value).then( resp => {
            message.success('Se creó el traslado correctamente.');
            setLoading(false);
            handleSelectWarehouse( value.warehouseId );
        }).catch(err => {
            setLoading(false);
            message.error(`No se pudo crear el traslado. ${err}`);
        });
    }

    const getProducts = () => {
        setLoading(true);
        API.get(`/Products`).then( (resp: any) => {
            setProducts(resp.data);
            setProductsFiltered(resp.data);
            setLoading(false);
        }).catch( err => {
            setLoading(false);
            message.error(`No se pudo cargar los productos. ${err}`)
        })
    }

    const getCompanyTransport = () => {
        setLoading(true);
        API.get(`/CompanyTransports`).then( resp => {
            setCompanyTransport(resp.data);
            setCompanyTransportFiltered(resp.data);
            setLoading(false);
        }).catch( err => {
            setLoading(false);
            message.error(`No se pudo cargar las transportadoras. ${err}`);
        })
    }

    const getWarehouseGetWarehouseList = async () => {
        try {
            let warehouseByUser = await API.get(`/Warehouses/GetWarehouseList`);
            setWarehouseByUserId(warehouseByUser.data);
        } catch (error) {
            message.error(`No se pudo cargar las bodegas ${error}`);
        }
    }

    const handleSelectCompany = async (value: number) => {
        try {
            const warehouses = await API.get(`/Warehouses/${companyTransportId}`);
            setWarehouses( warehouses.data );
        } catch (error) {
            message.error(`No se pudo cargar las bodegas. ${error}`);
        }
    }

    const handleSelectWarehouse = async (value: number) => {
        try {
            const inventory = await API.get(`/Inventory_Movement?warehouseId=${value}`); 
            setInventoryMovements( inventory.data );
        } catch (error) {
            message.error(`No se pudo cargar las bodegas. ${error}`);
        }
    }


    const onSearchFilteredProduct = (value: string) => {
        if (value) {
            setProductsFiltered(products.filter((x: product) => x.name.toLowerCase().includes(value)));  
        } else {
            setProductsFiltered(products);
        }
    }

    const onSearchFilteredCompanyTransport = (value: string) => {
        if (value) {
            setCompanyTransportFiltered(companyTransport.filter((x: company_transport) => x.name.toLowerCase().includes(value)));  
        } else {
            setCompanyTransportFiltered(companyTransport);
        }
    }

    const onSearchFilteredWarehouse = ( value: string ) => {
        if (value) {
            setWarehouseFiltered(warehouses.filter((x: Warehouse) => x.name.toLowerCase().includes(value)));  
        } else {
            setWarehouseFiltered(warehouses);
        }
    }

    useEffect(() => {
        getProducts();
        getCompanyTransport();
        getWarehouseGetWarehouseList();
    },[]);

    return {
        companyTransportFiltered,
        loading,
        formRef,
        products,
        productsFiltered,
        onSearchFilteredProduct,
        onSearchFilteredCompanyTransport,
        warehouses,
        handleSelectCompany,
        handleSelectWarehouse,
        onSearchFilteredWarehouse,
        onFinish,
        inventoryMovements,
        warehouseByUserId
    }
}
