import { useState, createContext } from 'react';
import { UserData } from '../Models/User.Model';
import utils from '../utils';
type ContextProps = {
    selectedComponent?: string,
    setSelectedComponent: (selectedComponent:string) => void,
    userData?: UserData,
    setUserData: (userData:UserData) => void,
};

export const PageContext = createContext<ContextProps>({
    setSelectedComponent: () => {},
    selectedComponent: 'Panel',
    setUserData: () => {},
});


let userD:any = null;
let roles:any = [];
let token = localStorage.getItem('auth_token');
if(token){
    userD = utils.parseJwt(localStorage.getItem('auth_token'));
    roles = userD["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
}

const PageContextProvider = (props: any) => {
    const [selectedComponent, setSelectedComponent] = useState<string>('Panel');
    const [userData, setUserData] = useState<UserData>({
        userName: userD ? userD.nombre : '',
        email:userD ? userD.email : '',
        storeID: userD ? userD.storeId : 0,
        //storeID:1123,
        userID : userD ? userD.nameid : 0,
        roles:roles,
    });

    return (
        <PageContext.Provider value={{
            selectedComponent,
            setSelectedComponent:setSelectedComponent,
            userData,
            setUserData:setUserData,
        }}>
            {props.children}
        </PageContext.Provider>
    );
}
export default PageContextProvider;