import React, { useState, useEffect } from 'react';
import { IUserRegistrationProps } from './IUserRegistrationProps';
import './UserRegistration.css';
import { Row, Col, Input, Button, message, Select } from 'antd';

const UserRegistration  = (props:IUserRegistrationProps) => {

  const { Option } = Select;

  const [confirmPassword, setConfirmPassword] = useState("");

  const updateState = (value: any, prop: string) => {
    let arrayAux = { ...props.user as any }
    arrayAux[prop] = value;
    props.setUser(arrayAux);
  }

  const userRegister = () => {
    if (isValid()) {
      props.setCurrentStep(2);
    }
  }

  const isValid = () => {
    let isValid = true
    if (!validateEmail(props.user.email)) {
      message.error('Debe ingresar un correo válido', 8);
      isValid = false;
    }
    if (props.user.password !== confirmPassword || confirmPassword === "") {
      message.error('las contraseñas no son iguales', 8);
      isValid = false;
    }

    if (props.user.full_name === "" || props.user.full_name === undefined) {
      message.error('Debe ingresar el nombre del administrador', 8);
      isValid = false;
    }
    return isValid;
  }

  function validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  return (
    <div className={`userRegistration`}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            placeholder="Nombre completo del administrador de la tienda"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-usuario.png"
                className="form-icon"
                alt="icono de usuario"
              />
            }
            onChange={(e) => updateState(e.target.value, 'full_name')}
            className="input"
          />
          </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            placeholder="Correo electrónico"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-correo-electronico.png"
                className="form-icon"
                alt="icono de correo electrónico"
              />
            }
            onChange={(e) => updateState(e.target.value, 'email')}
            className="input"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input.Password
            placeholder="Contraseña"
            type="password"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/iconod-e-contraseña.png"
                className="form-icon"
                alt="icono de contraseña"
              />
            }
            onChange={(e) => updateState(e.target.value, 'password')}
            className="input"
          />
          
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input.Password
            placeholder="Confirma la contraseña"
            type="password"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/iconod-e-contraseña.png"
                className="form-icon"
                alt="icono de contraseña"
              />
            }
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="input"
          />
        </Col>

      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button className={'generic-btn'} type="primary" onClick={() => userRegister()}>Registrar Usuario</Button>
        </Col>
      </Row>
    </div>
  );
};

export default UserRegistration;
