import { Button, Col, Input, Row } from "antd"
import { usePDFContract } from "../../Hooks/usePDFContract"
import './FillContract.css'
interface ISign {
    id: number;
}

const FranquiciaContract = (props: ISign) => {

    const { showBorder, Page1Ref, Page2Ref, downloadImage, handleFileRead, sign } = usePDFContract();
    return (
        <div>
            <div className="panel-container">
                <div className="page">
                    <div className="subpage" ref={Page1Ref}>
                        <h1 className="center"><u>MINUTA DE CONTRATO DE FRANQUICIA</u></h1>
                        Entre los suscritos a saber:    <strong>LINA PATRICIA CALLE SALDARRIAGA, </strong>mayor de edad,
                        domiciliada y residente en la ciudad de Medellín departamento de Antioquia,
                        identificada con la cédula de ciudadanía Nº <strong>43´597.363 </strong>
                        expedida en Medellín Ant., quien obra como    <strong>Representante Legal </strong>de <strong>“CRECEL” S.A.S. </strong>
                        con NIT. N. <strong>901274994-1, </strong>inscrita ante la Cámara de
                        Comercio de Medellín bajo N. radicado <strong>21-645045-12 </strong>y,
                        quien para efectos de este contrato se denominará la <strong>FRANQUICIANTE; </strong>y de otra parte
                        <Input style={{ width: 250 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nombre' id='input1' />
                        también mayor y vecino(a) de la ciudad de
                        <Input style={{ width: 190 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Ciudad' />, identificado(a) con documento de identidad
                        Nº.<Input style={{ width: 100 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nº Documento' /> expedida en <Input style={{ width: 180 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Ciudad' />quien actúa en nombre
                        propio, el cual, para todos los efectos de este contrato se denominará
                        el(la) <strong>FRANQUICIADO(A) </strong>respecto a la Tienda
                        Virtual<Input style={{ width: 190 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nombre tienda' />. Ambas partes, conforme al ordenamiento jurídico
                        colombiano y, con capacidad para actuar, hemos convenido en celebrar el
                        presente <strong>CONTRATO DE FRANQUICIA</strong>, que se regirá por las
                        siguientes cláusulas: <strong>PRIMERA. Objeto. —</strong>La <strong>FRANQUICIANTE</strong>, propietaria de la marca    <strong>CRECEL S.A.S. </strong>con NIT. N. <strong>901274994-1, </strong>
                        concede a título oneroso el uso de la
                        <strong>
                            Plataforma Virtual Educativa “CRECEL”, Uso de Software Logístico y
                            Logística de Envíos, Productos de la Línea CRECEL, Acceso y Venta de
                            Infoproductos de CRECEL,
                        </strong>
                        y <strong>KNOW HOW</strong>, para ser explotado en actividad comercial
                        lícita y educativa, ya sea en Colombia o en cualquier país. El punto de
                        venta estará ubicado en la plataforma virtual (redes).
                        <strong>
                            SEGUNDA. Derechos de entrada, uso de marca y producto.—LA FRANQUICIANTE
                        </strong>
                        concede el uso de su marca y productos relacionados en la cláusula primera,
                        por la suma de <Input style={{ width: 80 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Valor' /> dólares (US$<Input style={{ width: 50 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Valor' />), las cuales, serán
                        canceladas en los sistemas de pago de <strong>Hotmart </strong>o a la
                        cuenta bancaria que determine    <strong>LA FRANQUICIANTE. TERCERA. Regalías.—</strong>La utilización de la
                        franquicia no generará cobro de regalías.    <strong>CUARTA. OBLIGACIONES DE LA FRANQUICIANTE.—</strong>1. Incorporar
                        nuevos productos y mejorar la calidad de los existentes. 2. Colocar a
                        disposición del(la) <strong>FRANQUICIADO(A) </strong>las mercancías o
                        inventarios que componen su surtido, o, garantizar la puesta a disposición
                        del mismo <strong>FRANQUICIADO(A) </strong>de las mercancías cuando no sean
                        fabricadas por la <strong>FRANQUICIADOR</strong>. 3. Determinar las
                        políticas y estrategias de mercadeo en cuanto a posicionamiento, variables
                        del producto (marca, empaque, precio), variables de comunicación
                        (publicidad, promociones). 4. Otorgar formación, adiestramiento y
                        asistencia técnica al <strong>FRANQUICIADO(A) </strong>en los campos
                        técnicos, comercial, de educación, de marketing y de gestión en la
                        iniciación de la franquicia y durante la explotación de las unidades de
                        venta por el(la) <strong>FRANQUICIADO(A)</strong>. <strong>QUINTA.</strong>    <strong> </strong><strong>Obligaciones del(a) FRANQUICIADO(A).—</strong>1.
                        Comercializar los productos con las especificaciones impuestas por el    <strong>FRANQUICIANTE</strong>. 2. Comercializar los artículos producidos o
                        designados por <strong>LA</strong><strong> </strong>    <strong>FRANQUICIANTE</strong>. 3. No explotar directa o indirectamente la
                        franquicia por medio de establecimientos similares al de la franquicia. 4.
                        Utilizar durante la vigencia del contrato el <strong>Know How</strong> y
                        Licencia de Marca concedida por <strong>LA FRANQUICIANTE </strong>sólo para
                        la explotación de las franquicias. 5. No revelar el contenido del <strong>Know How</strong> suministrado por    <strong>LA FRANQUICIANTE</strong>. 6. Contribuir mediante el cumplimiento
                        de las directrices pactadas por <strong>LA FRANQUICIANTE</strong>, a la
                        buena reputación y éxito de la cadena. 7. Asistir a cursos de preparación,
                        retroalimentación o actualización suministrados por    <strong>LA FRANQUICIANTE</strong>. 8. Desplegar las actividades
                        administrativas y operativas necesarias para el desarrollo y la explotación
                        adecuada de cada una de las franquicias. 9. Informar a    <strong>LA FRANQUICIANTE </strong>sobre las mejoras en los procedimientos
                        técnicos y comercial empleados en la explotación de la franquicia. 10.
                        Aplicar los precios de venta definidos e impuestos por<strong>LA FRANQUICIANTE</strong>. 11. Utilizar los signos distintivos de    <strong>LA FRANQUICIANTE </strong>y mantener una política publicitaria y
                        promocionar adecuada a las necesidades de la franquicia. 12. Respetar las
                        normas de explotación y gestión pactadas, aceptando los controles de    <strong>LA FRANQUICIANTE </strong>establecidos en el contrato. 13.
                        Registrar las tiendas virtuales adquiridas en el sistema Software de    <strong>LA FRANQUICIANTE</strong>, resaltando que<strong> </strong>solo se
                        permite el uso y registro de una (01) tienda virtual en dicho sistema. 14.
                        El uso de la marca correrá por cuenta y riesgo del(la)    <strong>FRANQUICIADO(A)</strong>, quien responderá ante la Ley por las
                        infracciones de carácter civil, policiales, fiscales, tributarias, penales,
                        comerciales, administrativas y en general, e incluso con las multas
                        derivadas de los mismos durante el término del presente contrato y hasta
                        que cese el uso de la marca. El pago de impuestos, tasas, gravámenes,
                        creados o a punto de crearse, derivados de uso de la marca en tienda
                        virtual respectivo correrán a cargo del(la)    <strong>FRANQUICIADO(A)</strong>. <strong>PARÁGRAFO. </strong>

                    </div>
                </div>
                <div className="page">
                    <div className="subpage" ref={Page2Ref}>
                        <p>

                            Durante la vigencia del presente contrato, <strong>LA FRANQUICIANTE </strong>quedará
                            exonerada de cualquier manejo ejercido de forma irregular (incluyendo
                            publicidad engañosa) y contrario al ordenamiento jurídico por parte del(la)    <strong>FRANQUICIADO(A). </strong>15. Realizar un correcto y buen uso de
                            las redes sociales frente al tema y objeto de la marca    <strong>CRECEL </strong>y de sus productos establecidos en la cláusula
                            primera, impulsando y promocionando su objeto comercial. 16. Actuar con
                            honestidad y las buenas prácticas y costumbres comerciales respecto a la
                            entrega de material publicitario o canales de venta que    <strong>CRECEL </strong>ofrece y entrega para el Uso único y exclusivo de
                            los productos de<strong> CRECEL, </strong>es decir, no utilizar ni total ni
                            parcialmente las piezas publicitarias entregadas por    <strong>LA FRANQUICIANTE, </strong>con el propósito de promocionar por
                            parte del<strong> FRANQUICIADO(A) </strong>otros servicios o productos
                            publicitarios<strong>. </strong>17. Tratar confidencialmente toda la
                            información recibida directa o indirectamente por parte de    <strong>LA FRANQUICIANTE</strong>, y no utilizar ningún dato de esa
                            información de ninguna manera distinta al propósito y objeto del presente
                            contrato, en especial secretos comerciales, información técnica, sistemas
                            de computación y software, resultados de investigaciones.<strong> </strong>
                            Su incumplimiento dará lugar a la <strong><u>terminación unilateral del contrato</u></strong><strong> </strong>de forma inmediata de esta franquicia por parte de    <strong>LA FRANQUICIANTE, </strong>previo a la entrega de los medios de
                            prueba que demuestran la deshonestidad y mala práctica publicitaria.    <strong>SEXTA. Terminación y prórroga. — </strong>La duración del presente
                            contrato será de 01 año contados a partir de la suscripción de las firmas
                            de quienes en este intervienen y se renovará de forma automática por
                            periodos iguales al pactado inicialmente, siempre y cuando la(s) parte(s)
                            no lo de(n) por terminado con treinta (30) días de anticipación a la fecha
                            de su vencimiento, debiendo notificar a la parte dicha decisión mediante
                            cualquier medio válido que garantice su publicidad.    <strong>PARÁGRAFO PRIMERO. </strong>El contrato podrá ser prorrogado previo
                            acuerdo por las partes, que se hará constar por escrito o a través de otro
                            sí o documento adjunto al contrato principal, cuyo valor de dicha prórroga
                            anual será de <Input style={{ width: 80 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Valor' /> dólares (US$<Input style={{ width: 50 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Valor' />).    <strong>PARÁGRAFO SEGUNDO. </strong>En caso de terminación unilateral o
                            bilateral de este contrato, el(la) <strong>FRANQUICIADO(A) </strong>
                            renuncia a cualquier indemnización por clientela en el territorio pactado.<strong>SÉPTIMA. Cesión de derechos.—</strong>El(la)    <strong>FRANQUICIADO(A) </strong>no podrá ceder el contrato mucho menos
                            vender la franquicia.
                            <strong>
                                OCTAVA. Causales de terminación anticipada.—LA FRANQUICIANTE
                            </strong>
                            podrá en cualquier tiempo dar por terminado el contrato de manera
                            unilateral al <strong>FRANQUICIADO</strong>, informando con una antelación
                            de (01) mes en cualquiera de los siguientes eventos: <strong>a) </strong>
                            Insolvencia de <strong>LA FRANQUICIANTE</strong>; <strong>b). </strong>
                            Ineficiencia en la prestación de servicios de postventa por parte del    <strong>FRANQUICIADO</strong>, <strong>c)</strong>. Por ofrecer y vender un
                            producto o infoproducto por debajo del precio o valor sugerido por    <strong>LA FRANQUICIANTE</strong>, <strong>d). </strong>Violación e uso
                            inadecuado de las redes sociales que pongan en entre dicho el buen nombre y <strong>Know How</strong> acreditado en favor de <strong>“CRECEL”</strong>,    <strong>e). </strong>hacer uso indebido e inapropiado de la base de datos
                            de los franquiciados para proponer sus propios negocios y,<strong>f). </strong>creación de tiendas físicas para venta de productos de    <strong>“CRECEL”</strong>, <strong>g) </strong>Violación a la cláusula de
                            confidencialidad y,<strong> h) </strong>Violación a cualquiera de las
                            cláusulas del presente contrato. <strong>NOVENA. Cláusula Penal.—</strong>
                            Si cualquiera de las partes incumpliere una o cualquiera de las
                            obligaciones a su cargo, deberá pagar a la otra, la suma de    <strong><u>cuatrocientos dólares</u></strong> (US$ 400) a título de pena
                            derivada de dicho incumplimiento.    <strong>DÉCIMA. Cláusula compromisoria.—</strong>Toda controversia o
                            diferencia relativa a este contrato y a su ejecución, liquidación o
                            interpretación, se resolverá en un Centro de Conciliación debidamente
                            acreditado ante el Ministerio de Justicia y Derecho Colombiano.
                        </p>
                        <p>
                            Una vez leído, entendido y aprobado el presente contrato por quienes
                            participan en este, se firma en la ciudad de Medellín Antioquia y
                            <Input style={{ width: 190 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Ciudad' /> respectivamente, a los (<Input style={{ width: 40 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Día' />) días, del mes de
                            <Input style={{ width: 100 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Mes' />del año <Input style={{ width: 80 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Año' />
                        </p>
                        <Row>
                            <Col span={11}>
                                <br />
                                <img src='https://drophacking.blob.core.windows.net/documentos-internos/Firmas/firmaLinaP.png' />
                                ____________________________________<br />
                                <strong>LINA PATRICIA CALLE SALDARRIAGA</strong><br />
                                REPRESENTANTE LEGAL CRECEL SAS<br />
                                NIT: <strong>901.274.994-1</strong><br />
                                <strong>LA FRANQUICIANTE</strong><br />
                            </Col>
                            <Col span={13} style={{ textAlign: 'left' }}>
                                <br />
                                {/*<SignatureCanvas penColor='black' canvasProps={{width: 300, height: 90, className: showBorder ? 'red-border' : 'no-border'}} />*/}
                                <img src={sign} style={{ width: 300, height: 75 }} />
                                <Input
                                    id="originalFileName"
                                    type="file"
                                    required
                                    name="originalFileName"
                                    onChange={e => handleFileRead(e)}
                                    size="small"
                                    className={showBorder ? 'show-Content' : 'hide-content'}
                                    accept='image/*'
                                />                           ____________________________________<br />
                                <Input style={{ width: 250 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nombre' /><br />
                                CC. <Input style={{ width: 100 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Nº' /> expedida en <Input style={{ width: 150 }} className={showBorder ? 'red-border' : 'no-border'} placeholder='Ciudad' /><br />
                                <strong>EL(LA) FRANQUICIADO(A)</strong><br />
                            </Col>
                        </Row>

                    </div>
                </div>
                <Row>
                    <Col span={8}></Col>
                    <Col span={8}><Button className="generic-btn" onClick={() => downloadImage("Franquicia", props.id, 2)}>Generar y guardar documento</Button></Col>
                    <Col span={8}></Col>
                </Row>
                <br />
            </div>


        </div>
    )
}

export default FranquiciaContract;