import React from 'react';
import { IWarehouseDispatchReportProps } from './IWarehouseDispatchReportProps';

// Ant
import { Col, Row, Spin, Select, DatePicker, Alert, Button } from 'antd';

// DevExtreme
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, Grouping, GroupPanel, Lookup, Pager, Paging, SearchPanel, Selection } from 'devextreme-react/data-grid';

// Hooks
import useWarehouseDispatchReport from './Hooks/useWarehouseDispatchReport';
import ModalWarehouseDispatchReport from '../ModalWarehouseDispatchReport/ModalWarehouseDispatchReport';



const WarehouseDispatchReport  = (props:IWarehouseDispatchReportProps) => {

  const pageSizes = [10, 25, 50, 100];

  const {
    loading,
    onChangeDates,
    dispatchReport,
    setModalVisible,
    modalVisible,
    imprimirRuta
  } = useWarehouseDispatchReport();

  return (
    <div className='warehouseDispatchReport'>
      <Row gutter={[16,16]}>
        <Col span={6}>
          <DatePicker
            style={{ width: '100%', marginTop: 10 }}
            onChange={(e) => onChangeDates(e)}
          />
        </Col>
        <Col span={12}></Col>
        <Col span={2}>
          <Button onClick={()=>setModalVisible(true)}>Imprimir relación</Button>
        </Col>
      </Row>

      <Spin tip="Cargando..." spinning={loading}>
        {
          dispatchReport.length > 0 ?
            (

              <DataGrid
                dataSource={dispatchReport}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                showBorders={true}
              >
                <GroupPanel visible={true} />
                <Grouping autoExpandAll={true} />
                <FilterRow visible={true} />

                <Column dataField="guia" caption="Guia" dataType="string" />
                <Column dataField="billDate" caption="Fecha facturación" dataType="string" />
                <Column dataField="store" caption="Tienda" dataType="string" />
                <Column dataField="messenger" caption="Mensajero" dataType="string" />
                <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                <Paging defaultPageSize={20} />
              </DataGrid>
            )
            :
            (
              <Col style={{ marginTop: '10px' }}>
                <Alert
                  message="No hay datos para mostrar"
                  description="Seleccione una fecha"
                  type="warning"
                  showIcon
                />
              </Col>
            )
        }
      </Spin>
      { dispatchReport.length > 0 && 
        <ModalWarehouseDispatchReport 
          ModalVisible={modalVisible} 
          setModalVisible={setModalVisible}
          dispatchReport={dispatchReport}
          imprimirRuta={imprimirRuta}
        />
      }
    </div>
  );
};

export default WarehouseDispatchReport;
