import { Button, Col, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as React from 'react';
import { useEditBill } from '../../../Hooks/SuperAdmin/useEditBill';
import { IModalReturnBillProps } from './IModalReturnBillProps';
import './ModalReturnBill.css';

const ModalReturnBill = (props:IModalReturnBillProps) => {
  const {
    showModal,
    handleClose,
    isModalVisible,
    setCancelObservations,
    cancelObservations,
    ReturnBill,
    loading
  } = useEditBill();

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Devolver Guia
      </Button>
      <Modal 
        title="Devolvear Guia" 
        open={isModalVisible} 
        onOk={handleClose} 
        onCancel={handleClose}
        width={700}
        footer={[
          <Button key="back" onClick={() => handleClose()}>
            Cerrar
          </Button>
        ]}>
          <div style={{padding:20}}>
         Observaciones:
        <TextArea 
          rows={4} 
          name="description"
          onChange={(e:any)=>setCancelObservations(e.target.value)}
          value={cancelObservations}
        />
        <br /><br />
        <Row>
          <Col span={10}>
            <Button onClick={()=>ReturnBill(true,props.billId)} loading={loading}>aplicar el cobro del domicilio</Button>
          </Col>
          <Col span={14}>
            <Button type='primary' onClick={()=>ReturnBill(false,props.billId)} loading={loading}>Realizar devolución sin cobro de flete a la tienda</Button>
          </Col>
        </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ModalReturnBill;
