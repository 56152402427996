import { Button, Col, Row } from 'antd';
import './Differentiators.css';
import diferenciadores from '../../Assets/diferenciadores.png';
import drophooks from '../../Assets/drophooks.png';
import franquicia from '../../Assets/franquicia.png';



const Differentiators = () => {
    return (
 
        <div className='fondo2'>
            <Row style={{ paddingTop: '100px' }}></Row>
            <Row >
                <Col xs={1} sm={1} md={1} lg={1} xl={1} ></Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} className='textDifferentiators'>
                    <img src={diferenciadores} className='imgMaster' />
                </Col>
                <Col xs={22} sm={22} md={13} lg={13} xl={13} >
                    <Row >
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} ><img className='imgDroophooks' src={drophooks} /></Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} className='textMaster' > <div>
                            MASTER <b>TOOLS </b>
                        </div>
                            <div>
                                Productos que tienen el poder de tener un margen
                                grande de utilidad, producto que funciona y cumple
                                lo que promete, Inventario infinito ya que todos los
                                productos se producen en Colombia y Combos para
                                incrementar el ticket promedio de cada venta.</div>
                        </Col>
                    </Row><br />
                    <Row gutter={[16, 16]}>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} ><img className='imgDroophooks' src={drophooks} /></Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} ><img className='imgDroophooks' src={franquicia} /></Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8} ><img className='imgDroophooks' src={drophooks} /></Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={22} sm={22} md={7} lg={7} xl={7} className='textSmall' style={{background:'gray'}}>
                          <div style={{ fontSize:'24px'}}> DROP<b>HOOKS</b> </div> 
                            Técnicas 
                            persuasivas que
                            son capaz de
                            colocar a tu cliente
                            en modo compra
                            impulsiva.</Col>
                        <Col xs={22} sm={22} md={7} lg={7} xl={7} className='textSmall' style={{background:'#021435'}}>
                         <div  style={{ fontSize:'24px'}}>  FRANQUICIA <br /><b>CRECEL</b></div> 
                            La unica plataforma
                            que se enfoca en
                            lograr resultados de
                            sus usuarios.</Col>
                        <Col xs={22} sm={22} md={7} lg={7} xl={7} className='textSmall'  style={{background:'gray'}}>
                          <div  style={{ fontSize:'24px'}}>PAGES<b>BOX</b></div>  
                            Son páginas
                            construidas con el
                            copy boarding system
                            y garantizamos que
                            ya producen millones
                            en el mercado
                            colombiano.</Col>
                    </Row>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} ></Col>
            </Row>
        </div>
    )
}

export default Differentiators;

