import {
  Button,
  Col,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tabs,
  message
} from "antd";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Export,
  FilterRow,
  GroupPanel,
  Grouping,
  Pager,
  Paging,
  SearchPanel
} from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { API } from "../../API/axios";
import { useCategories } from "../../Hooks/useCategories";
import { email } from "../../Models/email.model";
import { storeModel } from "../../Models/store";
import Category from "../Category/Category";
import "./ApproveStore.css";
import { body } from "./BodyEmailApprove";
import { IApproveStoreProps } from "./IApproveStoreProps";
import { bodyReturn } from "./BodyEmailReturn";
import { Status } from "./Status";
import TableFreightStore from "../TableFreightStore/TableFreightStore";

const { TabPane } = Tabs;
const { Option } = Select;

const ApproveStore = (props: IApproveStoreProps) => {
  const pageSizes = [10, 25, 50, 100];

  const [storesList, setStoresList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [additionalInfoReturn, setAdditionalInfoReturn] = useState<string>("");
  const [modalAdditionalInfoReturn, setModalAdditionalInfoReturn] = useState<boolean>(false);
  const [actualStore, setActualStore] = useState<any>();


  const {
    categories,
  } = useCategories();

  useEffect(() => {
    if (localStorage.storeID !== "") {
      getStoreAndUserData();
    }
  }, []);

  const getStoreAndUserData = async () => {
    setLoading(true);
    const data = await API.get(`/Stores/GetStoreAndUsers`);
    const res = data.data.map((x: any) => {
      return {
        id: x.idStore,
        name: x.name,
        cel: x.cel,
        rut: x.rut,
        identification: x.identification,
        stateId: x.stateId,
        full_name: x.full_name,
        email: x.email,
        cedula1: x.cedula1,
        cedula2: x.cedula2,
        contracts: x.contracts,
        category: x.category
      };
    });
    setStoresList(res);
    setLoading(false);
  };

  const enviarEmailAprobado = (userId: number) => {
    if (localStorage.storeID !== "") {
      API.get(`/Users/GetUsersByStore/${userId}`).then((res) => {
        let email: email = {
          subject: "Documentos aprobados",
          mailTo: res.data[0].email,
          body: body(res.data[0].full_name),
        };
        API.post(`/Mail`, email);
      });
    }
  };

  const enviarEmailDevolucion = (userId: number, additionalInfo: string) => {
    if (localStorage.storeID !== "") {
      API.get(`/Users/GetUsersByStore/${userId}`).then((res) => {
        let email: email = {
          subject: "Documentos en estado de devolución",
          mailTo: res.data[0].email,
          body: bodyReturn(res.data[0].full_name, additionalInfo),
        };
        API.post(`/Mail`, email);
      });
    }
  };

  const docNoDisponible = () => {
    Modal.info({
      title: "Documento no disponible",
      content: "La tienda no ha cargado este documento",
      onOk() { },
    });
  };

  const getStoreDocuments = async (id: number, document: string) => {
    setLoading(true);
    let res = await API.get(`/StoreDocument/${id}`);
    if (res.status == 204) {
      docNoDisponible();
    }
    if (document === "CEDULA1") {
      if (!res.data.cedula1) {
        docNoDisponible();
      } else
        Modal.info({
          title: document,
          content: <img src={res.data.cedula1} style={{ width: "100%" }} alt='imt' />,
          onOk() { },
        });
    }

    if (document === "CEDULA2") {
      if (!res.data.cedula2) {
        docNoDisponible();
      } else
        Modal.info({
          title: document,
          content: <img src={res.data.cedula2} style={{ width: "100%" }} alt='imt' />,
          onOk() { },
        });
    }

    if (document === "RUT") {
      if (!res.data.rut) {
        docNoDisponible();
      } else window.open(res.data.rut);
    }

    if (document === "CONTRATO") {
      if (!res.data.contrato) {
        docNoDisponible();
      } else window.open(res.data.contrato);
    }
    setLoading(false);
  };

  const aprobarTienda = (x: any, status: number, additionalInfo: string) => {
    let el: storeModel = {
      id: x.id,
      name: x.name,
      adress: x.adress,
      city: x.city,
      phone: x.phone,
      cel: x.cel,
      rut: x.rut,
      identification: x.identification,
      identification_type: x.identification_type,
      logo: x.logo,
      stateId: status,
      pixel_1: x.pixel_1,
      instagram: x.instagram,
      facebook: x.facebook,
      youtube: x.youtube,
      landing: x.landing,
      landingPlan: x.landingPlan,
      created_at: x.created_at,
      updated_at: x.updated_at,
      url: x.url,
      txt: x.txt,
      checked: x.checked,
      contractStores: x.contractStores
    };
    API.put(`/Stores/${x.id}`, el).then(() => {
      message.success("Los datos fueron guardados");
      getStoreAndUserData();
      setModalAdditionalInfoReturn(false);
      setAdditionalInfoReturn("");
      if (status === 2) {
        enviarEmailAprobado(x.id);
      } else {
        enviarEmailDevolucion(x.id, additionalInfo);

      }
    });
  };

  const rechazarTienda = (x: any) => {
    API.get(`/Stores/RejectStore?storeId=${x.id}`).then(() => {
      message.success("La tienda fue rechazada");
      getStoreAndUserData();
    });
  };


  const approveStore = (data: any) => {
    return (
      <Popconfirm
        placement="topRight"
        title={"¿Desea aprobar los documentos de esta tienda?"}
        onConfirm={() => aprobarTienda(data.data, Status.Aprobado, "")}
        okText="Sí"
        cancelText="No"
      >
        <Button>Aprobar</Button>
      </Popconfirm>
    );
  };

  const returnStore = (data: any) => {
    return (
      <Popconfirm
        placement="topRight"
        title={"¿Desea devolver los documentos de esta tienda?"}
        onConfirm={() => {
          setModalAdditionalInfoReturn(true)
          setActualStore(data.data)
        }
        }
        okText="Sí"
        cancelText="No"
      >
        <Button>Devolver</Button>
      </Popconfirm>
    );
  };

  const setCategory = (row: any) => {
    return (
      <Select style={{ width: '100%' }} onChange={x => updateStoreCategory(row.data.id, x)}>
        {categories.map((x: any) => (
          <Option value={x.id}>{x.name}</Option>
        ))}
      </Select>
    );
  }

  const updateStoreCategory = async (storeId: number, categoryId: number) => {
    await API.get(`/Category/CreateStoreCategory?storeId=${storeId}&categoryId=${categoryId}`);
    message.success("La categoría de la tienda fue actualizada");
  }

  const RejectStore = (data: any) => {
    return (
      <Popconfirm
        placement="topRight"
        title={"¿Desea Rechazar esta tienda?"}
        onConfirm={() => rechazarTienda(data.data)}
        okText="Sí"
        cancelText="No"
      >
        <Button>Rechazar</Button>
      </Popconfirm>
    );
  };

  const returnContracts = (data: any) => {
    return (
      <Popconfirm
        placement="topRight"
        title={"¿Desea rechazar los contratos de esta tienda?"}
        onConfirm={() => rechazarContratos(data.data.contracts)}
        okText="Sí"
        cancelText="No"
      >
        <Button>Rechazar</Button>
      </Popconfirm>
    );

  }

  const approveContracts = (data: any) => {
    return (
      <Popconfirm
        placement="topRight"
        title={"¿Desea aprobar los contratos de esta tienda?"}
        onConfirm={() => aprobarContratos(data.data.contracts)}
        okText="Sí"
        cancelText="No"
      >
        <Button>Aprobar</Button>
      </Popconfirm>
    );

  }

  const rechazarContratos = async (data: any) => {
    await API.put(`/Contract/reject`, data).then(() => {
      message.success("Contratos rechazado");
      getStoreAndUserData();
    });
  }

  const aprobarContratos = async (data: any) => {
    await API.put(`/Contract/approve`, data).then(() => {
      message.success("Contratos aprobados");
      getStoreAndUserData();
    });
  }

  return (
    <div>
      <Modal
        open={modalAdditionalInfoReturn}
        title="Envia un mensaje para esta devolución de documentos"
        okText="Create"
        cancelText="Cancel"
        onCancel={() => setModalAdditionalInfoReturn(false)}
      >
        <div className="modal-return">
          <Input type="textarea" placeholder="Ingresa un mensaje" onChange={(msj) => {
            setAdditionalInfoReturn(msj.target.value)
          }} ></Input>
          <Button disabled={additionalInfoReturn == ""} onClick={() => aprobarTienda(actualStore, Status["Pendiente Actualización"], additionalInfoReturn)} >Devolver</Button>
        </div>
      </Modal>
      <Row>
        <Col span={2}></Col>
        <Col span={20}>
          <div className={`approveStore`}>
            <PageHeader
              className="site-page-header"
              onBack={() => (window.location.href = "/StoreHome")}
              title="Aprobar Tiendas"
              subTitle=""
            />
            <Tabs defaultActiveKey="1" type="card">
              <TabPane tab="Pendientes por aprobar" key="1">
                <Spin tip="Cargando..." spinning={loading}>
                  <DataGrid
                    dataSource={storesList.filter((x: any) => x.stateId === 1)}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                  >
                    <Export enabled={true} allowExportSelectedData={false} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Grouping autoExpandAll={true} />
                    <FilterRow visible={true} />

                    <Column
                      dataField="name"
                      caption="Nombre Tienda"
                      dataType="string"
                    />
                    <Column
                      dataField="full_name"
                      caption="Nombre Admin"
                      dataType="string"
                    />
                    <Column
                      dataField="identification"
                      caption="Cedula"
                      dataType="string"
                    />
                    <Column dataField="cel" caption="Celular" dataType="string" />
                    <Column
                      dataField="email"
                      caption="Correo"
                      dataType="string"
                    />
                    <Column
                      dataField="rut"
                      alignment="center"
                      caption="Rut"
                      visible={(x: any) => x.row.data.rut != null}
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Rut",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "RUT");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="cedula1"
                      alignment="center"
                      caption="Cedula frontal"
                      visible={(x: any) => x.row.data.cedula1 != null}
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Cedula",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "CEDULA1");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="cedula2"
                      alignment="center"
                      caption="Cedula posterior"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Cedula",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "CEDULA2");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="category"
                      cellRender={setCategory}
                      alignment="center"
                      caption="Categoría"
                      allowSorting={false}
                    />
                    <Column
                      dataField="idStore1"
                      cellRender={approveStore}
                      alignment="center"
                      caption="Aprobar"
                      allowSorting={false}
                    />
                    <Column
                      dataField="idStore2"
                      cellRender={returnStore}
                      alignment="center"
                      caption="Devolver"
                      allowSorting={false}
                    />
                    <Column
                      dataField="idStore3"
                      cellRender={RejectStore}
                      alignment="center"
                      caption="Rechazar"
                      allowSorting={false}
                    />
                    <Pager
                      allowedPageSizes={pageSizes}
                      showPageSizeSelector={true}
                    />
                    <Paging defaultPageSize={20} />
                  </DataGrid>
                </Spin>
              </TabPane>
              <TabPane tab="Actualizaciones" key="2">
                <Spin tip="Cargando..." spinning={loading}>
                  <DataGrid
                    dataSource={storesList.filter((x: any) => x.stateId === Status["En Actualización"])}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                  >
                    <Export enabled={true} allowExportSelectedData={false} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Grouping autoExpandAll={true} />
                    <FilterRow visible={true} />

                    <Column
                      dataField="name"
                      caption="Nombre Tienda"
                      dataType="string"
                    />
                    <Column
                      dataField="full_name"
                      caption="Nombre Admin"
                      dataType="string"
                    />
                    <Column
                      dataField="identification"
                      caption="Cedula"
                      dataType="string"
                    />
                    <Column dataField="cel" caption="Celular" dataType="string" />
                    <Column
                      dataField="email"
                      caption="Correo"
                      dataType="string"
                    />
                    <Column
                      dataField="rut"
                      alignment="center"
                      caption="Rut"
                      visible={(x: any) => x.row.data.rut != null}
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Rut",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "RUT");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="cedula1"
                      alignment="center"
                      caption="Cedula frontal"
                      visible={(x: any) => x.row.data.cedula1 != null}
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Cedula",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "CEDULA1");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="cedula2"
                      alignment="center"
                      caption="Cedula posterior"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Cedula",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "CEDULA2");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="idStore"
                      cellRender={approveStore}
                      alignment="center"
                      caption="Aprobar"
                      allowSorting={false}
                    />
                    <Column
                      dataField="idStore"
                      cellRender={returnStore}
                      alignment="center"
                      caption="Devolver"
                      allowSorting={false}
                    />
                    <Pager
                      allowedPageSizes={pageSizes}
                      showPageSizeSelector={true}
                    />
                    <Paging defaultPageSize={20} />
                  </DataGrid>
                </Spin>
              </TabPane>

              <TabPane tab="Todas las tiendas" key="3">
                <Spin tip="Cargando..." spinning={loading}>
                  <DataGrid
                    dataSource={storesList}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                  >
                    <Export enabled={true} allowExportSelectedData={false} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Grouping autoExpandAll={true} />
                    <FilterRow visible={true} />

                    <Column
                      dataField="stateId"
                      caption="Estado"
                      dataType="number"
                      format={(x: number) => Object.values(Status)[x - 1]}

                    />

                    <Column
                      dataField="name"
                      caption="Nombre Tienda"
                      dataType="string"
                    />
                    <Column
                      dataField="full_name"
                      caption="Nombre Admin"
                      dataType="string"
                    />
                    <Column
                      dataField="identification"
                      caption="Cedula"
                      dataType="string"
                    />
                    <Column dataField="cel" caption="Celular" dataType="string" />
                    <Column
                      dataField="email"
                      caption="Correo"
                      dataType="string"
                    />
                    <Column
                      dataField="category"
                      caption="Categoria(s)"
                      dataType="string"
                    />
                    <Column
                      dataField="rut"
                      alignment="center"
                      caption="Rut"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Rut",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "RUT");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="cedula1"
                      alignment="center"
                      caption="Cedula frontal"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Cédula",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "CEDULA1");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="cedula2"
                      alignment="center"
                      caption="Cedula posterior"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Cédula",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "CEDULA2");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="contracts"
                      alignment="center"
                      caption="Contratos"
                      type="buttons"
                      buttons={[
                        {
                          text: "Franquicia",
                          hindt: "Franquicia",

                          icon: "find",
                          onClick: (x: any) => {
                            if (!x.row.data.contracts.find((x: { title: string; }) => x.title == "Franquicia CRECEL")) {
                              docNoDisponible();
                            } else window.open(x.row.data.contracts.find((x: { title: string; }) => x.title == "Franquicia CRECEL").urlDocument);
                          }
                        },
                        {
                          text: "DropHackingMind",
                          hindt: "DropHackingMind",
                          icon: "find",
                          onClick: (x: any) => {
                            if (!x.row.data.contracts.find((x: { title: string; }) => x.title == "DropHaking Mind")) {
                              docNoDisponible();
                            } else window.open(x.row.data.contracts.find((x: { title: string; }) => x.title == "DropHaking Mind").urlDocument);

                          }
                        }
                      ]}
                    />
                    <Pager
                      allowedPageSizes={pageSizes}
                      showPageSizeSelector={true}
                    />
                    <Paging defaultPageSize={20} />
                  </DataGrid>
                </Spin>
              </TabPane>
              <TabPane tab="Contratos firmados" key="4">
                <Spin tip="Cargando..." spinning={loading}>
                  <DataGrid
                    dataSource={storesList.filter((x: any) => x.contracts?.find((x: any) => x.status == 1))}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                  >
                    <Export enabled={true} allowExportSelectedData={false} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Grouping autoExpandAll={true} />
                    <FilterRow visible={true} />

                    <Column
                      dataField="name"
                      caption="Nombre Tienda"
                      dataType="string"
                    />
                    <Column
                      dataField="full_name"
                      caption="Nombre Admin"
                      dataType="string"
                    />
                    <Column
                      dataField="identification"
                      caption="Cedula"
                      dataType="string"
                    />
                    <Column dataField="cel" caption="Celular" dataType="string" />
                    <Column
                      dataField="email"
                      caption="Correo"
                      dataType="string"
                    />
                    <Column
                      dataField="rut"
                      alignment="center"
                      caption="Rut"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Rut",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "RUT");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="cedula1"
                      alignment="center"
                      caption="Cedula frontal"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Cédula",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row.data.id, "CEDULA1");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="cedula2"
                      alignment="center"
                      caption="Cedula posterior"
                      type="buttons"
                      buttons={[
                        {
                          text: "ver",
                          hint: "Ver Cédula",
                          icon: "find",
                          onClick: (x: any) => {
                            getStoreDocuments(x.row, "CEDULA2");
                          },
                        },
                      ]}
                    />
                    <Column
                      dataField="contracts"
                      alignment="center"
                      caption="Contratos"
                      type="buttons"
                      buttons={[
                        {
                          text: "Franquicia",
                          hindt: "Franquicia",
                          icon: "find",
                          onClick: (x: any) => {
                            if (!x.row.data.contracts.find((x: { title: string; }) => x.title == "Franquicia CRECEL")) {
                              docNoDisponible();
                            } else window.open(x.row.data.contracts.find((x: { title: string; }) => x.title == "Franquicia CRECEL").urlDocument);

                          }
                        },
                        {
                          text: "DropHackingMind",
                          hindt: "DropHackingMind",
                          icon: "find",
                          onClick: (x: any) => {
                            if (!x.row.data.contracts.find((x: { title: string; }) => x.title == "DropHaking Mind")) {
                              docNoDisponible();
                            } else window.open(x.row.data.contracts.find((x: { title: string; }) => x.title == "DropHaking Mind").urlDocument);

                          }
                        }
                      ]}
                    />
                    <Column
                      dataField="idStore"
                      cellRender={approveContracts}
                      alignment="center"
                      caption="Aprobar contratos"
                      allowSorting={false}
                    />
                    <Column
                      dataField="idStore"
                      cellRender={returnContracts}
                      alignment="center"
                      caption="Rechazar Contratos"
                      allowSorting={false}
                    />
                    <Pager
                      allowedPageSizes={pageSizes}
                      showPageSizeSelector={true}
                    />
                    <Paging defaultPageSize={20} />
                  </DataGrid>
                </Spin>
              </TabPane>
              <TabPane tab="Categorías" key="5">
                <Category />
              </TabPane>
              <TabPane tab="Configuración Fletes" key="6">
                <TableFreightStore></TableFreightStore>
              </TabPane>


            </Tabs>
          </div>
        </Col>
      </Row>

    </div>


  );
};

export default ApproveStore;
