import { EditOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { API } from "../API/axios";
import { CityModel } from "../Models/city.model";
import { Provinces } from "../Models/Provinces";

export const useCities = () => {
  const initialData: CityModel = {
    id: 0,
    code: "",
    name: "",
    provinceId: 0,
    isActived: true,
    message:"",
    provinceCode: 0,
    provinceName: "",
  };

  const [provinces, setProvinces] = useState<Provinces[]>([]);
  const [cities, setCities] = useState();
  const [city, setCity] = useState<CityModel>(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProvinceId, setSelectedProvinceId] = useState<number>(0);

  useEffect(() => {
    getProvinces();
  }, []);

  const getProvinces = async () => {
    setLoading(true);
    let response: any = await API.get("/Cities/GetProvincesWeb?idCountry=1");
    setProvinces(response.data);
    setLoading(false);
  };

  const getCities = async (provinceId: number) => {
    setSelectedProvinceId(provinceId);
    setLoading(true);
    let response: any = await API.get(
      `/Cities/GetCities?idProvince=${provinceId}`
    );
    setCities(response.data);
    setLoading(false);
  };

  const cellRender = (cellInfo: any) => {
    return (
      <EditOutlined
        onClick={() => {
          setCity(cellInfo.data);
        }}
      />
    );
  };

  const Savecity = async (row:any) => {
    setLoading(true);
    let data = row;
    data.provinceId = selectedProvinceId;
    if(data.id.length > 4)
    {
        data.id = 0;
        data.isActived = true;
    }
    let response: any = await API.post("/Cities/PostCity", data);
    if (response.status == 200) {
      setLoading(false);
      notification.success({ message: "ciudad guardada" });
      getCities(selectedProvinceId);
    }
  };

  const updateCity = (e: any) => {
    const { name, value, checked, type } = e.target;
    setCity({ ...city, [name]: type == "checkbox" ? checked : value });
  };

  return {
    provinces,
    loading,
    cities,
    cellRender,
    getCities,
    Savecity,
    city,
    updateCity,
  };
};
