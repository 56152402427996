import { Col, Row, Button } from "antd";
import { PreBill } from "../../Models/Bill";
import { DataGrid } from "devextreme-react";
import { Column, ColumnFixing, Editing, Export, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Sorting } from "devextreme-react/data-grid";

interface IPreBills {
    data: PreBill[]
}
const TablePreBill = (PreBills: IPreBills) => {

    const approveBill = (data: any) => {
        return (
            <Button>Aprobar</Button>
        );
    };
    const rejectBill = (data: any) => {
        return (
            <Button>Rechazar</Button>
        );
    };

    function handleUpdateComission(data: any) {
        let newCommission = {
            commissionValue: data.commissionValue,
            commissionValueUSD: data.commissionValueUSD,
            mentorStoresId: data.mentorStoresProducts.mentorStoresId,
            store_ProductId: data.mentorStoresProducts.store_ProductId
        };
        console.log(newCommission);
    }

    return (
        <div>
            {PreBills.data.length > 0 && (<Row gutter={[16, 16]}>
                <Col style={{ padding: 10, backgroundColor: "white" }}>
                    <DataGrid
                        id="preBills"
                        dataSource={PreBills.data}
                        showBorders={true}
                        remoteOperations={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        showRowLines={true}
                        onRowUpdated={(value) => handleUpdateComission(value.data)}

                    >
                        <Editing
                            mode="cell"
                            allowUpdating={false}
                        />
                        <HeaderFilter visible={true} />
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <GroupPanel visible={false} />
                        <ColumnFixing enabled={true} />
                        <FilterRow visible={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />
                        <Pager
                            showInfo={true}
                            showNavigationButtons={true}
                            showPageSizeSelector={true}
                            visible={true}
                        />
                        <Column
                            allowEditing={false}
                            dataField="clientName"
                            caption="Cliente"
                            alignment="center"
                        />
                        <Column
                            allowEditing={false}
                            dataField="clientNumber"
                            caption="Número"
                            alignment="center"
                        />

                        <Column
                            dataField="createAt"
                            caption="Fecha"
                            alignment="center"
                        />
                        <Column
                            dataField="description"
                            caption="Poductos"
                            alignment="center"
                        />

                        <Column
                            dataField="id"
                            cellRender={approveBill}
                            alignment="center"
                            caption="Aprobar"
                            allowSorting={false}
                        />
                        <Column
                            dataField="id"
                            cellRender={rejectBill}
                            alignment="center"
                            caption="Rechazar"
                            allowSorting={false}
                        />
                    </DataGrid>
                </Col>
            </Row>)
            }
        </div>

    );
}

export default TablePreBill;

