import { Button, Col, Modal, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { API } from '../../API/axios';
import { bank, store_bank } from '../../Models/bank.model';
import { IModalStoreBankDataProps } from './IModalStoreBankDataProps';
import './ModalStoreBankData.css';

const ModalStoreBankData = (props:IModalStoreBankDataProps) => {

  const [bankData, setBankData] = useState<store_bank>();
  const [storeBank, setStoreBank] = useState("");

  useEffect(() => {
    if ( localStorage.storeID !== null ) {
      getBankData();
    }
  }, []);

  const getBankData = () => {
    if (localStorage.storeID !== null ) {
      API.get(`/Store_Banks/${props.storeId}`).then(res => {
        setBankData(res.data)
        API.get<bank[]>(`/Banks`).then(resp => {
          let banco = resp.data.filter(x => x.id === res.data.bankId);
          if (banco.length > 0) {
            setStoreBank(banco[0].name);
          }
        });
      });
    }
  }

  return (
    <div className={`modalStoreBankData`}>
      <Row>
        <Col span={12}>
          <b>Titular de la cuenta</b><br />
          {bankData?.owner_account}
        </Col>
        <Col span={12}>
          <b>Nombre de la tienda</b><br />
          {props.storeName}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <b>Número de cuenta</b><br />
          {bankData?.bank_account}
        </Col>
        <Col span={12}>
          <b>Banco</b><br />
          {storeBank}
        </Col>
      </Row>
    </div>
  );
};

export default ModalStoreBankData;
