import React, { useState, useEffect } from 'react';
import { IBankRegistrationProps } from './IBankRegistrationProps';
import './BankRegistration.css';
import { Row, Col, Input, Select, Button, message } from 'antd';
import utils from '../../../utils';
import { bank, store_bank } from '../../../Models/bank.model';
import { API } from '../../../API/axios';
const { Option } = Select;

const BankRegistration = (props:IBankRegistrationProps) => {

  const [listBanksFiltered, setListBanksFiltered] = useState<Array<bank>>([]);
  const [listBanks, setListBanks] = useState<Array<bank>>([]);

  useEffect(() => {
    getBanks();
  }, []);

  const getBanks = async () => {
    let res = await API.get<bank[]>(`/Banks`);
      setListBanks(utils.sortArray(res.data, 'name'));
      setListBanksFiltered(utils.sortArray(res.data, 'name'));
  }

  const handleSearchBank = (value: string) => {
    if (value) {
      setListBanksFiltered(listBanks.filter((x: bank) => x.name.toLowerCase().includes(value)));
    } else {
      setListBanksFiltered([])
    }
  }

  const updateState = (value: any, prop: string) => {
    let arrayAux = { ...props.bank as any }
    arrayAux[prop] = value;
    props.setBank(arrayAux);
  }

  const bankRegister = () => {
    let bankTemp: store_bank = props.bank;
    if (bankTemp !== undefined) {
      if (bankTemp.bankId !== 0 &&
        bankTemp.account_type !== "" &&
        bankTemp.bank_account !== "" &&
        bankTemp.owner_account !== "" &&
        bankTemp.owner_identification !== "") {
        props.saveRegistration();
      }
      else {
        message.warn('Debe llenar todos los datos de la cuanta bancaria')
      }
    }
    else {
      message.warn('Debe llenar todos los datos de la cuenta bancaria')
    }
  }

  return (
    <div className={`bankRegistration`}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Select
            style={{ width: '100%' }}
            showSearch
            placeholder="Seleccione el Banco"
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={true}
            onSearch={handleSearchBank}
            onChange={(e) => updateState(e, 'bankId')}
            notFoundContent={null}
          >
            {listBanksFiltered.map((x: bank) => <Option key={x.id} value={x.id}>{x.name}</Option>)}
          </Select>
        </Col>
        {props.bank.bankId != 19 &&
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Select defaultValue="Tipo Cuenta" style={{ width: '100%' }} onChange={(e) => updateState(e, 'account_type')}>
              <Option key='cuentaAhorros' value={'Cuenta de ahorros'}>Cuenta de ahorros</Option>
              <Option key='cuentaCorriente' value={'Cuenta de corriente'}>Cuenta de corriente</Option> 
            </Select>
          </Col>
        }
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            placeholder={props.bank.bankId == 19 ? "Correo de PayPal" : "Número de cuenta"}
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-tipo-de-banco.png"
                className="form-icon"
                alt='icono de tipo de banco'
              />
            }
            onChange={(e) => updateState(e.target.value, 'bank_account')}
            className="input"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            placeholder="Nombre del titular de la cuenta"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-titular-de-la-cuenta.png"
                className="form-icon"
                alt='icono de titular de la cuenta'
              />
            }
            onChange={(e) => updateState(e.target.value, 'owner_account')}
            className="input"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            placeholder="Número de Cédula"
            prefix={
              <img
                src="https://drophacking.blob.core.windows.net/images/Registro/icono-de-identificación.png"
                className="form-icon"
                alt='icono de identificación'
              />
            }
            onChange={(e) => updateState(e.target.value, 'owner_identification')}
            className="input"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button className={'generic-btn'} type="primary" loading={props.loading} onClick={() => bankRegister()}>Registrar Tienda</Button>
        </Col>
      </Row>
    </div>
  );
};

export default BankRegistration;
