import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { API } from '../../../API/axios';
import { user } from '../../../Models/User.Model';
import { ISelectCrecelCourierProps } from './ISelectCrecelCourierProps';
const { Option } = Select;

const SelectCrecelCourier = (props:ISelectCrecelCourierProps) => {

  const [couriersList, setCouriersList] = useState<user[]>([]);

  useEffect(() => {
    getCrecelCouriers();
  }, []);

  const getCrecelCouriers = () => {
    API.get<user[]>('/Users/GetCrecelCouriers').then(res=>{
      setCouriersList(res.data);
    })
  }

  return (
    <div>
      <Select
        style={{ width: '100%' }}
        placeholder={"Mensajero"}
        onChange={(e)=>props.onChangeCrecelCourier(e)}
        value={props.value}
      >
        {couriersList.map(x=><Option key={x.id} value={x.id}>{x.full_name}</Option>)}
      </Select>
    </div>
  );
};

export default SelectCrecelCourier;
