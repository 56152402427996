import { Button, Col, Popconfirm, Row, Select } from "antd";
import { DataGrid } from "devextreme-react";
import FormItem from "antd/lib/form/FormItem";
import { Column, ColumnFixing, Editing, Export, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Sorting } from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { loadStores } from "../Settings/useSettingsHook";
import { RequestFreight, deleteWithFreight, loadCities, putFreight } from "./ServiceFreightStore";


const TableFreightStore = () => {

    const { Option } = Select;
    const [stores, setStores] = useState<any[]>();
    const [cities, setCities] = useState<any[]>();
    const [actualStore, setActualStore] = useState<any>();


    useEffect(() => {
        loadStores().then((stores: any) => {
            setStores(stores);
        })
    }, []);

    function getCities(storeid: number) {
        loadCities(storeid).then((cities: any) => {
            setCities(cities);

        })
    }
    async function handleUpdateComission(data: any) {
        let requestFreight: RequestFreight = {
            storeId: actualStore.id,
            cityId: data.id,
            collectCommission: data.storesCitiesFreight.collectCommission,
            serviceCommission: data.storesCitiesFreight.serviceCommission,
            deliveryFreight: data.storesCitiesFreight.deliveryFreight,
            crecelUtily: data.storesCitiesFreight.crecelUtily
        }
        await putFreight(requestFreight);
        getCities(actualStore.id);
    }

    async function loadCitiesWithStore(storeName: string) {
        let store = await stores?.find(x => x.name === storeName);
        getCities(store.id)
        setActualStore(store);

    }

    const deleteConfig = (data: any) => {

        return (
            <Popconfirm
                placement="topRight"
                title={"¿Desea eliminar la configuración?"}
                onConfirm={() => sendDeleteConf(data.data.storesCitiesFreight)}
                okText="Sí"
                cancelText="No"
            >
                {data.data.storesCitiesFreight &&(<Button>Eliminar</Button>)}
            </Popconfirm>
        );
    }

    async function sendDeleteConf(data: any) {
        await deleteWithFreight(data.id);
        getCities(actualStore.id);
    }

    return (
        <div>

            {stores && (<FormItem
                label="Seleciona una tienda"
                name="stores"
            >
                <Select
                    showSearch
                    placeholder="Selecciona Tiendas"
                    style={{ width: "100%" }}
                    onChange={x => loadCitiesWithStore(x)}
                >
                    {stores.map((x: any) => (
                        <Option key={x.id} value={x.name}>
                            {x.name}
                        </Option>
                    ))}
                </Select>
            </FormItem>)}

            {(<Row gutter={[16, 16]}>
                <Col span={24} style={{ padding: 10, backgroundColor: "white" }}>
                    <DataGrid
                        id="cities"
                        dataSource={cities}
                        showBorders={true}
                        remoteOperations={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        showRowLines={true}
                        onRowUpdated={(value) => handleUpdateComission(value.data)}
                    >
                        <HeaderFilter visible={true} />
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <GroupPanel visible={false} />
                        <ColumnFixing enabled={true} />
                        <FilterRow visible={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />
                        <Pager
                            showInfo={true}
                            showNavigationButtons={true}
                            showPageSizeSelector={true}
                            visible={true}
                        />

                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                        <Column

                            dataField="name"
                            caption="Ciudad destino"
                            alignment="center"
                        />
                        <Column
                            allowEditing={true}
                            type='number'
                            dataField="storesCitiesFreight.collectCommission"
                            caption="Comision Recaudo"
                            alignment="center" />
                        <Column
                            allowEditing={true}
                            type='number'
                            dataField="storesCitiesFreight.serviceCommission"
                            caption="Comision Servicio"
                            alignment="center" />
                        <Column
                            allowEditing={true}
                            type='number'
                            dataField="storesCitiesFreight.deliveryFreight"
                            caption="Flete Mensajero"
                            alignment="center" />
                        <Column
                            allowEditing={true}
                            type='number'
                            dataField="storesCitiesFreight.crecelUtily"
                            caption="Utilidad Crecel"
                            alignment="center" />
                        <Column
                            allowEditing={false}
                            type='number'
                            dataField="storesCitiesFreight.totalFreight"
                            caption="Flete Total"
                            alignment="center" />

                        {<Column
                            cellRender={deleteConfig}
                            alignment="center"
                            caption="Eliminar configuración"
                            allowSorting={false}
                        />}

                    </DataGrid>
                </Col>
            </Row>)
            }
        </div>
    );

}

export default TableFreightStore;