import { useState, useEffect } from 'react';
import { API } from '../../../API/axios';
import { bank, store_bank } from '../../../Models/bank.model';

export const useModalStoreBank = (storeId: number) => {

  const [bankData, setBankData] = useState<store_bank>();
  const [storeBank, setStoreBank] = useState("");

  useEffect(() => {
    if ( localStorage.storeID !== null ) {
      getBankData();
    }
  }, []);

  useEffect(() => {
    if ( localStorage.storeID !== null ) {
      getBank();
    }
  })

  const getBankData = () => {
    if ( localStorage.storeID !== null ) {
      API.get(`/Store_Banks/${storeId}`).then(res => {
        setBankData(res.data)
      });
    }
  }

  const getBank = () => {
    API.get<bank[]>(`/Banks`).then(resp => {
      let banco = resp.data.filter(x => x.id === bankData?.bankId);
      if (banco.length > 0) {
        setStoreBank(banco[0].name);
      }
    });
  }

  return {
    bankData,
    storeBank
  }
}
