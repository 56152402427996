import { message } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { API } from '../../../API/axios';
import { PageContext } from '../../../Hooks/ContextProvider';
import { product } from '../../../Models/Products';


const useCopysLibrary = () => {

    const [imagesCopy, setImagesCopy] = useState<any>([]);
    const [products, setProducts] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const { userData } = useContext(PageContext);


    const getCopyProducts = async () => {
        setLoading(true);
        try {
            let imageCopysProducts = await API.get<product[]>(`/Products`);
            let imageCopysActive: product[] = imageCopysProducts.data;
            if (localStorage.currency == 'COP') {
                imageCopysActive = imageCopysProducts.data.filter(x => x.isActive);
            }
            else {
                imageCopysActive = imageCopysProducts.data.filter(x => x.isActiveEcuador);
            }
            let imageCopysProductsFilter = imageCopysActive.filter(x => x.testimonials !== '');
            if (userData?.roles.includes("Admin") || userData?.roles.includes("SuperAdmin")) {
                let arrayProducts = imageCopysProducts.data.map(x => {
                    return {
                        id: x.id,
                        testimonials: x.testimonials,
                        folderInformation: x.promotional_Images,
                        name: x.name
                    }
                });
                setImagesCopy(arrayProducts);
                setProducts(arrayProducts);
            } else {
                let arrayProducts = imageCopysProductsFilter.map(x => {
                    return {
                        id: x.id,
                        testimonials: x.testimonials,
                        folderInformation: x.promotional_Images,
                        name: x.name
                    }
                });
                setImagesCopy(arrayProducts);
                setProducts(arrayProducts);
            }
        } catch (error) {
            message.error(`No se cargaron las imagenes. ${error}`);
        }
        setLoading(false);
    }

    const onSearchFilteredProduct = (value: string) => {
        if (value) {
            setImagesCopy(products.filter((x: any) => x.name?.toLowerCase().includes(value)));
        } else {
            setImagesCopy(products)
        }
    }

    useEffect(() => {
        getCopyProducts();
    }, [])

    return {
        imagesCopy,
        loading,
        getCopyProducts,
        onSearchFilteredProduct
    }
}

export default useCopysLibrary
