import { Button, Col, Input, InputNumber, message, Modal, Row } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { API } from '../../API/axios';
import { PageContext } from '../../Hooks/ContextProvider';
import { updateBill } from '../../Hooks/SuperAdmin/useEditBill';
import { BillData } from '../../Models/Bill';
import ModalBtnDevolver from '../ModalBtnDevolver/ModalBtnDevolver';
import ModalCreateDeliveryNews from '../ModalCreateDeliveryNews/ModalCreateDeliveryNews';
import { IModalUpdateBillDataProps } from './IModalUpdateBillDataProps';
import './ModalUpdateBillData.css';

const ModalUpdateBillData = (props: IModalUpdateBillDataProps) => {

  const [billData, setbillData]: any = useState<BillData>();
  const [loading, setLoading] = useState<boolean>(false);
  const { userData } = useContext(PageContext);


  useEffect(() => {
    GetDeliveryData(props.guia)
  }, [props.guia]);

  const GetDeliveryData = (guia: string) => {

    if (guia === '') {
      return;
    }
    API.get(`/Bills/GetBillByGuia/${guia}`).then(res => {
      if (res.data !== "") {
        setbillData(res.data);
      }
    })
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setbillData((prevState: BillData) => ({
      ...prevState,
      [name]: value
    }));
  };

  const reasignarGuia = () => {
    billData.crecelDeliveryId = 1;
    billData.status = "Pendiente";
    setbillData((prevState: BillData) => ({
      ...prevState,
      crecelDeliveryId: 1
    }));
    setbillData((prevState: BillData) => ({
      ...prevState,
      status: "Pendiente"
    }));
    saveData();
  }

  const saveData = () => {
    setLoading(true);
    let array: updateBill[] = [];
    let el: any = billData;
    array.push(el);
    API.put(`/Bills`, array).then(res => {
      message.success("La Factura fue actualizada");
      props.getCurrierDeliveries(props.dispatchSelected)
      props.setVisible(false);
      setLoading(false);
    })
      .catch(err => {
        console.log(err);
        //message.error('Se presentó un error, intentalo nuevamente');
        setLoading(false);
      })
  }

  const closeModal = () => {
    props.reloadData();
    props.setVisible(false);
  }

  return (
    <div className={`modalUpdateBillData`}>
      <Modal
        open={props.visible}
        title={
          <Row>
            <Col span={20}>{`GUIA: ${props.guia}`}</Col>
            <Col span={4}>
              <Button onClick={() => reasignarGuia()} type='primary'>Reasignar</Button>
            </Col>
          </Row>}
        width={1000}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
      >
        <div style={{ padding: 20 }}>
          <Row style={{ marginBottom: 15 }}>
            <Col span={6}>Guia:</Col>
            <Col span={6}>
              <Input
                value={billData ? billData.guia : 0}
                name='guia'
                onChange={(e) => handleChange(e)}
              />
            </Col>
            <Col span={6}>

            </Col>
            <Col span={6}>
              {(userData?.roles.includes("SuperAdmin") || userData?.roles.includes("Bodega")) &&
                <ModalBtnDevolver guia={props.guia} />}
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={6}>Flete:</Col>
            <Col span={12}>
              <InputNumber
                value={billData?.delivery_value}
                name='delivery_value'
                disabled={!userData?.roles.includes("SuperAdmin") && !userData?.roles.includes("Admin")}
                onChange={(e) =>
                  setbillData((prevState: BillData) => ({
                    ...prevState,
                    delivery_value: e
                  }))}
              />
            </Col>
            <Col span={6}>
              {billData &&

                <ModalCreateDeliveryNews
                  guia={props.guia}
                  billingId={billData.id}
                  billData={billData} />
              }
            </Col>
          </Row>
          <br /><br />
          <Row>
            <Col span={17}></Col>
            <Col span={3}>
              <Button key="back" onClick={() => closeModal()}>
                Cerrar
              </Button>
            </Col>
            <Col span={1}></Col>
            <Col span={3}>
              <Button type="primary" key="back" onClick={() => saveData()}>
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ModalUpdateBillData;
