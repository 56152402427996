import { useEffect } from "react";
import utils from "../utils";

export const useHeader = () => {
  useEffect(() => {
    utils.comprobarCookie("auth_token");
  }, []);

  const LogOut = () => {
    utils.delete_cookie("auth_token");
    window.location.href = "/";
  };

  return {
    LogOut,
  };
};
