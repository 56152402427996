import { Button, Col, Row } from 'antd';
import './Change.css';
import titulo6 from '../../Assets/titulo6.png';
import quieroSer from '../../Assets/quieroSer.png';



const Change = () => {
    return (

        <div className='fondoChange'>

            <Row  >
                <Col xs={1} sm={1} md={3} lg={3} xl={3} ></Col>
                <Col xs={22} sm={22} md={18} lg={18} xl={18}  >
                    <div  style={{ textAlign: 'center' }}>
                        <img src={titulo6} className='imgChange' /><br></br>
                    </div>
                </Col>
                <Col xs={1} sm={1} md={3} lg={3} xl={3} ></Col>
            </Row>
            <Row  >
                <Col xs={1} sm={1} md={3} lg={3} xl={3} ></Col>
                <Col xs={22} sm={22} md={18} lg={18} xl={18} >
                    <div style={{ textAlign: 'center' }}>
                        <img src={quieroSer} className='buttonQuiero' />
                    </div>
                </Col>

                <Col xs={1} sm={1} md={3} lg={3} xl={3} ></Col>
            </Row><br/><br/>
        </div>
    )
}

export default Change;

