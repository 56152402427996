import React, { useState } from 'react'

const useProductModalLibrary = () => {

    const [type, setType] = useState("");
    const [productId, setProductId] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);

    const openModal = ( type:string, productId?: number ) => {
        setType(type);
        if(productId) {
            setProductId(productId);
        }
        setModalVisible(true);
    }

    return {
        type,
        productId,
        modalVisible,
        setModalVisible,
        openModal
    }
}

export default useProductModalLibrary
