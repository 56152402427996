import { Button, Col, Input, Row } from 'antd';
import { ILoginProps } from './ILoginProps';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.css';
import { useLogin } from '../../Hooks/useLogin';
import ModalForgotPassword from '../ModalForgotPassword/ModalForgotPassword';

const Login = (props: ILoginProps) => {

const { 
  loading, 
  login,
  updateState
} = useLogin();

  return (
    <div className='login-container'>
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <Row className='login-content'>
            <Col span={12}>
              <div className="container-left-block">
                <div className="bold-title-white">
                  CRECEL
                </div>
                <div className="sub-title-white-login">
                  UNA FRANQUICIA
                </div>
                <div className="bold-title-white">
                  GANADORA
                </div>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={10} className="right-block">
              <div className="gray-text">CRECEL</div>
              <div className="bold-title-blue">
                BIENVENIDO
              </div>
              <div className="sub-title-blue">
                DE NUEVO
              </div>
              <div className='blue-line'></div>
              <div className="description-text">
                Gracias por volver, inicie sesión en su cuenta completando este formulario
              </div>
              <Input 
                size="large" 
                placeholder="Nombre del usuario" 
                name="User"
                onChange={updateState}
                prefix={<img src="https://drophacking.blob.core.windows.net/images/Login/Icono-para-el-campo-de-usuario.png" className='form-icon'/>} 
                className="input" 
              />
              <Input.Password 
                size="large" 
                placeholder="Contraseña" 
                name="Password"
                onChange={updateState}
                prefix={<img src="https://drophacking.blob.core.windows.net/images/Login/icono-para-el-campo-contraseña.png" className='form-icon'/>} 
                className="input" />
              <Row>
                <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                  <Button className="btn-login" onClick={login} loading={loading}>Iniciar &nbsp;<b>sesión</b></Button>
                </Col>
                <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                  <div className="remenber-pass">
                    <ModalForgotPassword/>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
