import { Col, Row, Spin, Image, Progress } from 'antd';
import * as React from 'react';
import { usePanel } from '../../../Hooks/Store/usePanel';
import { IPlacasProps } from './IPlacasProps';
import './Placas.css';

const Placas = (props: IPlacasProps) => {

  const {
    TotalBills,
    loading
  } = usePanel();
  return (
    <div>
      <br/><br/>
      <span className='normal-text'>VENTAS</span>&nbsp;<span className='bold-text'>ALCANZADAS</span>
      <br/><br/>

      <Spin spinning={loading} tip="Cargando Placas...">
        <Row gutter={[16, 16]}>
          <Col span={2}></Col>
          <Col span={5}>
            {TotalBills >= 500 ?
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/500Color.png"} />
              :
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/500Gris.png"} />
            }
          </Col>
          <Col span={5}>
            {TotalBills >= 1000 ?
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/1000Color.png"} />
              :
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/1000Gris.png"} />

            }
          </Col>
          <Col span={5}>
            {TotalBills >= 2000 ?
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/2000Color.png"} />
              :
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/2000Gris.png"} />

            }
          </Col>
          <Col span={5}>
            {TotalBills >= 5000 ?
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/5000Color.png"} />
              :
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/5000Gris.png"} />

            }
          </Col>
          </Row>
          <br/>
          <Row gutter={[16, 16]}>
          <Col span={2}></Col>
          <Col span={5}>
            {TotalBills >= 10000 ?
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/10000Color.png"} />
              :
              <Image src={"https://drophacking.blob.core.windows.net/images/Panel/Placas/10000Gris.png"} />

            }
          </Col>
        </Row>
        <br/>
        <Row>
          <Col span={3}></Col>
          <Col span={20}>
            <Progress
              strokeColor={{
                '0%': '#808080',
                '100%': '#FFD700',
              }}

              percent={(TotalBills * 100) / 10000}
              format={percent => ` `}
              status="active"
            />
          </Col>
        </Row>
        <Row>
          <Col span={3}></Col>
          <Col span={18}>
          <span className='hola-text'>{TotalBills}</span>&nbsp;<span className='user-name'>VENTAS</span>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Placas;
