import { Button, message, Modal, notification, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { API } from "../../API/axios";
import { DollarOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { store_wallet } from "../../Models/store_wallet.model";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Paging,
  Editing,
} from "devextreme-react/data-grid";
import { PageContext } from "../../Hooks/ContextProvider";

export const ModalBillMovements = (BillId: any) => {
  const [store_wallet, setStore_wallet] = useState<store_wallet[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(PageContext);


  useEffect(() => {
    if (BillId.BillId) {
      GetMomentsByBill();
    }
  }, [BillId]);

  const UpdateStoreWalletMovement = (row: any) => {
    let data = { value: row.value, id: row.id }
    API.put(`/StoreWallet/${row.id}`, data);
    notification.success({ message: 'Datos guardados' })
  }

  const GetMomentsByBill = () => {
    setLoading(true);
    if (BillId.BillId) {
      API.get<store_wallet[]>(
        `/StoreWallet/GetStoreWalletByBillId?BillId=${BillId.BillId}`
      ).then((res) => {
        setStore_wallet(res.data);
        setLoading(false);
      });
    }
  };


  return (
    <div>
      <DollarOutlined
        title="ver movimientos monedero"
        onClick={() => setModalVisible(true)}
      />
      <Spin spinning={loading} />
      <Modal
        open={modalVisible}
        title={`Movimientos de la guia:`}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        width={900}
      >
        <div style={{ padding: 20 }}>
          <DataGrid
            dataSource={store_wallet}
            allowColumnReordering={true}
            showBorders={true}
            rowAlternationEnabled={true}
            onRowUpdated={(row) => UpdateStoreWalletMovement(row.data)}
          >
            {userData?.roles.includes("SuperAdmin") &&
              <Editing
                mode='bash'
                allowUpdating={true}
                allowAdding={true}
                allowDeleting={false}
              />
            }
            <Column
              dataField="movement_type"
              dataType="string"
              caption="Tipo de movimiento"
              width={150}
              allowEditing={false}
            />
            <Column
              dataField="value"
              cellRender={valueCustom}
              caption="Valor"
            />
            <Column
              dataField="created_at"
              dataType="date"
              caption="Fecha"
              format="yyyy-MM-dd"
              allowEditing={false}
            />
            <Column
              dataField="description"
              dataType="string"
              caption="Detalle"
              width={300}
            />
            <Paging defaultPageSize={20} />
          </DataGrid>
        </div>
      </Modal>
    </div>
  );
};

function valueCustom({ data }: any) {
  if (data.value > 0) {
    return (
      <span style={{ color: "green" }}>
        <CaretUpOutlined />{" "}
        {`${data.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </span>
    );
  }
  if (data.value < 0) {
    return (
      <span style={{ color: "red" }}>
        <CaretDownOutlined />{" "}
        {`${data.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </span>
    );
  }
  if (data.shippingTotal) {
    return (
      <span style={{ color: "red" }}>
        <CaretDownOutlined />{" "}
        {`${data.shippingTotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </span>
    );
  }
}