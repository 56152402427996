import { Button, DataGrid } from "devextreme-react"
import { Selection, Column, ColumnFixing, Export, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Sorting } from "devextreme-react/data-grid"
import { useCallback, useState } from "react";
import { Contract, getContracts, updateContracts } from "./TableContractsCategoryService";
import { Popconfirm } from "antd";
import './TableContractsCategory.css'

interface TableContractsCategory {
    id: number;
}
const TableContractsCategory = (props: any) => {

    const [tableContractsCategory, setTableContractsCategory] = useState<TableContractsCategory>({ id: 0 });
    const [contracts, setContracts] = useState<Contract[]>([]);
    if (props.id != tableContractsCategory.id) {
        getContracts(props.id).then((res => {
            setContracts(res);
            let actives = res.filter((x: { isActiv: any; }) => x.isActiv)
            setSelectedItemKeys(actives);
            setTableContractsCategory(props)
        }))
    }

    const onSelectionChanged = useCallback((data: any) => {
        setSelectedItemKeys(data.selectedRowKeys);
        handleUpdateContracts(props.id, data.selectedRowKeys)
    }, []);
    const [selectedItemKeys, setSelectedItemKeys] = useState<Contract[]>([]);

    function handleUpdateContracts(idcategory: number, contracts: Contract[]) {
        if (idcategory != 0) {
            updateContracts(idcategory, contracts)
        }
    }

    return (
        <div className="contenedor">
            <Popconfirm
                className="CategoryButton"
                title="Las tiendas asociadas a esta categoria deberán aceptar el contrato para que la tienda se habilite"
                onConfirm={(_) => {
                    handleUpdateContracts(tableContractsCategory.id, selectedItemKeys)
                }}
            >
                <Button className="btn-contratos">Guardar Contratos</Button>
            </Popconfirm>
            <div>

                <DataGrid
                    id="contracts"
                    dataSource={contracts}
                    showBorders={true}
                    remoteOperations={true}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    showRowLines={true}
                    selectedRowKeys={selectedItemKeys}
                    onSelectedRowKeysChange={setSelectedItemKeys}
                >
                    <Selection mode="multiple" />
                    <HeaderFilter visible={true} />
                    <HeaderFilter visible={true} />
                    <Sorting mode="multiple" />
                    <GroupPanel visible={false} />
                    <ColumnFixing enabled={true} />
                    <FilterRow visible={true} />
                    <Export enabled={true} allowExportSelectedData={false} />
                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                    <Grouping autoExpandAll={false} />
                    <Pager
                        showInfo={true}
                        showNavigationButtons={true}
                        showPageSizeSelector={true}
                        visible={true}
                    />

                    <Column
                        allowEditing={false}
                        dataField="name"
                        caption="Nombre"
                        alignment="center"
                    />
                    <Column
                        allowEditing={false}
                        dataField="description"
                        caption="Descripción"
                        alignment="center" />
                </DataGrid>

            </div>
        </div>
    )
}

export default TableContractsCategory;