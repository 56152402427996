import React, { useEffect, useState, useRef } from 'react';
import { IDispatchRelationshipProps } from './IDispatchRelationshipProps';
import './DispatchRelationship.css';
import { Button, Col, Input, InputNumber, message, Popconfirm, Row, Select, Spin, Table } from 'antd';
import moment from 'moment';
import { DeleteColumnOutlined, DeleteOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { company_transport } from '../../../../Models/company_Transport';
import { Bill } from '../../../../Models/Bill';
import { BillProducts, product } from '../../../../Models/Products';
import { user } from '../../../../Models/User.Model';
import { arrayRelationShips } from '../PrintDispatchRelationship/PrintDispatchRelationship';
import { BillByStatus } from '../../../../Models/Warehouse';
import { API } from '../../../../API/axios';

const { Option } = Select;
export type dispatch = {
    id:number,
    created_at:string,
    bills:Bill[]
}

const EditDispatchRelationship = (props:IDispatchRelationshipProps) => {

    const [selectedCompanyTransport, setSelectedCompanyTransport] = useState<number>();
    const [dispatchId, setDispatchId] = useState<number>(0);
    const [guia, setGuia] = useState<string>("");
    const [companyTransportList, setCompanyTransportList] = useState<company_transport[]>([]);
    const inputRef = useRef<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [listDataPendingDispatch, setListDataPendingDispatch] = useState<Array<Bill>>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    //const [dataForDispatch, setDataForDispatch] = useState<Array<Bill>>([]);
    const [productList, setProductList] = useState<Array<product>>([]);
    const [dispatchData, setDispatchData] = useState<dispatch>();
    const [dispatchBills, setdispatchBills] = useState<Bill[]>([]);
    const [arrayDispatchesRelationShip1, setArrayDispatchesRelationShip1] = useState<arrayRelationShips[]>([]);
    const [arrayDispatchesRelationShip2, setArrayDispatchesRelationShip2] = useState<arrayRelationShips[]>([]); 
    const [couriersList, setCouriersList] = useState<user[]>([]); 

    useEffect(() => {
        GetCompanyTransport();
        getPendingBillsDispatch();
        getProductList();
        getCrecelCouriers();
    }, []);

    const GetCompanyTransport = () => {
        API.get<company_transport[]>(`/CompanyTransports`).then(res => {
            setCompanyTransportList(res.data);
        });
    }

    const getProductList = () => {
        API.get<product[]>(`/Products`).then(res => {
            setProductList(res.data);
        })
    }

    const getCrecelCouriers = () => {
        API.get<user[]>(`/Users/GetCrecelCouriers`).then(res=>{
          setCouriersList(res.data);
          getDispatchHistory(res.data);
        })
      }

    const getDispatchHistory = (courrierList:user[]) => {
        let endDate = moment(new Date()).format('YYYY/MM/DD')
        let startDate = moment(new Date()).day(-15).format('YYYY/MM/DD');
        API.get(`Dispatchs/GetDispatchesByDates?startDate=${startDate}&endDate=${endDate}`).then(res=>{
          let array1 = res.data.map((x:any)=>{
            let el:arrayRelationShips = {
              id:x.id,
              date: moment(x.created_at).utcOffset('GMT-05').format('YYYY-MM-DD'),
              company_transport : x.bills.length > 0 ? x.bills[0].company_TransportId : 0
            }
            return el;
          });
          setArrayDispatchesRelationShip1(array1);
        });
        API.get(`CrecelDeliveries/GetDeliveriesByDates?startDate=${startDate}&endDate=${endDate}`).then(res=>{
          let array2 = res.data.map((x:any)=>{
            let mensajeros = courrierList.filter(u=>u.id === x.courierId);
            let mensajero = "";
            if(mensajeros.length > 0)
            {
              mensajero = mensajeros[0].full_name;
            }
            let el:arrayRelationShips = {
              id:x.id,
              date: moment(x.created_at).utcOffset('GMT-05').format('YYYY-MM-DD'),
              company_transport : mensajero
            }
            return el;
          });
          setArrayDispatchesRelationShip2(array2);
        });
      }

    const addItemToArray = (e: any) => {
        if (e.key === 'Enter') {
            let guia = e.target.value;
            let bill = listDataPendingDispatch.find((x: Bill) => x.guia === guia);
            let billInDispatch = dispatchBills.map((x: Bill) => x.guia).indexOf(guia);
            if (billInDispatch === -1) {
                if (bill !== undefined) {
                    let object: Bill = bill;
                    setdispatchBills(dataForDispatch => [...dataForDispatch, object])
                }
                else {
                    message.error('Esta guia no esta disponible para ser despachada', 4)
                }
            }
            else {
                message.warning(`La guia ${guia}, ya está incluída en esta relación de despacho`, 5)
            }
            inputRef.current.focus();
            setGuia("");
        }
    }

    const getPendingBillsDispatch = () => {
        let data: BillByStatus = {
            status: "Impreso-G",
        }
        setLoading(true);
        API.get<Bill[]>(`/Bills/GetBillsByStatus?status=Impreso-G`).then(res => {
            let response = res.data.map((x: Bill) => {
                x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
                x.key = x.id;
                return x
            })
            setLoading(false);
            setListDataPendingDispatch(response);
        });
    }

    const removeItemsFromDispatch = () => {
        let arrayTemp = [...dispatchBills as any];
        selectedRowKeys.map((x: number) => {
            arrayTemp = arrayTemp.filter((r: Bill) => r.id !== x);
        });
        setdispatchBills(arrayTemp);
        setSelectedRowKeys([])
    }

    const onSelectChange = (selectedRowKeys: any) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (e: any) => onSelectChange(e),
    };

    const columns = [
        {
            title: 'Guia',
            dataIndex: 'guia',
            key: 'guia'
        },
        {
            title: 'Teléfono',
            dataIndex: 'guia',
            key: 'guia',
            render: (f: any, x: Bill) => {
                return `${x.storeClient?.cellphone} / ${x.storeClient?.phone}`
            }
        },
        {
            title: 'Contenido',
            dataIndex: 'bill_Products',
            key: "bill_Products",
            render: (f: any, x: Bill) => {
                let arrayProds = x.bill_Products.map((prod: BillProducts) => {
                    let productFiltered = productList.filter((p: product) => prod.productId === p.id);
                    return productFiltered.length > 0 ? `${prod.quantity} ${productFiltered[0].name}` : ""
                });
                return arrayProds.join();
            }
        },
        {
            title: 'Recaudo',
            dataIndex: 'bill_Products',
            key: "bill_Products",
            render: (f: any, x: Bill) => {
                let arrayProds = x.bill_Products.map((prod: BillProducts) => {
                    let productFiltered = productList.filter((p: product) => prod.productId === p.id);
                    return productFiltered.length > 0 ? productFiltered[0].selling_price : ""
                });
                let total = arrayProds.length > 0 ? arrayProds.reduce((a: any, b: any) => { return a + b }) : 0;
                return `$ ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
    ];

    const saveData = () => {
        if(arrayDispatchesRelationShip1.length > 0)
        {
            let el = {
                billingsId: dispatchBills.map((x: Bill) => { return x.id !== undefined ? x.id : 0 })
            }
            API.put(`/Dispatchs/${dispatchId}`,el).then(res => {
                message.success('Los datos fueron actualizados');
                setCompanyTransportList([]);
                setSelectedRowKeys([]);
            })
        }
    }

    const getDispatchByById = (type:string,dispatchId:number) => {
        if(dispatchId)
        {
            API.get(`/${type}/${dispatchId}`).then(res=>{
            let response = res.data.bills.map((x: Bill) => {
              x.key = x.id;
              return x
            })
            setDispatchData(res.data);
            setdispatchBills(response); 
          })
          .catch(err=>{
            console.log(err);
            message.error('Se presentó un error, intentelo nuevamente');
          })
        }
        else{
          setdispatchBills([]); 
        }
      }

    return (
        <div className={`dispatchRelationship`}>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    Nº Relación de despachos:<br />
                    <Select
                        allowClear
                        style={{ width: '100%', marginBottom: 20 }}
                        placeholder={"Mensajero"}
                        onChange={(e: any) => getDispatchByById('Dispatchs', e)}
                    >
                        {arrayDispatchesRelationShip1.map(x =>
                            <Option key={x.id} value={x.id}>
                                {`${companyTransportList.filter(com => com.id === x.company_transport).length > 0 ?
                                    companyTransportList.filter(com => com.id === x.company_transport)[0].name : ""} 
                  - ${x.date}`}
                            </Option>
                        )}
                    </Select>

          Relacion de despachos Crecel:
          <Select
                        allowClear
                        style={{ width: '100%' }}
                        placeholder={"Mensajero"}
                        onChange={(e: any) => getDispatchByById('CrecelDeliveries', e)}
                    >
                        {arrayDispatchesRelationShip2.map(x =>
                            <Option key={x.id} value={x.id}>
                                {x.company_transport} - {x.date}
                            </Option>
                        )}
                    </Select>
                </Col>
                <Col span={2}>
                    <br />
                 </Col>
                <Col span={12}></Col>
            </Row>
            <Row>
                <Col span={3}>

                </Col>
                <Col span={1}></Col>
                <Col span={1}></Col>
                <Col span={4}>

                </Col>
                <Col span={1}></Col>
                <Col span={4}>
                    {selectedRowKeys.length > 0 &&
                        <Popconfirm
                            placement="top"
                            title="¿Eliminar las guias seleccionadas de la relación ?"
                            onConfirm={removeItemsFromDispatch}
                            okText="Sí" cancelText="No">
                            <br />
                            <Button icon={<DeleteOutlined />} type="primary">{`Elimininar (${selectedRowKeys.length}) de la relación`}</Button>
                        </Popconfirm>
                    }
                </Col>
                <Col span={6}></Col>
                <Col span={2}>
                    <br />
                    {dispatchBills.length > 0 &&
                        <Button icon={<SaveOutlined />} type="primary" onClick={saveData}>Guardar</Button>
                    }
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Spin tip="Cargando datos..." spinning={loading}>
                        <Table rowSelection={rowSelection} columns={columns} dataSource={dispatchBills} />
                    </Spin>
                </Col>
            </Row>
        </div>
    );
};

export default EditDispatchRelationship;
