import { message } from "antd";
import { API } from "../../API/axios";


export interface RequestFreight {
    storeId: number
    cityId: number
    collectCommission: number,
    serviceCommission: number,
    deliveryFreight: number,
    crecelUtily: number
}
export function loadCities(storeId: number) {
    return API.get(`cities/Freight/${storeId}`)
        .then((response) => {
            return response.data
        })
        .catch((error) => message.error(error.message));
}

export function deleteWithFreight(id: number) {
    return API.delete(`cities/Freight?id=${id}`)
        .then((response) => {
            return response.data
        })
        .catch((error) => message.error(error.message));
}

export function putFreight(requestFreight: RequestFreight) {
    return API.put(`cities/Freight`, requestFreight)
        .then((response) => {
            return response.data
        })
        .catch((error) => message.error(error.message));

}