import { Button, Input, Select, message } from "antd"
import { useState } from "react";
import "./ModalNoveltyHoko.css";
import { API } from "../../API/axios";
import { deliveryNews } from "../../Models/SuperAdmin/deliveryNews.model";

interface ModalNoveltyProps {
    billId: number;
    deliveryNews: deliveryNews,
}

const ModalNoveltyHoko = (props: ModalNoveltyProps) => {
    const { billId, deliveryNews } = props

    const [deliveryNew, setDeliveryNew] = useState<deliveryNews>(deliveryNews);

    const { TextArea } = Input;
    const { Option } = Select;
    const [solutionText, setSolutionText] = useState<string>("");
    const [solutionStatus, setsolutionStatus] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const onChangeSolution = (text: string) => {
        if (text.length <= 255) {
            setSolutionText(text);
        }
    };

    const SendNoveltySolution = async () => {
        setLoading(true);
        var solutionRequest = {
            billId: billId,
            message: solutionText,
            status: solutionStatus,
            deliveryNewId: deliveryNew.id
        };
        await API.post("/DeliveryNews/SolveNoveltyHoko", solutionRequest).then(x => {
            setLoading(false);
            message.success('Solución enviada');
            setSolutionText("");
            setDeliveryNew(x.data);
        });
    }
    return (
        <div className="modal-content ant-col-10">
            Tipo de solución:
            <Select
                onChange={(e: any) => setsolutionStatus(e)}
                style={{ width: "100%" }}
                defaultValue={"Corrección de Dirección"}
            >
                <Option key={"Corrección de Dirección"} value={0}>
                    Corrección de Dirección
                </Option>
                <Option key={"Nuevo Ofrecimiento"} value={1}>
                    Nuevo Ofrecimiento
                </Option>
                <Option key={"Solicitar Devolución"} value={2}>
                    Solicitar Devolución
                </Option>
            </Select>
            <TextArea
                disabled={deliveryNew.response != null}
                rows={3}
                onChange={(e) => onChangeSolution(e.target.value)}
                value={deliveryNew.response ?? solutionText}
            />
            {!deliveryNew.response && (<Button loading={loading} onClick={() => SendNoveltySolution()} disabled={solutionText.length == 0} type="primary">Enviar solución</Button>)}
        </div>
    )
}

export default ModalNoveltyHoko;