import { Button, Col, Input, Row } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import "../Login/Login.css";
import { useRegistration } from "../../Hooks/Store/useRegistration";

const CreateRegistration = () => {


    const {
        userRegistrationData,
        handleChangeUserRegistration,
        loading,
        registerUser
    } = useRegistration();

  return (
    <div className="login-container">
      <div className="blue-bar"></div>
      <Row>
        <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <div className="login-layout">
            <div>
              <Row>
                <Col
                  xs={0}
                  sm={0}
                  md={10}
                  lg={10}
                  xl={10}
                  className="left-block"
                >
                  <div className="container-left-block" style={{marginTop:'100pt'}}>
                    <img src='https://drophacking.blob.core.windows.net/images/Registro/icono-para-titulo.png'/>
                    <div className="bold-title-white">CRECEL</div>
                    <div className="sub-title-white-login">UNA FRANQUICIA</div>
                    <div className="bold-title-white">GANADORA</div>
                  </div>
                </Col>
                <Col xs={2} sm={2} md={1} lg={1} xl={1} className="right-block white-container">
                  <div className="gray-bar"></div>
                </Col>
                <Col
                  xs={22}
                  sm={22}
                  md={10}
                  lg={10}
                  xl={10}
                  className="right-block white-container"
                >
                  <div className="gray-text">CRECEL</div>
                  <span className="bold-title-blue">PREPARATE</span>&nbsp;&nbsp;
                  <span className="title-blue">PARA</span><br/>
                  <span className="title-blue">SER PARTE DE LA </span><br/>
                  <span className="bold-title-blue">NUEVA ÉLITE DE</span><br/>
                  <span style={{background:'#0a1a55',color:'white',fontSize:'40pt'}}>FACTURACIÓN</span>
                  <div className="description-text">
                    Completa el siguiente formulario para dar inicio al registro de tu tienda
                  </div>
                  <Input
                    size="large"
                    placeholder="Nombre del usuario"
                    name="name"
                    prefix={<UserOutlined />}
                    className="input"
                    value={userRegistrationData.name}
                    onChange={handleChangeUserRegistration}
                  />
                  <Input
                    size="large"
                    placeholder="Email"
                    name="email"
                    prefix={<MailOutlined />}
                    className="input"
                    value={userRegistrationData.email}
                    onChange={handleChangeUserRegistration}
                  />
                  <Row>
                    <Col xs={22} sm={22} md={22} lg={6} xl={6}></Col>
                    <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                      <Button className="generic-btn" loading={loading} onClick={registerUser}>
                        <b>Registrarse</b>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
      </Row>

      <div className="blue-bar"></div>
    </div>
  );
};

export default CreateRegistration;
