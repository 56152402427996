import { Badge, Button, Col, DatePicker, Modal, Row, Spin, } from "antd";
import { ISalesProps } from "./ISalesProps";
import "./Sales.css";
import { useSales } from "../../Hooks/Store/useSales";
import { DataGrid } from "devextreme-react";
import {
  IssuesCloseOutlined,
} from "@ant-design/icons";
import {
  Column,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { usePanel } from "../../Hooks/Store/usePanel";
import { useContext } from "react";
import { PageContext } from "../../Hooks/ContextProvider";
import ModalDetalleGuia from "../ModalDetalleGuia/ModalDetalleGuia";
import ModalBillNews from "../ModalBillNews/ModalBillNews";
const { RangePicker } = DatePicker;

const Sales = (props: ISalesProps) => {
  const {
    loading,
    filterStateSelected,
    salesList,
    filtrarPorEstado,
    handleChangeDates,
    guia,
    modalSearchGuiaVisible,
    setModalSearchGuiaVisible,
    findGuia
  } = useSales();

  const { totalWallet, store } = usePanel();

  const AmountFormat = {
    style: "currency",
    currency: "USD",
    useGrouping: true,
    minimumSignificantDigits: 3,
  };

  const { userData } = useContext(PageContext);

  const DetalBillNews = (guia: string) => {
    window.open(
      `https://web.palomamensajeria.com/DetailBill?guia=${guia}`,
      "_blank"
    );
  };

  function isNews(data: any) {
    if (data.data.delivery_News > 0) {
      if (data.data.company_Transport == 'Paloma') {
        return <Button type="primary" onClick={() => DetalBillNews(data.data.guia)}>
          <IssuesCloseOutlined style={{ color: "orange" }} /> Novedad
        </Button>
      } else {
        return <ModalBillNews
          guia={data.data.guia}
          company_TransportId={data.data.company_TransportId}
          billingId={data.data.id}
        />
      }
    }
  }

  return (
    <div className="sales-container">
      <Row>
        <Col xs={1} sm={1} md={1} lg={2} xl={2}></Col>

        <Col xs={24} sm={24} md={24} lg={2} xl={2} className="bg-2">
          <div className="logo-container-sales">
            <img src={store?.logo} className="logo" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7} className="bg-2">
          <div className="line-h-20-sales">
            <span className="hola-text-sales">¡Hola</span>&nbsp;
            <span className="user-name-sales">{userData?.userName}!</span>
          </div>
          <div className="line-h-20-sales">
            <span className="normal-text-sales">SALDO</span>&nbsp;
            <span className="bold-text-sales">ACTUAL</span>
          </div>
          <div className="line-h-40-sales">
            <span className="amount-value-sales">{`$ ${totalWallet}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4} className="bg-2">
          <br />
          <span className="normal-text-sales">Rango de fechas</span>
          <RangePicker onChange={handleChangeDates} />
        </Col>
        <Col xs={6} sm={6} md={6} lg={3} xl={3} className="bg-3 panel-metrics">
          <img src="https://drophacking.blob.core.windows.net/images/Panel/INCONO-DE-INGRESOS.png" />
          <div className="line-h-20">
            <span className="panel-title">Ventas</span>
            <br />
            <span className="panel-value">{`$ ${salesList.reduce((sum, { collect_value }) => sum + collect_value, 0).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={3} xl={3} className="bg-2 panel-metrics">
          <br />
          <img src="https://drophacking.blob.core.windows.net/images/Panel/ICONO-DE-FLETES.png" />
          <div className="line-h-20">
            <span className="panel-title">FLETES</span>
            <br />
            <span className="panel-value">{`$ ${salesList.reduce((sum, { flete }) => sum + flete, 0).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={3} xl={3} className="bg-3 panel-metrics">
          <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-COSTOS-.png" />
          <div className="line-h-20">
            <span className="panel-title">PRODUCTOS</span>
            <br />
            {localStorage.currency == "COP" ?
              <span className="panel-value">{`$ ${salesList.reduce((sum, { cost }) => sum + cost, 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
              :
              <span className="panel-value">{`$ ${salesList.reduce((sum, { usD_Cost }) => sum + usD_Cost, 0).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={1} sm={1} md={1} lg={2} xl={2}></Col>
        <Col xs={24} sm={24} md={24} lg={22} xl={22} className="bg-1-sales">
          <div onClick={() => filtrarPorEstado('Todos')} className="menu-boton">
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/icono-de-TODOS-.png" />
            Todos ({salesList.length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Pendiente')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/icno-de-PENDIENTES.png" />
            Pendiente ({salesList.filter(x => x.status == 'Pendiente').length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Despachado')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-DESPACHADOS-.png" />
            Despachado ({salesList.filter(x => x.status == 'Despachado').length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Anulado')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-ANULADO-.png" />
            Anulado ({salesList.filter(x => x.status == 'Anulado').length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Novedad')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-NOVEDAD.png" />
            Novedad ({salesList.filter(x => x.status == 'Novedad').length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Devuelto')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-DEVUELTO.png" />
            Devuelto ({salesList.filter(x => x.status == 'Devuelto').length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Entregado')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-ENTREGADO.png" />
            Entregado ({salesList.filter(x => x.status == 'Entregado').length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Pagado')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-PAGADO-.png" />
            Pagado ({salesList.filter(x => x.status == 'Pagado').length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Adelantado')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-ADELANTADO.png" />
            Adelantado ({salesList.filter(x => x.status == 'Adelantado').length})
          </div>
          <div className="menu-boton" onClick={() => filtrarPorEstado('Solucionada')}>
            <img src="https://drophacking.blob.core.windows.net/images/Ventas/ICONO-DE-SOLUCIONADO.png" />
            Solucionado ({salesList.filter(x => x.status == 'Solucionada').length})
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={1} sm={1} md={1} lg={2} xl={2}></Col>
        <Col xs={24} sm={24} md={24} lg={22} xl={22} className="bg-1-sales">
          <Spin tip="Cargando..." spinning={loading}>
            <DataGrid
              dataSource={
                filterStateSelected === "Todos"
                  ? salesList
                  : salesList.filter((x) => x.status === filterStateSelected)
              }
              allowColumnReordering={true}
              showBorders={true}
              rowAlternationEnabled={true}
              allowColumnResizing={true}
            >
              <HeaderFilter visible={true} />
              <Export enabled={true} allowExportSelectedData={false} />
              <GroupPanel visible={true} />
              <SearchPanel visible={true} highlightCaseSensitive={true} />
              <Grouping autoExpandAll={true} />
              <FilterRow visible={true} />
              <Column dataField="guia1" alignment="center" caption="Ver Guia"
                type="buttons"
                buttons={[{
                  text: "ver",
                  hint: 'Ver Guia',
                  icon: 'find',
                  // visible:  (row: any) => row.row.data.totalPendingBillNews > 0 ? true : false,  
                  onClick: (row: any) => { findGuia(row.row.data) }
                }]}
              />
              <Column dataField="guia" caption="Guia" dataType="string" />
              <Column
                dataField="observaciones_Envio"
                dataType="string"
                caption="Productos"
                width={350}
              />
              <Column
                dataField="created_at"
                type={"date"} format={"dd-MM-yyyy"}
                caption="Fecha"
              />
              <Column
                dataField="collect_value"
                type={"number"} format={AmountFormat}
                caption="Recaudo"
              />
              <Column dataField="flete" caption="Flete" type={"number"} format={AmountFormat} />
              {localStorage.currency == 'COP' ?
                <Column dataField="cost" type={"number"} format={AmountFormat} caption="Costo" />
                :
                <Column dataField="usD_Cost" type={"number"} format={AmountFormat} caption="Costo" />
              }

              <Column dataField="status" dataType="string" caption="Estado" />
              <Column
                dataField="company_Transport"
                dataType="string"
                caption="Transportadora"
              />
              <Column
                dataField="forma_Pago"
                dataType="string"
                caption="Forma de pago"
              />
              <Column dataField="id" cellRender={isNews} caption="Novedades" allowSorting={false} />

              <Pager showPageSizeSelector={true} showInfo={true} />
              <Paging defaultPageSize={20} />
            </DataGrid>
          </Spin>
        </Col>
      </Row>
      {(modalSearchGuiaVisible && guia != "") &&
        <ModalDetalleGuia visible={modalSearchGuiaVisible} setVisible={setModalSearchGuiaVisible} guia={guia} />
      }
    </div>
  );
};

export default Sales;
