import { useState } from "react";
import { message } from "antd";
import { store_wallet_movement } from "../../../Models/store_wallet.model";
import { API } from "../../../API/axios";


export const useModalSaldoTienda = (rechargePending: any) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setdisabled] = useState<boolean>(false);

    const aprobarRetiro = (data: store_wallet_movement) => {
        setLoading(true);
        data.state = "Aprobación de Retiro";
        API.put(`/StoreWalletMovement/${data.id}`, data).then(response => {
            setdisabled(true);
            message.success('Retiro de dinero registrado');
            rechargePending();      
        });  
    }

    const rechazarRetiro = (data: store_wallet_movement) => {
        setLoading(true);
        data.state = "Rechazado";
        API.put(`/StoreWalletMovement/${data.id}`, data).then(response => {
            setdisabled(true);
            message.success('La solicitud fue rechazada');
            rechargePending();
        });
    }

    return {
        aprobarRetiro,
        rechazarRetiro,
    }
}
