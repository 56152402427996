import { IEditBillProps } from './IEditBillProps';
import './EditBill.css';
import { Button, Col, Input, InputNumber, Modal, Row } from 'antd';
import { useEditBill } from '../../../Hooks/SuperAdmin/useEditBill';
import { useEffect } from 'react';
import ModalRegisterBillNews from '../ModalRegisterBillNews/ModalRegisterBillNews';
import ModalReturnBill from '../ModalReturnBill/ModalReturnBill';

const EditBill = (props: IEditBillProps) => {

  const {
    getBillData,
    billData,
    saveData,
    loading,
    billUpdateData,
    handleChangeBillData
  } = useEditBill();

  useEffect(() => {
    if (props.billId != 0) {
      getBillData(props.billId);
    }
  }, [props.billId]);

  return (
    <Modal
      title={props.billId}
      open={props.showModal}
      width={700}
      onOk={() => props.setShowModal(false)}
      onCancel={() => props.setShowModal(false)}
      footer={[]}
    >
      <div style={{ padding: 20 }}>
        <Row gutter={[2, 2]}>
          <Col span={5}>
            Guia:
            <Input style={{ width: '100%' }} value={billUpdateData.guia} name='guia' onChange={handleChangeBillData} />
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
            Flete:<InputNumber style={{ width: '100%' }} name={'delivery_value'} value={billUpdateData.delivery_value} onChange={(e) => handleChangeBillData(e, "delivery_value")} />
          </Col>

          <Col span={1}></Col>
          <Col span={5}>
            Uso Plataforma:<InputNumber style={{ width: '100%' }} name={'platformUse'} value={billUpdateData.platformUse} onChange={(e) => handleChangeBillData(e, "platformUse")} />
          </Col>

          <Col span={1}></Col>
          <Col span={5}>
            Comisión Recaudo:<InputNumber style={{ width: '100%' }} name={'collectionCommission'} value={billUpdateData.collectionCommission} onChange={(e) => handleChangeBillData(e, "collectionCommission")} />
          </Col>

          <Col span={1}></Col>
          <Col span={2}>
            <br />
            <Button type='primary' loading={loading} onClick={saveData}>Guardar</Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={12}>
            <ModalRegisterBillNews billId={props.billId} />
          </Col>
          <Col span={12}>
            <ModalReturnBill billId={props.billId} />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EditBill;
