import { Button, Col, Row } from 'antd';
import './Statistics.css';
import tituloSesion from '../../Assets/tituloSesion.png';



const Statistics = () => {
    return (

        <div className='fondo4'>
            <Row gutter={[16, 16]} style={{ paddingTop: '100px' }} >
                <Col xs={1} sm={1} md={1} lg={1} xl={1} ></Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                    <img src={tituloSesion} className='imgSeccion' style={{ paddingTop: '100px' }} />

                </Col>
                <Col xs={22} sm={22} md={5} lg={5} xl={5} style={{  background:'#021435', color:'white'}}className='textStatistics'>
                    <div className='textFirst'>+500</div>
                    Personas que
                    quisieron emprender
                    con cada unos de
                    nuestros productos
                    más vendidos
                </Col>

                <Col xs={22} sm={22} md={5} lg={5} xl={5} style={{  background:'white'}} className='textStatistics'>
                    <div className='textFirst'>+1000</div>
                    Productos que han
                    generado impacto
                    comercial en
                    competencia con
                    grandes mercados
                </Col>
                <Col xs={22} sm={22} md={5} lg={5} xl={5} style={{ background:'#021435', color:'white',  }} className='textStatistics'>
                    <div className='textFirst'>+50M</div>
                    Más de 50 millones
                    de pesos vendidos a
                    nivel nacional e
                    internacional
                </Col>
                <Col xs={2} sm={2} md={1} lg={1} xl={1} ></Col>
            </Row>
        </div>
    )
}

export default Statistics;

