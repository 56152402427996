import { Col, Row, Input, Card } from 'antd';
import * as React from 'react';
import { IPowerSellersProps } from './IPowerSellersProps';
import './PowerSellers.css';
import { HeartFilled } from '@ant-design/icons';
import ProductDetail from './ProductDetail/ProductDetail';
import { useEffect, useState } from 'react';
import { usePowerSellers } from '../../Hooks/Store/PowerSellers';
const { Search } = Input;

const PowerSellers = (props: IPowerSellersProps) => {
  const {
    isModalVisible,
    setIsModalVisible,
    showProductDetail,
    selectedProductId,
    productsListFiltered,
    onSearchFilteredProduct
  } = usePowerSellers();

  return (
    <div className='powerSellers-container'>
      <span className='sub-title-white'>POWER</span>&nbsp;&nbsp;<span className='bold-title-white'>SELLERS</span>
      <div className='powerSellers-description'>
        Navega por los productos disponibles a continuación y elige el que se ajuste más a ti y a tu estragegia.
      </div>
      <div className='powerSellers-products-container'>
        <Row className='powerSellers-products-title'>
          <Col span={20}>
            <span className='color-texto-gris'>Todos los</span>&nbsp;&nbsp;<span className='color-texto-gris'><b>productos</b></span>
            <div className='gray-line'></div>
          </Col>
          <Col span={4}>
            <Search placeholder="Buscar producto" onChange={(e)=>onSearchFilteredProduct(e.target.value)} onSearch={(e)=>onSearchFilteredProduct(e)}/>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='item-container'>
          {productsListFiltered.map((item) => 
            <Col span={6}>
            <Card
              hoverable
              style={{ width: 240 }}
              onClick={() => showProductDetail(item.id)}
              cover={<img alt={item.name} src={item.image} />}
            >
              <div className='product-name color-texto-gris'>{item.name}</div>
              <div className='disclaimer color-texto-gris'>Precio tienda</div>
              <div className='price'>{`$ ${localStorage.currency == 'COP' ?  item.selling_price : item.usD_Selling_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
              {/* <div className='likes'><HeartFilled/> {item.total_Likes}</div> */}
            </Card>
          </Col>
          )}
        </Row>
      </div>
      <ProductDetail isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} productId={selectedProductId}/>
    </div>
  );
};

export default PowerSellers;
