import { message } from "antd";
import { API } from "../../API/axios";

export interface Contract {
    id: number,
    name: string,
    description: string,
    isActiv: false
}
export function getContracts(idCategory: number) {
    return API.get(`Contract/${idCategory}`)
        .then((response) => {
            return response.data
        });
}

export function updateContracts(idCategory: number, contracts: Contract[]) {
    return API.put(`Contract/${idCategory}`, contracts)
        .then(() => message.success("Contratos actualizados"))
        .catch((error) =>
            message.error(`Error actualizando contratos. Error:${error.message}`))
}