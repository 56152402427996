import {StoreClient} from "../Models/StoreClient";
import {Bill, BillData} from "../Models/Bill";

function isStoreClient(input: any): input is StoreClient {
    return input && 'adress_field_1' in input;
}

function isBillOrBillData(input: any): input is Bill | BillData {
    return input && 'guia' in input;
}

export function getAddressDetails(storeClient: StoreClient | undefined): any {
    return {
        adress_field_1: storeClient?.adress_field_1 ?? "",
        adress_field_2: storeClient?.adress_field_2 ?? "",
        adress_field_3: storeClient?.adress_field_3 ?? "",
        adress_field_4: storeClient?.adress_field_4 ?? "",
        adress_field_5: storeClient?.adress_field_5 ?? "",
        adress_field_6: storeClient?.adress_field_6 ?? "",
        adress_field_7: storeClient?.adress_field_7 ?? "",
        adress_field_8: storeClient?.adress_field_8 ?? "",
        adress_field_9: storeClient?.adress_field_9 ?? "",
        adress_field_10: storeClient?.adress_field_10 ?? "",
        adress_field_11: storeClient?.adress_field_11 ?? "",
        adress_field_12: storeClient?.adress_field_12 ?? "",
        adress_indications: storeClient?.adress_indications ?? "",
        building: storeClient?.building ?? "",
        carrera: storeClient?.carrera ?? "",
        cellphone: storeClient?.cellphone ?? "",
        identification: storeClient?.identification ?? "",
        neighborhood: storeClient?.neighborhood ?? "",
    }
}

export function validAddress(storeClient: StoreClient): boolean {
    return storeClient.carrera !== "" || storeClient.adress_field_1 !== ""
        || storeClient.adress_field_4 !== "" || storeClient.adress_field_5 !== ""
        || storeClient.adress_field_6 !== "" || storeClient.adress_field_7 !== ""
        || storeClient.adress_field_8 !== "" || storeClient.adress_field_9 !== ""
        || storeClient.adress_field_10 !== "" || storeClient.adress_field_11 !== ""
        || storeClient.neighborhood !== "" || storeClient.building === ""
        || storeClient.adress_indications !== "";
}

export function formatAddress(billOrBillData: Bill | BillData | undefined): string;
export function formatAddress(storeClient: StoreClient | undefined, indications?: string): string;
export function formatAddress(input: BillData | Bill | StoreClient | undefined, indications?: string): string {

    if (isBillOrBillData(input)) {
        if (input.address !== null) {
            return `${input.address} ${formatIndications(input)}`.trim();
        }

        return formatAddress(input.storeClient, input.adress_indications);
    }

    if (isStoreClient(input)) {
        const addressRaw = `${input.carrera} ${input.adress_field_1}
            ${input.adress_field_2} ${input.adress_field_3}
            ${input.adress_field_1 !== "" ? "#" : ""} ${input.adress_field_4}
            ${input.adress_field_5} ${input.adress_field_6}
            ${input.adress_field_7 !== "" ? "-" : ""} ${input.adress_field_7}
            ${input.adress_field_8} ${input.adress_field_9}
            ${input.adress_field_10} ${input.adress_field_11}
            ${input.adress_field_12} ${input.adress_indications ?? ""}
            ${input.building ?? ""}`.replace(/\s+/g, ' ').trim();

        if (addressRaw.length !== 0) {
            return addressRaw;
        }

        return indications ?? "";
    }

    return "";
}

export function formatIndications(storeClient: StoreClient | undefined): string;
export function formatIndications(billOrBillData: Bill | BillData | undefined): string;
export function formatIndications(addressIndications: string | undefined): string;
export function formatIndications(input: StoreClient | BillData | Bill | string | undefined): string {
    if (typeof input === 'string') {
        return input.replace(/\s+/g, ' ').trim();
    }

    if (isStoreClient(input)) {
        return formatIndications(input.adress_indications);
    }

    if (isBillOrBillData(input)) {
        return formatIndications(input.adress_indications);
    }

    return "";
}
