import React, { useState } from 'react'

// ANT
import { Col, Input, Row, Form, message, Card, Spin } from 'antd';
import { API } from '../../../API/axios';

type DownloadsProps = {
    product: Product,
    setProduct: Function
}

const Downloads = ({ product, setProduct } : DownloadsProps) => {

    const [loading, setLoading] = useState(false);

    const handleChange = async ({ target }: any) => {
        const { name, value } = target;
        if (name === 'testimonials') {
            const file = target.files[0];
            let formData = new FormData();

            const myNewFile = new File([file], `${file.name}.${file.type}`, { type: file.type });
            formData.append('Attachments', myNewFile);
            formData.append('strContainerName', `folder-image`);
            formData.append('folderName', `folder-image`);

            const urlImage =  await API.post(`/BlobStorage/UploadImageGallery`, formData);
            message.success('Se ha cargado la imagen correctamente'); 
            const imagen = urlImage.data;

            setProduct({
                ...product,
                [name]: imagen
            })
            return;
        }
        setProduct({
            ...product,
            [name]: value
        })
    }

    return (
        <div>
            <Form>
                <Row style={{ marginTop: 10 }}>
                    <Col span={24}>
                        <b>URL DRIVE Colombia:</b>
                        <Input
                            value={product.promotional_Images}
                            onChange={handleChange}
                            name="promotional_Images"
                        />
                    </Col>
                    <Col span={24}>
                        <b>URL DRIVE Ecuador:</b>
                        <Input
                            value={product.youtube}
                            onChange={handleChange}
                            name="youtube"
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col span={24}>
                        <b>IMAGEN BIBLIOTECA:</b>
                        <Input
                            type="file"
                            onChange={handleChange}
                            name="testimonials"
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {product.testimonials !== "" &&
                            <Card style={{}}>
                                <Spin tip='Cargando imagen...' spinning={loading}>
                                    <img src={product.testimonials} style={{ width: '100%', maxHeight: 300 }} />
                                </Spin>
                            </Card>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default Downloads

type Product = {
    id?: number,
    name?: string,
    image?: string,
    description?: string,
    cost?: number,
    selling_price?: number,
    suggested_selling_price?: number,
    provider?: string,
    stock?: number,
    warranty_days?: number,
    youtube?: string,
    commission_value1?: number,
    commission_value2?: number,
    category_id?: number,
    max_Selling_price?: number,
    promotional_Images?: string,
    url_Post?: string,
    url_Folder_Images?: string,
    url_Folder_Videos?: string,
    url_Folder_Segmentos?: string,
    url_Landing?: string,
    url_Questions?: string,
    text_Copy?: string,
    testimonials?: string,
    total_Likes?: number,
    weight?: number,
    madeInOwnLab?: boolean,
    isFree?: boolean,
    isActive?: boolean,
    usD_Selling_price?: number,
    usD_Suggested_selling_price?: number,
    usD_Commission_value1?: number,
    usD_Commission_value2?: number,
    usD_Max_Selling_price?: number,
    isActiveEcuador?: boolean
}
