import { Col, Row } from 'antd';
import Differentiators from '../Differentiators/Differentiators';
import DropHacking from '../DropHacking/DropHacking';
import Statistic from '../Statistics/Statistics';
import Page from '../Page/Page';
import Reality from '../Reality/Reality';
import Change from '../Change/Change';
import FormContact from '../FormContact/FormContact';


const Home = () => {
    return (
        <Row style={{ width: '99%' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Page />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <DropHacking />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Differentiators />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Statistic />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Reality />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Change />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormContact />
            </Col>
        </Row>
    )
}

export default Home;

