import { useState } from "react";
import { IChartProps } from "./IChartProps";
import {
  Chart,
  Series,
  Export,
  Tooltip,
  CommonSeriesSettings,
  Label,
  Format,
  Legend,
} from "devextreme-react/chart";
import "./Chart.css";
import { Col, Row,DatePicker } from "antd";
import { BarChartOutlined, LineChartOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;


const StoreChart = (props: IChartProps) => {

  const [chartType, setChartType] = useState<'bar' | 'line'>("bar");

  const customizeTooltip = (arg: any) => {
    const value = `$ ${arg.valueText}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return {
      text: `Ventas ${arg.argumentText}: ${value}`,
    };
  };

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row className="left-panel">
            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
              <Chart id="chart" dataSource={props.ChartDataSource}>
                <Series
                  valueField="total"
                  argumentField="day"
                  name={`Mis Ventas últimos ${Math.abs(props.dayQuantity)} días`}
                  type={chartType}
                  color="orange"
                />
                <Export enabled={false} />
                <Legend
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                ></Legend>
                <Tooltip
                  enabled={true}
                  location="edge"
                  customizeTooltip={customizeTooltip}
                />
              </Chart>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{paddingTop:130}}>

            <BarChartOutlined className="chartIcon" onClick={()=>setChartType('bar')}/>&nbsp;&nbsp;&nbsp;
            <LineChartOutlined className="chartIcon" onClick={()=>setChartType('line')}/>
            <br/><br/>
            {/*<RangePicker className="datePicker"/>*/}
             <div className="filter-Button" onClick={()=>props.setDayQuantity(-7)}>Últimos 7 días</div>
              <br/>
              <div className="filter-Button" onClick={()=>props.setDayQuantity(-30)}>Últimos 30 días</div> 
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default StoreChart;
