import { Button, Col, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEditBill } from "../../../Hooks/SuperAdmin/useEditBill";
import { IModalRegisterBillNewsProps } from "./IModalRegisterBillNewsProps";
import "./ModalRegisterBillNews.css";

const ModalRegisterBillNews = (props: IModalRegisterBillNewsProps) => {
  const {
    showModal,
    handleClose,
    isModalVisible,
    handleDeliveryNewsChange,
    deliveryNews,
    saveDeliveryNews,
  } = useEditBill();

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Registrar Novedad
      </Button>
      <Modal
        title="Registrar novedad"
        open={isModalVisible}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={() => handleClose()}>
            Cerrar
          </Button>,
          <Button
            type="primary"
            key="back"
            onClick={() => saveDeliveryNews(props.billId)}
          >
            Guardar
          </Button>,
        ]}
      >
        <div style={{ padding: 20 }}>
          Novedad:
          <TextArea
            rows={4}
            name="description"
            onChange={(e: any) => handleDeliveryNewsChange(e.target.value)}
            value={deliveryNews.description}
          />
          <br />
          <br />
          <Row>
            <Col span={10}>
              <Button key="back" onClick={() => handleClose()}>
                Cerrar
              </Button>
            </Col>
            <Col span={14}>
              <Button
                type="primary"
                key="back"
                onClick={() => saveDeliveryNews(props.billId)}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ModalRegisterBillNews;
