import { message } from "antd";
import { useEffect, useState } from "react";
import { API } from "../../API/axios";
import { product, storeProduct } from "../../Models/Products";

export const usePowerSellers = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [productList, setproductList] = useState<product[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [productDetail, setProductDetail] = useState<product>();
  const [productsListFiltered, setProductsListFiltered] = useState<Array<product>>([]);
  const [storeSellingPrice, setStoreSellingPrice] = useState<number>(0);


  useEffect(() => {
    getProducts();
  }, []);

  const getProductById = async (ProductId:number) => {
    setLoading(true);
    let resp = await API.get(`/Products/${ProductId}`);
    if (resp.status === 200) {
        setProductDetail(resp.data);
      setLoading(false);
    }
  };

  const getProducts = async () => {
    setLoading(true);
    let resp = await API.get<product[]>(`/Products`);
    if (resp.status === 200) {
      if(localStorage.currency == 'USD')
      {
        setproductList(resp.data.filter(x=>x.isActiveEcuador == true));
        setProductsListFiltered(resp.data.filter(x=>x.isActiveEcuador == true));
      }
      else{
        setproductList(resp.data.filter(x=>x.isActive == true));
        setProductsListFiltered(resp.data.filter(x=>x.isActive == true));
      }
      setLoading(false);
    }
  };

  const showProductDetail = (ProductId:number) => {
    setSelectedProductId(ProductId)
    setIsModalVisible(true);
  }

  const onSearchFilteredProduct = (value: string) => {
    if (value) {
      setProductsListFiltered(productList.filter((x: product) => x.name.toLowerCase().includes(value)));
    } else {
      setProductsListFiltered(productList)
    }
  }

  const addProductToStore = (productId:number) => {
    if ( localStorage.storeID !== "" ) {
      API.get(`/StoreProducts?id=${localStorage.storeID}&Currency=${localStorage.currency}`).then((res: any) => {
        let productExist = res.data.filter((x:storeProduct)=>x.productId === productId);
        if(productExist.length === 0)
        {
          if(storeSellingPrice > 0)
          {
            let el:any;
            if(localStorage.currency == 'USD')
            {
              el = {
                usD_selling_price:storeSellingPrice,
                stock:0,
                storeId:parseInt(localStorage.storeID),
                productId:productId
              }
            }
            else{
              el = {
                selling_price:storeSellingPrice,
                stock:0,
                storeId:parseInt(localStorage.storeID),
                productId:productId
              }
            }
            API.post(`/StoreProducts`, el).then(res=>{
              message.success('El producto fue agregado a la tienda.');
              setIsModalVisible(false);
            });
          }
          else{
            message.warning('Indica cual es el precio de venta del producto');
          }
        }
        else{
          // update store product
          if(storeSellingPrice > 0)
          {
            let el:any;
            if(localStorage.currency == 'USD')
            {
              el = {
                id: productExist[0].id,
                usD_selling_price:storeSellingPrice,
                storeId:parseInt(localStorage.storeID),
              }
            }
            else{
              el = {
                id: productExist[0].id,
                selling_price:storeSellingPrice,
                storeId:parseInt(localStorage.storeID),
              }
            }
            API.put(`/StoreProducts/${productExist[0].id}`, el).then(res=>{
              message.success('El precio del producto fue modificado.');
              setIsModalVisible(false);
            });
          }
          else{
            message.warning('Indica cual es el precio de venta del producto');
          }
          //message.warning('El producto ya existe en el inventario de la tienda')
        }
      });
    }
  }

  return {
    isModalVisible,
    setIsModalVisible,
    productList,
    showProductDetail,
    selectedProductId,
    getProductById,
    productDetail,
    productsListFiltered,
    onSearchFilteredProduct,
    loading,
    addProductToStore,
    storeSellingPrice, 
    setStoreSellingPrice
  };
};
