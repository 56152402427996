import { Button, Col, Form, Input, Row } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { DataGrid } from "devextreme-react";
import { Column, ColumnFixing, Editing, Export, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Sorting } from "devextreme-react/data-grid";
import { useTableCommission } from "./useTableCommissionHook";

const TableCommission = (props: any) => {

    const { loadProducts, products, updateCommission } = useTableCommission();
    const [form] = Form.useForm();

    function handleSearchUser(): void {
        form.validateFields().then((values: any) => {
            loadProducts(values.email);
        });
    }

    function handleUpdateComission(data: any) {
        let newCommission = {
            commissionValue: data.commissionValue,
            commissionValueUSD: data.commissionValueUSD,
            mentorStoresId: data.mentorStoresProducts.mentorStoresId,
            store_ProductId: data.mentorStoresProducts.store_ProductId
        }
        updateCommission(newCommission);
    }

    return (
        <div>
            <Form form={form}>
                <FormItem
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Por favor, ingresa el correo electrónico",
                        },
                        {
                            type: "email",
                            message:
                                "Ingresa una dirección de correo electrónico válida",
                        },
                    ]}
                >
                    <Input></Input>
                </FormItem>

                <FormItem>
                    <Button type="primary" onClick={handleSearchUser}>
                        Buscar
                    </Button>
                </FormItem>
            </Form>
            {products.length > 0 && (<Row gutter={[16, 16]}>
                <Col span={24} style={{ padding: 10, backgroundColor: "white" }}>
                    <DataGrid
                        id="commissions"
                        dataSource={products}
                        showBorders={true}
                        remoteOperations={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        showRowLines={true}
                        onRowUpdated={(value) => handleUpdateComission(value.data)}

                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                        <HeaderFilter visible={true} />
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <GroupPanel visible={false} />
                        <ColumnFixing enabled={true} />
                        <FilterRow visible={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                        <Grouping autoExpandAll={false} />
                        <Pager
                            showInfo={true}
                            showNavigationButtons={true}
                            showPageSizeSelector={true}
                            visible={true}
                        />
                        <Column
                            allowEditing={false}
                            dataField="storeName"
                            caption="Tienda"
                            alignment="center"
                        />
                        <Column
                            allowEditing={false}
                            dataField="productName"
                            caption="Producto"
                            alignment="center" />

                        <Column
                            dataField="commissionValue"
                            caption="Valor Comisión"
                            alignment="center"
                        />
                        <Column
                            dataField="commissionValueUSD"
                            caption="Valor Comisión USD"
                            alignment="center"
                        />
                    </DataGrid>
                </Col>
            </Row>)
            }
        </div>

    );
}

export default TableCommission;

